import React, {useEffect, useRef} from "react";
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import SectionHeader from "./partials/SectionHeader";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ScrollAnimation from "react-animate-on-scroll";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Devices = ({
                     className,
                     topOuterDivider,
                     bottomOuterDivider,
                     topDivider,
                     bottomDivider,
                     hasBgColor,
                     invertColor,
                     ...props
                 }) => {


    const outerClasses = classNames(
        'devices section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'devices-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
        title: '',
        paragraph: ''
    };

    const ref = useRef(null);
    gsap.registerPlugin(ScrollTrigger);


    useEffect(() => {
        const element = ref.current;
        gsap.from(element.querySelector(".img-box"), {
            scrollTrigger: {
                trigger: ".devices .img-box",
                start: "top top+=120",
                end: `bottom center+=520`,
                scrub: true,
                pin: true
            }
        });


        element.querySelector(".devices-box").querySelectorAll('img').forEach(el => {
            if (!el.classList.contains('doors_detector')) {
                gsap.fromTo(
                    el,
                    {},
                    {
                        scale: .2,
                        opacity: 0,
                        duration: 1,
                        scrollTrigger: {
                            trigger: ".doors_detector_text",
                            start: "top center",
                            toggleActions: 'play none none reverse',

                        }
                    });
            }
        })


        gsap.fromTo(element.querySelector('.doors_detector'), {},
            {
                y: 95,
                x: 150,
                scale: 2,
                duration: 1,
                scrollTrigger: {
                    trigger: ".doors_detector_text",
                    start: "top center",
                    toggleActions: 'play none none reverse',
                }
            }
        )
        gsap.fromTo(element.querySelector('.doors_detector'), {},
            {
                y: 0,
                x: 0,
                scale: 0.2,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".doors_detector_text",
                    start: "bottom center",
                    toggleActions: 'play none none reverse',
                }
            }
        )


        gsap.fromTo(element.querySelector('.motion_detector'), {},
            {
                opacity: 1,
                y: 95,
                x: -150,
                scale: 1.5,
                duration: 1,
                scrollTrigger: {
                    trigger: ".motion_detector_text",
                    start: "top center",
                    toggleActions: 'play none none reverse',
                }
            }
        )
        gsap.fromTo(element.querySelector('.motion_detector'), {},
            {
                y: 0,
                x: 0,
                scale: 0.2,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".motion_detector_text",
                    start: "bottom center",
                    toggleActions: 'play none none reverse',
                }
            }
        )

        gsap.fromTo(element.querySelector(".socket"), {},
            {
                opacity: 1,
                y: -75,
                x: -150,
                scale: 2.3,
                duration: 1,
                scrollTrigger: {
                    trigger: ".socket_detector_text",
                    start: "top center",
                    toggleActions: 'play none none reverse',
                }
            }
        )
        gsap.fromTo(element.querySelector('.socket'), {},
            {
                y: 0,
                x: 0,
                scale: 0.2,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".socket_detector_text",
                    start: "bottom center",
                    toggleActions: 'play none none reverse',
                }
            }
        )

        gsap.fromTo(element.querySelector('.micro_pc'), {},
            {
                opacity: 1,
                y: -180,
                x: 50,
                scale: 2,
                duration: 1,
                scrollTrigger: {
                    trigger: ".micro_pc_text",
                    start: "top center",
                    toggleActions: 'play none none reverse',
                }
            }
        )
    }, []);


    return (
        <section
            {...props}
            className={outerClasses}
            ref={ref}
        >


            <div className="img-box">
                <img
                    src={require("./../../assets/images/shape-15.svg")} alt=""
                    className="main-shape reveal-from-left" data-reveal-delay="200"/>
                <img
                    src={require("./../../assets/images/shape-16.svg")} alt=""
                    className="bg-shape"/>


                <div className="devices-box">
                    <img
                        src={require("./../../assets/images/doors_detector.png")} alt=""
                        className="doors_detector"/>
                    <img
                        src={require("./../../assets/images/motion_detector.png")} alt=""
                        className="motion_detector"/>
                    <img
                        src={require("./../../assets/images/micro_pc.png")} alt=""
                        className="micro_pc"/>
                    <img
                        src={require("./../../assets/images/socket.png")} alt=""
                        className="socket"/>
                </div>

            </div>

            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>

                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="devices_main">
                                <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>
                                    <div className="icon-box hide-pr show-pr">
                                        <img
                                            src={require("./../../assets/images/bg-shape2.svg")}
                                            alt="" className="bg-shape"/>
                                        <img
                                            src={require("./../../assets/images/icon25.svg")}
                                            alt=""
                                            className="icon"/>
                                    </div>
                                    <h2>Оборудование</h2>
                                </ScrollAnimation>
                                <ul>
                                    <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>
                                        <li>Размеры датчиков меньше 5 см</li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={600} animateIn='fadeInUp' animateOut='fadeOut'

                                                     initiallyVisible={false}>
                                        <li>Не требует наличия никакой инфраструктуры</li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={800} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Установка не требует монтажных работ</li>
                                    </ScrollAnimation>
                                </ul>
                            </div>
                            <div className="doors_detector_text">
                                <h3>Датчик открытия/закрытия двери</h3>
                                <ul>
                                    <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>
                                        <li>Датчик ставится в прорезь в двери или приклеивается прямо на дверь.

                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={200} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Питается от аккумулятора. Одного аккумулятора хватает на 3 года.
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Не требует монтажных работ. Сигнал передается дистанционно.<br/>
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Дальность действия внутри здания: 10-15 метров
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Дальность действия на открытой местности: 40-60 метров
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Размеры: 41 х 21 х 11 мм
                                        </li>
                                    </ScrollAnimation>
                                </ul>
                            </div>
                            <div className="motion_detector_text">
                                <h3>Датчик движения</h3>
                                <ul>
                                    <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>
                                        <li>Датчик приклеивается на стену или потолок в недоступном местей
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={200} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Питается от аккумулятора. Одного аккумулятора хватает на 3 года.
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Не требует монтажных работ. Сигнал передается дистанционно.<br/>
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Дальность действия внутри здания: 10-15 метров
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Дальность действия на открытой местности: 40-60 метров
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Размеры: 30 х 30 х 33 мм
                                        </li>
                                    </ScrollAnimation>
                                </ul>
                            </div>
                            <div className="socket_detector_text">
                                <h3>Усилитель сигнала датчиков</h3>
                                <ul>
                                    <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>

                                        <li>Датчик ставится в прорезь в двери или приклеивается прямо на дверь.</li>


                                        <li>Датчик приклеивается на стену или потолок в недоступном местей

                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={200} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Питается от аккумулятора. Одного аккумулятора хватает на 3 года.
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Не требует монтажных работ. Сигнал передается дистанционно.<br/>
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Дальность действия внутри здания: 10-15 метров
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Дальность действия на открытой местности: 40-60 метров
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Размеры: 41 х 21 х 11 мм</li>
                                    </ScrollAnimation>
                                </ul>
                            </div>
                            <div className="micro_pc_text">
                                <h3>Аггрегатор (устройство сбора показаний датчиков)</h3>
                                <ul>

                                    <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>
                                        <li>Данные с датчиков собираются специальным устройством,
                                            предназначенным для отправки данных на сервер.

                                        </li>
                                    </ScrollAnimation>

                                    <ScrollAnimation delay={200} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Устройство защищено от перепадов электричества,
                                            непродолжительного отсутствия питания (несколько часов)
                                            и не требует подключения к интернету.
                                        </li>
                                    </ScrollAnimation>
                                    <ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut'
                                                     initiallyVisible={false}>
                                        <li>Устройство ставится в недоступном месте, закрывается
                                            в специальный корпус и пломбируется.

                                        </li>
                                    </ScrollAnimation>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                        </div>
                    </div>

                </div>
            </div>
        </section>
);
}

Devices.propTypes = propTypes;
Devices.defaultProps = defaultProps;

export default Devices;
