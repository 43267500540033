import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import ScrollAnimation from "react-animate-on-scroll";

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const SystemIndicators = ({
                              className,
                              topOuterDivider,
                              bottomOuterDivider,
                              topDivider,
                              bottomDivider,
                              hasBgColor,
                              invertColor,
                              pushLeft,
                              ...props
                          }) => {

    const outerClasses = classNames(
        'system-indicators section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'system-indicators-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: '',
        paragraph: ''
    };


    return (
        <section
            {...props}
            className={outerClasses}
        >

            <div className="img-box">
                <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>

                    <img
                        src={require("./../../assets/images/shape-15.svg")} alt=""
                        className="main-shape reveal-from-left" data-reveal-delay="200"/>
                    <img
                        src={require("./../../assets/images/shape-16.svg")} alt=""
                        className="bg-shape reveal-from-left" data-reveal-delay="400"/>

                    <div className={tilesClasses}>
                        {/*<ScrollAnimation delay={200} animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>*/}
                        <div className="tiles-item  system-indicators-item lost-profit screen-one">
                            <div className="tiles-item-inner">
                                <div className="lost-profit-header">

                                    <div className="lost-profit-title">
                                        <div className="lost-profit-icon">
                                            <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 890.5 890.5">
                                                <g>
                                                    <g>
                                                        <path
                                                            d="M208.1,180.56l355-96.9l-18.8-38c-12.3-24.7-42.3-34.9-67-22.6l-317.8,157.5H208.1z"/>
                                                        <path d="M673.3,86.46c-4.399,0-8.8,0.6-13.2,1.8l-83.399,22.8L322,180.56h289.1h126l-15.6-57.2
                                                                C715.5,101.06,695.3,86.46,673.3,86.46z"/>
                                                        <path d="M789.2,215.56h-11.4h-15.5h-15.5H628.5H193.8h-57h-48h-8.9H50.1c-15.8,0-29.9,7.3-39.1,18.8c-4.2,5.3-7.4,11.4-9.2,18.1
                                                                c-1.1,4.2-1.8,8.6-1.8,13.1v6v57v494.1c0,27.601,22.4,50,50,50h739.1c27.601,0,50-22.399,50-50v-139.5H542.4
                                                                c-46.9,0-85-38.1-85-85v-45.8v-15.5v-15.5v-34.4c0-23,9.199-43.899,24.1-59.199c13.2-13.601,30.9-22.801,50.7-25.101
                                                                c3.3-0.399,6.7-0.6,10.1-0.6h255.2H813h15.5h10.6v-136.5C839.2,237.96,816.8,215.56,789.2,215.56z"/>
                                                        <path d="M874.2,449.86c-5-4.6-10.9-8.1-17.5-10.4c-5.101-1.699-10.5-2.699-16.2-2.699h-1.3h-1h-15.5h-55.9H542.4
                                                                c-27.601,0-50,22.399-50,50v24.899v15.5v15.5v55.4c0,27.6,22.399,50,50,50h296.8h1.3c5.7,0,11.1-1,16.2-2.7
                                                                c6.6-2.2,12.5-5.8,17.5-10.4c10-9.1,16.3-22.3,16.3-36.899v-111.3C890.5,472.16,884.2,458.959,874.2,449.86z M646.8,552.36
                                                                c0,13.8-11.2,25-25,25h-16.6c-13.8,0-25-11.2-25-25v-16.6c0-8,3.7-15.101,9.6-19.601c4.3-3.3,9.601-5.399,15.4-5.399h4.2H621.8
                                                                c13.8,0,25,11.199,25,25V552.36L646.8,552.36z"/>
                                                    </g>
                                                </g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                                <g></g>
                                            </svg>
                                        </div>
                                        <h5 className="mt-0 mb-8">
                                            Упущенная выручка
                                        </h5>
                                    </div>
                                    <span className="lost-profit-subheader">
                                            за 134,4 часа
                                        </span>
                                </div>
                                <div className="lost-profit-content">
                                    <h3 className="m-0 text-sm">
                                        - 98 000.00 <span>₽</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*</ScrollAnimation>*/}

                        {/*<ScrollAnimation delay={400} animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>*/}
                        <div className="tiles-item system-indicators-item suspicious-activity screen-two">
                            <div className="tiles-item-inner">
                                <div className="suspicious-activity-header">
                                    Общее время несанкционированной активности
                                </div>
                                <div className='chart'>
                                    <div className="chart-dates">01.07.2020 - 04.07.2021</div>
                                    <svg viewBox='0 0 560 260'>
                                        <defs>
                                            <filter id='dropshadow'>
                                                <feGaussianBlur in='SourceAlpha' stdDeviation='3'></feGaussianBlur>
                                                <feOffset dx='0' dy='0' result='offsetblur'></feOffset>
                                                <feComponentTransfer>
                                                    <feFuncA slope='0.2' type='linear'></feFuncA>
                                                </feComponentTransfer>
                                                <feMerge>
                                                    <feMergeNode></feMergeNode>
                                                    <feMergeNode in='SourceGraphic'></feMergeNode>
                                                </feMerge>
                                            </filter>
                                        </defs>
                                        <g className='datasets'>
                                            <g >
                                                <rect className='dataset' id='dataset-7' x="9.7" y="200.9" width="42.5" height="59.1"/>
                                                <rect className='dataset' id='dataset-6' x="92.7" y="90.7" width="42.5" height="169.3"/>
                                                <rect className='dataset' id='dataset-5' x="175.7" y="108.5" width="42.5" height="151.5"/>
                                                <rect className='dataset' id='dataset-4' x="258.8" y="125.6" width="42.5" height="134.4"/>
                                                <rect className='dataset' id='dataset-3' x="341.8" y="76.5" width="42.5" height="183.5"/>
                                                <rect className='dataset' id='dataset-2' x="424.8" y="110.9" width="42.5" height="149.1"/>
                                                <rect className='dataset' id='dataset-1' x="507.8" y="200.9" width="42.5" height="59.1"/>
                                            </g>

                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {/*</ScrollAnimation>*/}

                        {/*<ScrollAnimation delay={600} animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>*/}
                        <div className="tiles-item  system-indicators-item questionable-activity screen-three">
                            <div className="tiles-item-inner">
                                <div className="questionable-activity-header">
                                    <div className="questionable-activity-title">Процент несанкционированной активности</div>
                                    <div className="questionable-activity-subtitle">134,4/320</div>
                                </div>

                                <figure className="questionable-activity-chart" data-percent="42">
                                    <span>42%</span>
                                    <svg width="200" height="200">
                                        <circle className="border" cx="90" cy="90" r="80"
                                                transform="rotate(-90, 90, 90)"/>
                                        <circle className="outer" cx="90" cy="90" r="80"
                                                transform="rotate(-90, 90, 90)"/>
                                    </svg>
                                </figure>

                            </div>
                        </div>
                        {/*</ScrollAnimation>*/}

                    </div>
                </ScrollAnimation>
            </div>

            <div className="container">
                <div className={innerClasses}>
                    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' initiallyVisible={false}>
                        <div className="system-indicators-title">
                            <div className="icon-box hide-pr show-pr">
                                <img
                                    src={require("./../../assets/images/bg-shape2.svg")}
                                    alt="" className="bg-shape"/>
                                <img
                                    src={require("./../../assets/images/icon24.svg")}
                                    alt=""
                                    className="icon"/>
                            </div>
                            <h4>
                                На основе показаний датчиков и данных о заселениях система вычисляет разные показатели
                            </h4>
                        </div>
                    </ScrollAnimation>

                    <ul>
                        <ScrollAnimation delay={200} animateIn='fadeIn' animateOut='fadeOut' initiallyVisible={false}>
                            <li style={{fontSize: "20px"}}>
                                <h5>Упущенная выручка</h5>
                                Общая недополученная выручка вычисленная на основе выявленных неучтенных заселений за период
                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation delay={400} animateIn='fadeIn' animateOut='fadeOut' initiallyVisible={false}>
                            <li style={{fontSize: "20px"}}>

                                <h5>Общее время несанкционированной активности</h5>
                                Общее время несанкционированной активности в номерах

                                <h5>Общее время несанкцианированной активности</h5>
                                Общее время несанкцианированной активности в номерах

                            </li>
                        </ScrollAnimation>
                        <ScrollAnimation delay={600} animateIn='fadeIn' animateOut='fadeOut' initiallyVisible={false}>
                            <li style={{fontSize: "20px"}}>

                                <h5>Процент несанкционированной активности</h5>
                                Процент несанкционированной активности от времени учтённых заселений

                                <h5>Процент несанкцианированной активности</h5>
                                Процент несанкцианированной активности от времени учтённых заселений

                            </li>
                        </ScrollAnimation>
                    </ul>

                </div>
            </div>
        </section>
    );
}

SystemIndicators.propTypes = propTypes;
SystemIndicators.defaultProps = defaultProps;

export default SystemIndicators;
