import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Slider from "react-slick";


const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const MicroProcessor = ({
                            className,
                            topOuterDivider,
                            bottomOuterDivider,
                            topDivider,
                            bottomDivider,
                            hasBgColor,
                            invertColor,
                            ...props
                        }) => {


    const outerClasses = classNames(
        'mount-example section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'mount-example-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );
    var settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        autoplay: true,
        speed: 6000,
        autoplaySpeed: 0,
        cssEase: "linear",
        className: 'slider',
        pauseOnHover: false,

        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }]
    };
    return (
        <section
            {...props}
            className={outerClasses}
        >
            <Slider {...settings}>
                <div>
                    <img src="https://picsum.photos/id/201/360/240" alt=""/>
                </div>
                <div>
                    <img src="https://picsum.photos/id/202/360/240" alt=""/>
                </div>
                <div>
                    <img src="https://picsum.photos/id/203/360/240" alt=""/>
                </div>
                <div>
                    <img src="https://picsum.photos/id/204/360/240" alt=""/>
                </div>
                <div>
                    <img src="https://picsum.photos/id/200/360/240" alt=""/>
                </div>
                <div>
                    <img src="https://picsum.photos/id/199/360/240" alt=""/>
                </div>
            </Slider>
        </section>
    );
}

MicroProcessor.propTypes = propTypes;
MicroProcessor.defaultProps = defaultProps;

export default MicroProcessor;
