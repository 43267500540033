import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <div className="m-0 logo">
        <Link to="/">
          <Image
            src={require('./../../../assets/images/logo.svg')}
            alt="Open"
            width={62}
            height={62} />
            <div className="logo__text">
              <p className="logo__brand-name text-color-primary">
                Softra
              </p>
              <p className="logo__description ">
                Контроль заселений
              </p>
            </div>
        </Link>
      </div>
    </div>
  );
}

export default Logo;
