import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ScrollAnimation from "react-animate-on-scroll";

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const Features = ({
                      className,
                      topOuterDivider,
                      bottomOuterDivider,
                      topDivider,
                      bottomDivider,
                      hasBgColor,
                      invertColor,
                      invertMobile,
                      invertDesktop,
                      alignTop,
                      imageFill,
                      ...props
                  }) => {

    const outerClasses = classNames(
        'examples-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: '',
        paragraph: ''
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={splitClasses}>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile">
                                <ScrollAnimation delay={200} animateIn='fadeInRight' animateOut='fadeOut'
                                                 initiallyVisible={false}>
                                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                        Знакомая ситуация?
                                    </div>
                                    <h3 className="mt-0 mb-12">
                                        Недобросовестный администратор
                                    </h3>
                                    <p className="m-0">
                                        Администратор получает деньги за 5 часов,
                                        <br/>
                                        Вносит в учетную систему или Excel 3 часа,
                                        <br/>
                                        А деньги за 2 часа оставляет себе.
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile',
                                    imageFill && 'split-item-image-fill'
                                )}
                                 data-reveal-container=".split-item">
                                <ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOut' initiallyVisible={false}>
                                    <div className="blob">

                                        <svg viewBox="0 0 553 517">

                                            <linearGradient id="MyGradient">
                                                <stop offset="5%" stopColor="#c4d0f9"/>
                                                <stop offset="95%" stopColor="#e5d0fa"/>
                                            </linearGradient>

                                            <path className="g0" fill="url(#MyGradient)">
                                                <animate attributeName="d" dur="8s" repeatCount="indefinite"
                                                         values="M305.2 148.5c0 84.3-68.4 152.7-152.7 152.7c-84.3 0-152.7-68.4-152.7-152.7C-0.2 62.4 18.6 1.4 94.2 0.6
	C171.2-0.2 305.2 60.8 305.2 148.5z; m305.2,148.5c-15,86.3 -89.4,154.7 -173.7,154.7c-84.3,0 -145.7,-72.6 -131.7,-154.7c14,-82.1 133.8,-147.1 209.4,-147.9c77,-0.8 111,61.6 96,147.9z; M305.2 148.5c0 84.3-68.4 152.7-152.7 152.7c-84.3 0-152.7-68.4-152.7-152.7C-0.2 62.4 18.6 1.4 94.2 0.6
	C171.2-0.2 305.2 60.8 305.2 148.5z; "/>
                                            </path>

                                            <g className="g1">
                                                <path className="bg" d="M60.5-32.9c1.6-7,5.1-11.2,8-11c0.3,0,0.8,0.1,1.4,0.5c-0.3,1.5-0.4,3-0.4,4.5c0,13.2,10.8,24,24,24
		s24-10.8,24-24c0-1.6-0.2-3.1-0.4-4.5c1-0.4,2.2-0.7,3.4-0.5c4.1,0.9,6,7,7,10c2.7,8.3,1.8,17,2,17c0,0,0-0.1,0-0.1
		c0.2-1.9,1.4-4.5,2-9.9c0.3-3.1,0.5-5.2,0-8c-0.5-2.7-1.3-4.7-2-6c-1-1.9-1.6-3.1-3-4c-1.3-0.9-2.6-1-6-1c-1.9,0-4.1,0-6.4,0
		l-0.8-2.8c0.8-2.1,2.3-4.8,5.2-7.2c4.1-3.4,8.5-3.9,10-4c-2.8-0.4-8.6-0.7-15,2c-0.9,0.4-1.7,0.8-2.4,1.2l-1.1-3.9
		c1.7-1.6,3.4-2.7,4.6-3.3c-2.1-0.1-3.9,0-5.5,0.2l-11.9-41.5c0.7-0.3,1.1-1,1.3-1.7h-4.9c0.1,0.7,0.6,1.3,1.2,1.6L81.9-69.1
		c-4.7-2.4-9.3-2.7-11.4-2.8c2.9,1.5,6.6,3.8,9.8,7.2l-3.1,8.8c-1.4-0.8-3-1.3-5.7-2c-2.4-0.7-6.2-1.4-11-1c2.1,0.4,5.5,1.4,9,4
		c2.4,1.8,4.1,3.8,5.4,5.7l-0.9,2.7c-0.4-0.1-0.9-0.3-1.4-0.3c-3.9-0.7-7,1-9,2c-1.5,0.8-3.5,1.8-5,4c-1.3,1.8-1.9,4-2,8
		c-0.1,3.4,0.1,8.6,2,15C59.2-22.9,59.8-27.9,60.5-32.9z M113.1-45.9c-0.2,0-0.4,0-0.6,0c0.1-0.3,0.1-0.7,0.3-1.2L113.1-45.9z
		 M104.5-51.9c0.5-1.6,1.3-3.8,3-6c0.5-0.7,1.1-1.3,1.6-1.9l1,3.6C107.7-54.7,105.8-53.1,104.5-51.9z M95.7-104.9c0.1,0,0.2,0,0.3,0
		c0.1,0,0.1,0,0.2,0l11.9,41.3c-3.9,0.7-6.2,2.2-7.5,3.7c-2.6,2.8-4.5,8.2-5,8c-0.1,0-0.1-0.2-0.1-0.4c0-1-0.7-2.2-1.9-4.6
		c-3.2-6.4-8-10-8-10c-0.9-0.7-1.8-1.2-2.7-1.8L95.7-104.9z M81.1-63.8c1.3,1.5,2.4,3.1,3.4,4.9c3,5.6,2.6,9.8,2,11
		c-0.1,0.3-0.5,1-1,1c-0.5,0-0.9-0.8-1-1c-1.4-2.8-5.7-6.8-6-7c-0.1-0.1-0.3-0.2-0.4-0.4L81.1-63.8z M75.6-48.1
		c0.7,1.2,1.2,2.3,1.5,3.1c-0.6-0.4-1.4-0.8-2.2-1.2L75.6-48.1z"/>
                                                <path className="bg" d="M182.7-95.1l-1.2-10.3c-0.1-0.8-0.8-1.5-1.7-1.5h-6.7c-0.8,0-1.5,0.6-1.6,1.5l-1.2,10.3
		c-8.4,2.1-15.2,8.2-18.1,16.2h48.8C197.9-86.9,191.1-93.1,182.7-95.1z"/>
                                                <path className="bg" d="M248-18l-1.6-13.4c-0.1-0.8-0.8-1.5-1.6-1.5h-2.9v-74h-1v74H238c-0.8,0-1.6,0.6-1.7,1.5L234.7-18
		c-8.6,2.2-15.4,8.8-18.1,17.1h49.5C263.4-9.3,256.5-15.8,248-18z"/>
                                                <path className="bg" d="M409.2-66.1c-1.7-0.9-3.2-1-4.3-1c-1.8-0.7-3.6-1.6-5.2-2.7c0.2,0,0.4,0,0.5,0c3.4-0.3,5.5-2.4,6-3
		c-0.7-0.3-2.9-1.3-5.8-0.6c-1.5,0.4-2.6,1-3.4,1.7c-0.2-0.2-0.5-0.4-0.7-0.6c-0.7-0.7-1.8-1.6-3.5-3c-0.5-0.4-0.9-0.8-1.3-1.1
		c0.5-1,0.6-1.9,0.7-2.3c-0.1,0-0.2,0-0.3,0c0.2-0.1,0.4-0.2,0.6-0.3c3-1.6,4-4.5,4.2-5.2c-0.7,0-3.2,0.1-5.5,1.9
		c-1.5,1.2-2.3,2.6-2.7,3.6c-1.1-0.8-2-1.4-2.8-1.7c-2.8-1-5.4-0.8-7.5-0.4c0.4-0.4,0.7-0.9,1-1.5c1.6-3,1-5.9,0.8-6.7
		c-0.6,0.4-2.7,1.8-3.7,4.5c-0.6,1.6-0.6,3.1-0.5,4.1c-0.9,0.2-1.8,0.5-2.6,0.9l-15.7-44.9l-19.2,43.5c-0.6-0.5-1.3-0.8-2.9-1.4
		c0.4-1,0.8-2.9,0.3-5.1c-0.6-2.6-2.2-4.1-2.8-4.6c-0.4,0.8-1,2.4-0.8,4.4c0.2,2.2,1.1,3.8,1.9,4.8c-1.9-0.6-4.8-1.5-9.1-2.7
		l-0.3-0.1c-0.5-0.1-1-0.3-1.4-0.4c0.5-1,1-2.8,0.7-5c-0.4-2.6-1.9-4.2-2.5-4.8c-0.4,0.8-1.1,2.3-1.1,4.3c0,2.3,0.9,4,1.7,5.1
		c-3.5-1.1-6-2.2-8.3-3.7c0.7-0.9,1.5-2.5,1.6-4.6c0.2-2.6-0.9-4.6-1.3-5.2c-0.6,0.7-1.6,2-2,4c-0.4,1.9-0.1,3.5,0.3,4.7
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.9-0.7-1.7-1.6-2.5-2.4c-0.3-1.1-1.1-2.8-2.8-4.2c-2-1.7-4.2-1.9-5-2c0.2,0.9,0.7,2.5,2,4
		c1.7,1.9,3.8,2.6,5.1,2.9c0.8,0.9,1.6,1.7,2.5,2.5c-0.7-0.1-1.4-0.2-2.2-0.2c-3.4,0.2-5.6,2.3-6.1,2.8c0.7,0.3,2.9,1.4,5.8,0.8
		c2-0.4,3.4-1.5,4.2-2.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c1.9,1.3,3.9,2.2,6.3,3.1c-0.3,0.1-0.6,0.1-1,0.2
		c-3.3,0.9-4.9,3.5-5.3,4.1c0.7,0.2,3.1,0.7,5.8-0.5c1.7-0.8,2.8-1.9,3.4-2.8c0.6,0.2,1.3,0.4,2,0.6l0.3,0.1
		c3.2,0.9,5.6,1.6,7.4,2.2c-0.4,0.1-0.9,0.2-1.4,0.4c-3.2,1.1-4.7,3.8-5,4.5c0.7,0.1,3.2,0.5,5.8-0.9c1.7-0.9,2.7-2.2,3.2-3.1
		c2,0.7,2.7,1,3.3,1.6l-1.5,3.3c-2.1-0.4-3.9,0.1-4.4,0.3c0.3,0.5,1.3,1.8,3.1,2.8l-0.6,1.3c-0.3-0.1-0.6-0.2-0.8-0.2
		c-0.6-0.7-1.6-1.7-3-2.4c-3-1.5-6-0.7-6.7-0.5c0.4,0.6,1.9,2.6,4.7,3.5c0.5,0.1,0.9,0.2,1.4,0.3c-1.6,1.2-2.5,3.2-3,4.3
		c-0.4,0.9-0.8,2-1,3.9c-0.9,0.2-2.4,0.6-3.9,1.7c-2.7,2.1-3.2,5.1-3.3,5.8c0.7-0.1,3.2-0.6,5.2-2.7c1-1.1,1.6-2.3,1.9-3.3
		c0,1.1,0,2.3,0.1,3.8c0.1,1.6,0.3,2.7,0.5,3.7c-0.9,0.3-2.2,0.8-3.4,1.9c-2.5,2.3-2.9,5.3-3,6c0.7-0.2,3.1-0.8,5-3
		c1-1.2,1.5-2.5,1.7-3.5c0.1,0.3,0.2,0.7,0.2,1c0.3,1.3,0.7,2.7,1,5c0.3,2.1,0.4,4,0.4,5.7c-0.9,0.2-2.1,0.7-3.2,1.6
		c-2.7,2.1-3.2,5.1-3.3,5.8c0.7-0.1,3.2-0.6,5.1-2.7c0.6-0.6,1-1.2,1.3-1.9c-0.1,1.4-0.2,2.6-0.3,3.3c-0.2,2.2-0.5,3.5-0.9,5.1
		c0,0.2-0.1,0.4-0.1,0.6c-0.9,0.2-2.4,0.6-3.8,1.7c-2.7,2.1-3.2,5.1-3.3,5.8c0.7-0.1,3.2-0.6,5.1-2.7c0.7-0.7,1.1-1.5,1.5-2.2
		c-0.1,0.8-0.3,1.6-0.4,2.6c-0.4,2.6-0.7,5.3-0.9,7.9c-0.5,0.8-1.1,2-1.3,3.5c-0.5,3.4,1.2,5.9,1.7,6.5c0.5-0.6,1.9-2.6,1.8-5.5
		c0-1.9-0.7-3.3-1.2-4.2c0.2-2.6,0.5-5.3,0.9-8c0.1-0.9,0.3-1.7,0.4-2.4c0.1,0,0.1,0,0.2,0c0.3,0.6,0.6,1.3,1.2,1.9
		c0.4,0.5,0.9,1,1.3,1.3c-0.2,0.2-0.4,0.4-0.6,0.7c-2,2.7-1.7,5.8-1.6,6.5c0.7-0.3,2.9-1.4,4.2-4c0.3-0.6,0.5-1.1,0.6-1.7
		c0.8,0.3,1.5,0.4,1.8,0.5c-0.1-0.5-0.4-1.9-1.3-3.3c0.9-1.3,1.8-2.6,2.7-4c0,0.8,0.1,1.7,0.5,2.6c1.1,3.2,3.7,4.7,4.4,5.1
		c0.1-0.7,0.5-3.2-0.8-5.8c-0.9-1.7-2.2-2.8-3.1-3.3c0.8-1.2,2.5-4.1,4.1-7.2c0,0.9,0,2,0.4,3.2c1.1,3.2,3.7,4.7,4.4,5.1
		c0.1-0.7,0.5-3.2-0.8-5.8c-1-2-2.6-3.1-3.5-3.6c0.1-0.3,0.3-0.5,0.4-0.8c0.2-0.3,0.3-0.7,0.5-1.1c3.8,2.4,8.2,3.9,13.1,3.9
		c3,0,5.9-0.6,8.6-1.6c0,0.5-0.1,1.1-0.1,1.6c0,0.1,0,0.1,0,0.2c-0.9,0.2-2.4,0.7-3.8,1.9c-2.6,2.2-3,5.2-3.1,6
		c0.7-0.1,3.1-0.7,5-2.9c1.1-1.3,1.6-2.6,1.8-3.6c-0.1,2.4-0.1,4.9,0,7.3c-0.5,0.8-1,2-1.2,3.5c-0.4,3.4,1.4,5.8,1.8,6.5
		c0.4-0.6,1.8-2.7,1.7-5.6c-0.1-1.9-0.7-3.3-1.3-4.2c-0.1-2.2-0.1-4.3,0-6.5c0.4,0.5,0.9,1,1.5,1.5c2.7,2.1,5.7,1.8,6.5,1.8
		c-0.3-0.7-1.3-2.9-3.9-4.3c-1.5-0.8-3-1-4-1c0-0.1,0-0.3,0-0.4c0-0.7,0.1-1.4,0.1-2c8.5-3.7,14.4-12.2,14.4-22c0-2-0.3-3.9-0.7-5.8
		c0.3,0.5,0.7,0.9,1.2,1.4c2.5,2.3,5.5,2.5,6.2,2.5c-0.2-0.7-1-3.1-3.4-4.7c-1.5-1.1-3.1-1.4-4.1-1.5c0.4-0.2,0.8-0.6,1.2-1
		c1.8-1.2,3.3-1.8,4.5-1.7c0,0.9,0.2,2.1,0.8,3.4c0.5,1,1.1,1.8,1.8,2.5c0,0.7,0,1.3,0.1,2c0,0.8,0,1.5,0,2.2
		c-0.9,0.2-2.4,0.7-3.8,1.9c-2.6,2.2-3,5.2-3.1,6c0.7-0.1,3.1-0.7,5-2.9c0.8-0.9,1.3-1.9,1.6-2.7c-0.1,0.3-0.1,0.6-0.2,1
		c-0.2,1-0.4,2.1-0.5,3.6c-0.2,1.8-0.3,3.4-0.3,5.3c-0.9,0.2-2.3,0.7-3.6,1.9c-2.6,2.2-3,5.2-3.1,6c0.7-0.1,3.1-0.7,5-2.9
		c0.9-1,1.4-2.1,1.7-3c0,1.4,0.1,3,0.2,4.9c0.1,1.8,0.2,3.2,0.3,4.4c-0.9,0.3-2,0.8-3.1,1.7c-2.6,2.2-3,5.2-3.1,6
		c0.7-0.1,3.1-0.7,5-2.9c0.6-0.8,1.1-1.5,1.4-2.3c0,0.1,0,0.1,0,0.2c0.1,1.4,0.3,2.9,0.5,5c0.1,1.8,0.2,3.5,0.3,5.3
		c-0.9,0.3-2,0.8-3.1,1.7c-2.6,2.2-3,5.2-3.1,6c0.7-0.1,3.1-0.7,5-2.9c0.6-0.7,1-1.4,1.3-2c0,1.6,0.1,3.2,0.1,4.8
		c-0.6,0.7-1.2,1.9-1.6,3.3c-0.8,3.3,0.7,6,1,6.6c0.5-0.5,2.1-2.4,2.3-5.3c0.1-1.9-0.4-3.4-0.8-4.3c0-2.1,0-4.2-0.1-6.3
		c0.5,0.7,1.1,1.6,2.1,2.3c2.7,2.1,5.7,1.8,6.5,1.8c-0.3-0.7-1.3-2.9-3.9-4.3c-1.9-1-3.7-1.1-4.7-1c-0.1-1.9-0.2-3.7-0.3-5.5
		c-0.2-2.1-0.3-3.6-0.5-5c0-0.4-0.1-0.8-0.1-1.1c0.5,0.7,1.1,1.5,2.1,2.2c2.7,2.1,5.7,1.8,6.5,1.8c-0.3-0.7-1.3-2.9-3.9-4.3
		c-1.9-1-3.7-1.1-4.7-1c-0.1-1.2-0.2-2.6-0.3-4.5c-0.1-1.8-0.2-3.3-0.2-4.6c0.4,0.6,1,1.2,1.7,1.7c2.7,2.1,5.7,1.8,6.5,1.8
		c-0.3-0.7-1.3-2.9-3.9-4.3c-1.6-0.9-3.2-1-4.3-1c0-1.9,0-3.6,0.2-5.4c0.2-1.4,0.4-2.5,0.5-3.5c0.1-0.4,0.1-0.9,0.2-1.3
		c0.4,0.6,1,1.2,1.8,1.8c2.7,2.1,5.7,1.8,6.5,1.8c-0.3-0.7-1.3-2.9-3.9-4.3c-1.6-0.8-3-1-4.1-1c0.1-0.7,0.1-1.5,0.1-2.5
		c0-0.4,0-0.7,0-1.1c0.9,0.7,1.8,1.1,2.1,1.2c0.1-0.7,0.2-3.2-1.5-5.6c-0.6-0.9-1.3-1.5-1.9-2c-0.3-0.4-0.6-0.7-0.9-0.9
		c0,0-0.1,0-0.1-0.1c0.9-0.2,2-0.6,3-1.4c1.2,2.5,3.2,3.8,3.8,4.1c0.2-0.7,0.6-3.2-0.7-5.8c-0.1-0.2-0.2-0.4-0.3-0.6
		c0.2,0.2,0.5,0.4,0.7,0.6c1.7,1.4,2.7,2.3,3.5,2.9c0.1,0.1,0.3,0.2,0.4,0.3c-0.4,0.9-0.8,2.3-0.7,4.1c0.3,3.4,2.4,5.5,3,6
		c0.3-0.7,1.3-2.9,0.6-5.8c-0.3-1.3-0.8-2.3-1.4-3.1c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.6,0.5c1.8,1.3,3.8,2.4,6,3.3
		c0.5,0.8,1.2,1.9,2.4,2.7c2.7,2,5.7,1.7,6.5,1.6C412.9-62.5,411.8-64.7,409.2-66.1z M372.9-77.6C372.9-77.6,372.8-77.6,372.9-77.6
		c-0.4,0-0.8,0-1.2,0.1c0.3-0.2,0.7-0.3,1-0.5L372.9-77.6z M338.7-79c1.2,1.4,2.1,3.1,2.6,5.2c-0.6-0.6-1.4-1.4-2.6-1.9
		c-0.4-0.2-0.9-0.4-1.3-0.5L338.7-79z M335.1-70.8c0.5,0.2,0.9,0.5,1.3,0.9h-1.7L335.1-70.8z M334.2-71.2l-0.9,2.1
		c0.1-0.9,0-1.7-0.1-2.3C333.4-71.4,333.8-71.3,334.2-71.2z M328-66.7c0.5-1.2,1.4-3.1,2.9-4.1c-0.4,0.5-0.7,1-1,1.6
		c-1.5,3-0.7,6-0.5,6.7c0.5-0.3,1.8-1.3,2.8-3.1c-0.4,1.8-0.7,3.7-0.7,5.7c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.4-0.4-0.6-0.6
		c-1.4-1.3-2.9-1.9-4-2.1C327.1-64.5,327.5-65.8,328-66.7z M332.8-25.2c-0.3-0.3-0.5-0.6-0.9-0.9c-0.2-0.1-0.3-0.3-0.5-0.4
		c0.5-0.1,1-0.3,1.6-0.6c0.9-0.5,1.6-1,2.2-1.6C334.4-27.5,333.6-26.3,332.8-25.2z M336.6-31c-0.9-0.2-2.5-0.3-4.3,0.3
		c-1.8,0.6-3,1.7-3.8,2.6c-0.2-0.1-0.5-0.1-0.7-0.2c0.1-0.5,0.2-1,0.3-1.4c0.3-1.6,0.7-2.9,0.9-5.2c0.1-0.9,0.3-2.3,0.3-4.2
		c0.3,0.8,0.7,1.8,1.5,2.8c0.5,0.6,1.1,1.2,1.6,1.6c-0.1,0.2-0.2,0.3-0.2,0.4c0.2,0,0.6,0.1,1.1,0.1c1.5,0.9,2.9,1.1,3.4,1.2
		c0-0.3-0.1-0.8-0.4-1.5c0.6-0.2,1.1-0.4,1.7-0.7c0.6-0.3,1.2-0.7,1.6-1.1C338.5-34.1,337.3-32.2,336.6-31z M341.1-39.1
		C341.1-39.1,341-39.1,341.1-39.1c-1,0-2.3,0-3.8,0.5c-1,0.4-1.9,0.9-2.6,1.4c-0.2-0.3-0.5-0.6-0.8-0.9c-1.7-1.6-3.6-2-4.5-2.2
		c0-1.9-0.1-4.2-0.4-6.7c-0.1-1.1-0.3-2-0.4-2.7c0.1,0.1,0.2,0.3,0.3,0.4c2.3,2.5,5.3,2.9,6,3c-0.2-0.7-0.8-3.1-3-5
		c-1.5-1.2-3.1-1.7-4.1-1.9c-0.3-1.2-0.5-2.6-0.7-4.7c0-0.6-0.1-1.2-0.1-1.7c0.2,0.5,0.5,0.9,0.9,1.4c1.3,1.6,2.9,2.5,4.1,2.9
		c1.2,6.2,4.7,11.5,9.7,15C341.4-39.9,341.2-39.5,341.1-39.1z M340.4-70.4c0.2,0.1,0.6,0.3,1,0.5h-3.7c-0.7-0.8-1.5-1.4-2.3-1.9
		L336-73c0.2,0.1,0.5,0.2,0.7,0.3c2.1,0.6,3.8,0.3,4.8,0.1c0.1,0.4,0.2,0.8,0.2,1.2C341.1-71,340.6-70.6,340.4-70.4z M349.4-69.9
		c0.1-0.3,0.2-0.5,0.3-0.7c0.4-0.3,0.7-0.5,1-0.7c0.3,0.2,0.7,0.7,1.1,1.4H349.4z M354.6-69.9c0.1-0.6,0.2-1.1,0.4-1.6
		c0.8,0.5,2.1,1.2,3.7,1.4c0.9,0.1,1.8,0.1,2.6-0.1c0.1,0.1,0.1,0.2,0.2,0.3H354.6z M355.8-72.5C355.8-72.5,355.8-72.5,355.8-72.5
		c0.2-0.2,1.4-1.2,2.6-0.9c0,0,0,0,0,0C357.4-73.2,356.5-72.8,355.8-72.5z M363.9-69.9h-1.1c-0.1-0.2-0.2-0.3-0.3-0.5
		c0.6-0.2,1.1-0.4,1.5-0.6C363.9-70.6,363.9-70.2,363.9-69.9z M361.9-80.6c-0.3,0.7-1.1,3-0.3,5.8c0.2,0.6,0.4,1.1,0.6,1.6
		c-0.6-0.2-1.4-0.3-2.2-0.3c-0.5-0.5-0.9-0.7-1.5-0.8c-0.8-0.1-1.5,0-2,0.3c0.3-0.6,0.5-1.4,0.6-2.3c0.4-3.4-1.3-5.9-1.7-6.5
		c-0.4,0.6-1.8,2.6-1.8,5.6c0.1,1.9,0.7,3.4,1.3,4.3c-0.8,0.8-1.2,1.9-1.3,3.1H353c-0.3-0.6-0.6-1.2-1-1.6c0.4-0.8,0.8-2,0.8-3.5
		c0.1-3.4-1.9-5.7-2.4-6.3c-0.4,0.6-1.6,2.8-1.2,5.7c0.2,1.3,0.6,2.4,1.1,3.2c-0.2,0-0.4,0.1-0.6,0.2c-0.8-0.3-1.8-0.6-3.1-0.7
		c-1.6,0-2.9,0.4-3.9,0.8c-0.1-0.6-0.2-1.1-0.3-1.6c1-0.5,2.6-1.4,3.8-3.3c1.4-2.2,1.4-4.4,1.3-5.2c-0.8,0.3-2.4,1-3.7,2.5
		c-1.2,1.4-1.7,2.8-2,4c-0.6-1.8-1.5-3.2-2.7-4.4l18.3-41.6L372.4-79c-0.8,0.4-1.6,0.8-2.5,1.3c0.2-0.5,0.4-1.2,0.5-1.8
		c0.5-3.4-1.1-5.9-1.6-6.5c-0.5,0.6-1.9,2.6-1.9,5.5c0,1.7,0.5,3.1,1,4c-1.5,0.9-2.2,1.6-2.6,2.1c0-0.1,0-0.2,0-0.4
		C364.8-78.2,362.5-80.1,361.9-80.6z M371-69.9c1.8-0.9,2.9-2.1,3.2-2.5c-0.7-0.3-3-1.1-5.8-0.3c-1.6,0.5-2.8,1.4-3.6,2.1
		c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0.1-0.3
		c0.2-0.5,0.4-1,0.7-1.5c0.4-0.5,1.1-1.2,2.3-1.9c0.8,0.5,2,1.2,3.6,1.4c0.8,0.1,1.6,0.1,2.3,0l1.5,4.2H371z M378.5-73
		c-0.7,1.7-0.7,3.2-0.6,4.2c0,0,0,0-0.1,0c-0.2-0.4-0.3-0.8-0.5-1.2h-0.7l-1.5-4.4c1.7-0.4,2.8-1.1,3.2-1.4
		c-0.5-0.4-2.1-1.5-4.4-1.8l-0.3-0.9c0.7-0.3,1.5-0.6,2.3-0.8c0.6,0.8,1.5,1.8,2.8,2.5c0.6,0.3,1.2,0.6,1.8,0.7
		C379.8-75.3,379-74.3,378.5-73z M387.9-76.8c-0.3,0.2-0.6,0.5-0.9,0.8c-1.5,1.6-2.1,3.4-2.3,4.3c-0.1,0-0.2,0-0.2,0
		c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.9,0-1.8,0.2-2.9,0.7c1-1.7,1.2-3.4,1.1-4.6c1.4,0.1,2.4-0.2,2.8-0.3c-0.4-0.6-1.8-2.7-4.5-3.7
		c-0.5-0.2-0.9-0.3-1.4-0.4c1.7-0.2,3.7-0.2,5.8,0.5c0.7,0.2,1.5,0.8,2.6,1.5C387.9-77.6,387.9-77.2,387.9-76.8z M389.6-77.9
		C389.6-77.9,389.6-77.9,389.6-77.9c0-0.1,0.1-0.1,0.2-0.1C389.7-77.9,389.7-77.9,389.6-77.9z"/>
                                                <path className="bg" d="M131.5,47.1h37v-51h-9.9l-7.5-15l-9.4,15h-10.2V47.1z M151-16.9l6.5,13h-14.6L151-16.9z M135.5,0.1h29v43h-29
		V0.1z"/>
                                                <path className="bg"
                                                      d="M76.9,58.1H66.5v40h41v-40H97.1l-9-18L76.9,58.1z M101.5,63.1v29h-29v-29H101.5z M78,58.1l10-16l7.9,16H78z"/>
                                                <path className="bg" d="M410.5,7.9l0.6-0.3v-8.5L96,145.6l3.5,5.5l19-8.8v126.8v9v31H2.5v2h409v-2h-291v-31h57v-9v-15h59v-9v-17
		c13.7,0.3,30.1,0.5,43.6,0.8c5.5,0.1,10.9-1.1,15.9-3.4l115.5-56.4v-10c-0.3,0.2-0.7,0.3-1,0.5V7.9z M408.5,160.6
		c-19.7,9.6-39.3,19.1-59,28.7V36l59-27.2V160.6z M120.5,269.1V141.4l55-25.3v153H120.5z M177.5,245.1V115.2l57-26.3v156.2H177.5z
		 M236.5,219.1V88l54-24.9v154.4c-3.1,1-6.4,1.6-9.8,1.6H236.5z M294.4,216c-0.6,0.3-1.3,0.6-1.9,0.8V62.2l55-25.3v153.3
		C329.8,198.8,312.1,207.4,294.4,216z"/>
                                            </g>

                                            <g className="g2">
                                                <path className="st0"
                                                      d="M255.1,2h200.1c4.4,0,8,3.6,8,8v126.2c0,4.4-3.6,8-8,8H255.1c-4.4,0-8-3.6-8-8V10C247.1,5.6,250.7,2,255.1,2z"
                                                />
                                                <g>
                                                    <path className="st1" d="M428.2,124.2h-43.3c-2.1,0-3.9-1.7-3.9-3.9V107c0-2.1,1.7-3.9,3.9-3.9h43.3c2.1,0,3.9,1.7,3.9,3.9v13.3
			C432.1,122.4,430.3,124.2,428.2,124.2z"/>
                                                    <text x="275" y="33" fontSize="16px" className="st2">
                                                        Учётная система
                                                    </text>
                                                    <g>
                                                        <g>
                                                            <path className="st3" d="M372.8,82.7h-13.3c-2.1,0-3.9-1.7-3.9-3.9V65.6c0-2.1,1.7-3.9,3.9-3.9h13.3c2.1,0,3.9,1.7,3.9,3.9v13.3
					C376.7,81,375,82.7,372.8,82.7z"/>
                                                            <g>
                                                                <path className="st4"
                                                                      d="M367.7,71.8v0.8h-3v-0.8H367.7z"/>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <path className="st5" d="M428.2,82.7h-13.3c-2.1,0-3.9-1.7-3.9-3.9V65.6c0-2.1,1.7-3.9,3.9-3.9h13.3c2.1,0,3.9,1.7,3.9,3.9v13.3
					C432.1,81,430.3,82.7,428.2,82.7z"/>
                                                            <g>
                                                                <path className="st6"
                                                                      d="M421.9,69v2.8h2.7v0.7h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7V69H421.9z"/>
                                                            </g>
                                                        </g>

                                                        <text transform="matrix(1 0 0 1 387.6612 79.3183)"
                                                              fontSize={"24.2268px"}>3
                                                        </text>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path className="st3" d="M321.7,59.6c-5.3-5.3-12.3-8.2-19.7-8.2c-7.4,0-14.5,2.9-19.7,8.2s-8.2,12.3-8.2,19.7
						c0,7.4,2.9,14.5,8.2,19.7c5.3,5.3,12.3,8.2,19.7,8.2c7.4,0,14.5-2.9,19.7-8.2c5.3-5.3,8.2-12.3,8.2-19.7
						C329.8,71.8,326.9,64.8,321.7,59.6z M301.9,101.7c-12.4,0-22.4-10.1-22.4-22.4c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
						c0.1-12.2,9.9-22.1,22.1-22.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c12.4,0,22.4,10.1,22.4,22.4
						S314.3,101.7,301.9,101.7z"/>
                                                            </g>
                                                        </g>
                                                        <g>
                                                            <g>
                                                                <path className="st7" d="M319,80.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h2c-0.9-9.2-8.4-16.4-17.6-17.1V62
						c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6v-1.7c-9.1,0.9-16.3,8.2-17.2,17.3h1.8c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
						h-1.8c0.8,9.3,8.1,16.7,17.4,17.5v-2.1c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v2.1c9.3-0.8,16.8-8.3,17.4-17.7H319z
						 M308.6,74.9l-5.5,5.5c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.9-0.2-1.2-0.5l-9.6-9.6c-0.6-0.6-0.6-1.7,0-2.3
						c0.6-0.6,1.7-0.6,2.3,0l8.4,8.4l4.3-4.3c0.6-0.6,1.7-0.6,2.3,0C309.3,73.3,309.3,74.3,308.6,74.9z"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <path d="M426,115.6c-0.4,0.2-0.7,0.5-1,0.8c-1-0.9-2.4-1.1-3.6-0.5c-0.5,0.2-0.8,0.6-1.1,0.9c-1-0.7-2.2-0.8-3.4-0.3
				c-0.3,0.2-0.6,0.4-0.9,0.6l-1.6-3.2c-0.8-1.6-2.8-2.3-4.4-1.4c-1.6,0.8-2.2,2.8-1.4,4.4l5.8,11.5l-3.3-1.3c0,0,0,0-0.1,0
				c-0.8-0.3-1.7-0.2-2.5,0.2l0,0c-0.8,0.4-1.3,1.1-1.6,1.9c-0.5,1.4,0.2,2.9,1.8,4c2.1,1.4,4.3,2.6,6.2,3.7
				c1.4,0.8,2.7,1.6,3.7,2.2c0.9,0.6,3.5,2.6,3.5,2.6c0.3,0.3,0.8,0.3,1.2,0.1l12.3-6.3c0.4-0.2,0.7-0.7,0.6-1.1
				c-0.1-0.8-1.3-8.2-2.6-10.7l-3.4-6.7C429.5,115.4,427.6,114.7,426,115.6z M431.8,124.7c0.8,1.6,1.8,6.6,2.2,9.2l-11,5.6
				c-0.8-0.6-2.4-1.8-3.1-2.3c-1.1-0.7-2.4-1.5-3.8-2.3c-1.9-1.1-4-2.3-6-3.7c-0.5-0.4-1.1-0.9-1-1.5c0.1-0.3,0.3-0.5,0.5-0.6
				c0.2-0.1,0.5-0.1,0.8-0.1l5.9,2.4c0.4,0.2,0.9,0.1,1.2-0.3c0.3-0.3,0.4-0.8,0.2-1.2l-7.1-14.1c-0.3-0.5-0.1-1.2,0.5-1.4
				c0.5-0.3,1.2,0,1.4,0.5l4.6,9.1c0.3,0.5,0.9,0.8,1.5,0.5s0.8-0.9,0.5-1.5l-1.6-3.2c-0.3-0.5-0.1-1.2,0.5-1.4
				c0.5-0.3,1.2,0,1.4,0.5l2.2,4.4c0.3,0.5,0.9,0.8,1.5,0.5c0.5-0.3,0.8-0.9,0.5-1.5l-1.6-3.2c-0.3-0.5-0.1-1.2,0.5-1.4
				c0.5-0.3,1.2,0,1.4,0.5l2.2,4.4c0.3,0.5,0.9,0.8,1.5,0.5c0.5-0.3,0.8-0.9,0.5-1.5l-1.4-2.8c-0.3-0.5-0.1-1.2,0.5-1.4
				c0.5-0.3,1.2,0,1.4,0.5L431.8,124.7z"/>
                                                        <path className="st8" d="M428.4,118l3.4,6.7c0.8,1.6,1.8,6.6,2.2,9.2l-11,5.6c-0.8-0.6-2.4-1.8-3.1-2.3c-1.1-0.7-2.4-1.5-3.8-2.3
				c-1.9-1.1-4-2.3-6-3.7c-0.5-0.4-1.1-0.9-1-1.5c0.1-0.3,0.3-0.5,0.5-0.6c0.2-0.1,0.5-0.1,0.8-0.1l5.9,2.4c0.4,0.2,0.9,0.1,1.2-0.3
				c0.3-0.3,0.4-0.8,0.2-1.2l-7.1-14.1c-0.3-0.5-0.1-1.2,0.5-1.4c0.5-0.3,1.2,0,1.4,0.5l4.6,9.1c0.3,0.5,0.9,0.8,1.5,0.5
				s0.8-0.9,0.5-1.5l-1.6-3.2c-0.3-0.5-0.1-1.2,0.5-1.4c0.5-0.3,1.2,0,1.4,0.5l2.2,4.4c0.3,0.5,0.9,0.8,1.5,0.5
				c0.5-0.3,0.8-0.9,0.5-1.5l-1.6-3.2c-0.3-0.5-0.1-1.2,0.5-1.4c0.5-0.3,1.2,0,1.4,0.5l2.2,4.4c0.3,0.5,0.9,0.8,1.5,0.5
				c0.5-0.3,0.8-0.9,0.5-1.5l-1.4-2.8c-0.3-0.5-0.1-1.2,0.5-1.4C427.5,117.2,428.1,117.5,428.4,118z"/>
                                                    </g>
                                                </g>
                                            </g>

                                            <g className="g3">
                                                <path className="st0" d="M19.7,54.6l-8.5-23.5C9.7,27,5.1,24.8,1,26.3l-69.7,25.2c-4.2,1.5-6.3,6.1-4.8,10.2l8.5,23.5
		c1.5,4.2,6.1,6.3,10.2,4.8l39.2-14.1c1.3,0.9,3.4,2.1,6.2,3.3c4.3,1.8,11,3.3,11,3.3s-2.9-4.6-2.2-7.7c0.4-1.6,1-3.3,1.6-4.8l14-5
		C19,63.4,21.2,58.8,19.7,54.6z"/>
                                                <path className="st1" d="M-84.3,41.5l19.8-6.9c4.1-1.5,8.6,0.6,10,4.8l7,19.8c1.5,4.1-0.6,8.7-4.7,10.2L-72,76.2
		c-4.1,1.5-8.6-0.6-10-4.8l-7-19.8C-90.5,47.5-88.4,43-84.3,41.5z"/>
                                                <g>
                                                    <path className="st2" d="M-71.4,50.2c-0.1-0.4-0.6-0.6-1-0.5l-1.2,0.4l1.1-2.3c0.1-0.3,0.1-0.6-0.1-0.8c-0.2-0.2-0.5-0.3-0.8-0.2
			l-2.9,1.1c-0.4,0.1-0.6,0.6-0.5,1c0.1,0.4,0.6,0.6,1,0.5l1.2-0.4l-1.1,2.3c-0.1,0.3-0.1,0.6,0.1,0.8c0.2,0.2,0.5,0.3,0.8,0.2
			l2.9-1.1C-71.5,51-71.3,50.6-71.4,50.2z"/>
                                                    <path className="st2" d="M-71.4,45.5c0.2,0.2,0.5,0.3,0.8,0.2l2.9-1.1c0.4-0.1,0.6-0.6,0.5-1c-0.1-0.4-0.6-0.6-1-0.5l-1.2,0.4l1.1-2.3
			c0.1-0.3,0.1-0.6-0.1-0.8c-0.2-0.2-0.5-0.3-0.8-0.2l-2.9,1.1c-0.4,0.1-0.6,0.6-0.5,1c0.1,0.4,0.6,0.6,1,0.5l1.2-0.4l-1.1,2.3
			C-71.7,45-71.6,45.3-71.4,45.5z"/>
                                                    <path className="st2" d="M-59.2,49.8l-10.2,3.7c-1.2,0.4-1.8,1.8-1.4,3l1.1,2.9c0.1,0.4,0.6,0.6,1,0.5l13.8-5l-1.3-3.6
			C-56.7,50-58,49.4-59.2,49.8z"/>
                                                    <path className="st2" d="M-71.2,59.9l-0.3-0.7l-7.3,2.6l-2.9-8c-0.1-0.4-0.6-0.6-1-0.5s-0.6,0.6-0.5,1l5.8,16.1c0.1,0.4,0.6,0.6,1,0.5
			s0.6-0.6,0.5-1l-0.8-2.2l21.9-7.9L-54,62c0.1,0.4,0.6,0.6,1,0.5c0.4-0.1,0.6-0.6,0.5-1l-1.9-5.2l-13.8,5
			C-69.4,61.8-70.7,61.1-71.2,59.9z"/>
                                                    <path className="st2" d="M-72.8,55.6c0.4,1.2-0.2,2.5-1.4,3c-1.2,0.4-2.5-0.2-3-1.4c-0.4-1.2,0.2-2.5,1.4-3
			C-74.5,53.8-73.2,54.4-72.8,55.6z"/>
                                                </g>
                                                <text transform="matrix(0.9224 -0.3863 0.3863 0.9224 -42.0289 77.9675)"
                                                      fontSize={"16px"}
                                                      className="st2 st3 st4">5 часов
                                                </text>
                                            </g>

                                            <g className="g4">
                                                <g>
                                                    <path className="st0" d="M159.2,244.6h-59c-3.3,0-5.9,2.3-5.9,5.1v81.8c0,2.8,2.6,5.1,5.9,5.1h59c3.3,0,5.9-2.3,5.9-5.1v-81.8
			C165.1,246.9,162.5,244.6,159.2,244.6z"/>
                                                    <path className="st1" d="M145.8,212.9v31.7h-2.2v-32.2c0-1.5-1.4-2.8-3.2-2.8h-22c-1.8,0-3.2,1.2-3.2,2.8v32.2h-2.2v-31.7
			c0-2.8,2.6-5.1,5.9-5.1h20.9C143.2,207.8,145.8,210.1,145.8,212.9z"/>
                                                    <path className="st2" d="M107.8,258.8c0.9,0,1.6,0.6,1.6,1.4v62.1c0,0.8-0.7,1.4-1.6,1.4c-0.9,0-1.6-0.6-1.6-1.4v-62.1
			C106.2,259.5,106.9,258.8,107.8,258.8z"/>
                                                    <path className="st2" d="M129.2,258.8c0.9,0,1.6,0.6,1.6,1.4v62.1c0,0.8-0.7,1.4-1.6,1.4c-0.9,0-1.6-0.6-1.6-1.4v-62.1
			C127.6,259.5,128.3,258.8,129.2,258.8z"/>
                                                    <path className="st2" d="M150.7,258.8c0.9,0,1.6,0.6,1.6,1.4v62.1c0,0.8-0.7,1.4-1.6,1.4c-0.9,0-1.6-0.6-1.6-1.4v-62.1
			C149.1,259.5,149.8,258.8,150.7,258.8z"/>
                                                </g>
                                                <g>
                                                    <path className="st0" d="M158.6,243.6h-59c-3.3,0-5.9,2.3-5.9,5.1v81.8c0,2.8,2.6,5.1,5.9,5.1h59c3.3,0,5.9-2.3,5.9-5.1v-81.8
			C164.5,245.8,161.8,243.6,158.6,243.6z"/>
                                                    <path className="st1" d="M145.1,211.9v31.7H143v-32.2c0-1.5-1.4-2.8-3.2-2.8h-22c-1.8,0-3.2,1.2-3.2,2.8v32.2h-2.2v-31.7
			c0-2.8,2.6-5.1,5.9-5.1h20.9C142.5,206.8,145.1,209.1,145.1,211.9z"/>
                                                    <path className="st2" d="M107.1,257.8c0.9,0,1.6,0.6,1.6,1.4v62.1c0,0.8-0.7,1.4-1.6,1.4c-0.9,0-1.6-0.6-1.6-1.4v-62.1
			C105.5,258.4,106.2,257.8,107.1,257.8z"/>
                                                    <path className="st2" d="M128.5,257.8c0.9,0,1.6,0.6,1.6,1.4v62.1c0,0.8-0.7,1.4-1.6,1.4c-0.9,0-1.6-0.6-1.6-1.4v-62.1
			C126.9,258.4,127.6,257.8,128.5,257.8z"/>
                                                    <path className="st2" d="M150,257.8c0.9,0,1.6,0.6,1.6,1.4v62.1c0,0.8-0.7,1.4-1.6,1.4s-1.6-0.6-1.6-1.4v-62.1
			C148.4,258.4,149.1,257.8,150,257.8z"/>
                                                </g>
                                                <g>
                                                    <radialGradient id="SVGID_1_" cx="342.7341" cy="158.2146"
                                                                    r="17.4572"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.5792" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </radialGradient>
                                                    <path className="st3" d="M330.4,147.9c-0.4,3.5-1.5,6.9-2.3,10.4c-0.8,3.5-1.4,7.1-0.6,10.5s3.1,6.8,6.5,7.8c2.4,0.8,5.1,0.3,7.5-0.4
			c4.8-1.4,9.3-3.9,12.6-7.7c3.3-3.8,5.1-8.9,4.4-13.8c-1.1-6.9-7-12.3-13.6-14.5c-1.7-0.6-3.5-1-5.3-0.8c-1.4,0.2-2.7,0.7-3.9,1.3
			c-3.3,1.6-6.3,3.9-8.8,6.7"/>
                                                </g>
                                                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse"
                                                                x1="68.8128"
                                                                y1="175.6622" x2="46.6343" y2="228.3255">
                                                    <stop offset="0.2553" stopColor="#776EE4"/>
                                                    <stop offset="0.5235" stopColor="#6866C6"/>
                                                </linearGradient>
                                                <path className="st4" d="M89.7,187.7c1.3,5-1.1,9.8-5.4,18.3c-4.7,9.3-8.1,16.1-14.9,18c-9.2,2.6-18.8-5.5-24.8-10.6
		c-5.8-4.9-9.4-10-11.6-13.6c0.7-2.5,1.9-6.7,3.4-11.9c2.8-9.9,2.9-10.4,3.4-11.2C46.8,166.3,85.6,171.8,89.7,187.7z"/>
                                                <path className="st5" d="M161.4,205c0,0,2.3,2,5.6,2.4c4.3,0.5,7,3.7,7,3.7l-7.5,9.3c0,0-0.6-1.8-4.8-3.3c-3.4-1.2-7.9-6.6-7.9-6.6
		L161.4,205z"/>
                                                <path className="st5" d="M159.6,203.9c0,0,1.9,2.4,5,3.5c4.1,1.3,9.8,1.3,9.8,1.3l-9.2,7.6c0,0-3-0.3-6.9-2.6c-3.1-1.9-7.3-5.9-7.3-5.9
		L159.6,203.9z"/>
                                                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse"
                                                                x1="29.9328"
                                                                y1="332.34" x2="41.7573" y2="332.34">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st6" d="M41.8,323.4c-1,6.1-2.1,12.1-3.1,18.2c-2.9-0.7-5.8-1.3-8.7-2c0.5-5.5,1.1-11,1.6-16.6
		C34.9,323.2,38.3,323.3,41.8,323.4z"/>
                                                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse"
                                                                x1="66.4466"
                                                                y1="314.815" x2="85.4749" y2="314.815">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st7" d="M66.4,313.6c3.6,3.8,7.2,7.5,10.7,11.3c2.8-2.5,5.5-5,8.3-7.4c-4.4-4.3-8.8-8.5-13.2-12.8
		C70.4,307.7,68.4,310.7,66.4,313.6z"/>
                                                <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse"
                                                                x1="28.0371"
                                                                y1="359.3518" x2="28.0371" y2="365.4717">
                                                    <stop offset="0" stopColor="#EDEDF9"/>
                                                    <stop offset="1" stopColor="#CFCFE2"/>
                                                </linearGradient>
                                                <path className="st8" d="M12.7,357.7c-0.2,1.5-0.3,3.8,1,5.5c2,2.5,5.9,2.2,10.3,1.9c4.1-0.3,5.8-1,11.7-2.1c3.3-0.6,6.1-0.9,7.8-1.1
		c-0.1-2.1-0.2-4.2-0.3-6.3c-1,0.3-2,0.6-3.1,0.9C30.1,359.2,20.7,359,12.7,357.7z"/>
                                                <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse"
                                                                x1="17.1092"
                                                                y1="342.6972" x2="34.0461" y2="342.6972">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#5252CC"/>
                                                </linearGradient>
                                                <path className="st9" d="M33.9,340.3c-1-4.9-6.4-8.5-8.2-7.5c-1.6,0.9,0.4,4.6-1.1,9.5c-1.3,4.2-4.5,7.1-7.4,9c1,0.5,4.6,2.4,8.7,1.2
		C31,350.9,34.9,345.3,33.9,340.3z"/>
                                                <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse"
                                                                x1="12.5782"
                                                                y1="349.0315" x2="43.602" y2="349.0315">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#615DE2"/>
                                                </linearGradient>
                                                <path className="st10" d="M42.5,339.1c-0.9-0.6-1.5,0-4.8-0.2c-2.7-0.1-3-0.6-3.9-0.2c-1.1,0.5-1.4,1.4-2.9,4.8c-2.2,5-2.5,5.3-2.9,5.6
		c-2,1.4-3.9-0.2-7.1,0.3c-4.8,0.8-9.1,6-8.3,8.2c0.6,1.5,4.6,1.6,12.6,1.8c4,0.1,6,0.1,8.6-0.4c4.1-0.8,7.2-2.4,9.3-3.6
		c0.1-5,0.3-8.3,0.3-10.2C43.7,341.9,43.7,339.9,42.5,339.1z"/>
                                                <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse"
                                                                x1="74.7154"
                                                                y1="340.9404" x2="102.7089" y2="340.9404">
                                                    <stop offset="0" stopColor="#EDEDF9"/>
                                                    <stop offset="1" stopColor="#CFCFE2"/>
                                                </linearGradient>
                                                <path className="st11" d="M74.7,353c0.8,1.2,2.3,2.9,4.6,2.9c3.3,0,6.3-3.4,9.7-7.3c3.2-3.6,4-5.6,8.1-11.2c2.3-3.2,4.3-5.7,5.6-7.3
		c-1.5-1.4-3-2.7-4.5-4.1c-0.6,1.1-1.2,2.1-1.9,3.2C90,339.4,82.1,347.2,74.7,353z"/>
                                                <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse"
                                                                x1="68.2475"
                                                                y1="333.4418" x2="83.8506" y2="333.4418">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#5252CC"/>
                                                </linearGradient>
                                                <path className="st12" d="M80.1,323.2c-4.1-2.6-11-0.5-11.8,1.7c-0.7,1.9,3.4,2.9,5.5,7.5c1.8,4,1.1,8.7,0,12.4
		c1.1-0.4,5.3-2.2,7.9-6.5C85,333,84.4,325.9,80.1,323.2z"/>
                                                <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse"
                                                                x1="73.1431"
                                                                y1="334.1139" x2="98.1963" y2="334.1139">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#615DE2"/>
                                                </linearGradient>
                                                <path className="st13" d="M86.4,315.2c-1.1,0.3-1.2,1.2-4.1,3.9c-2.3,2.2-2.8,2.1-3.3,3.1c-0.6,1.2-0.2,2.2,0.9,5.8
		c1.6,5.3,1.6,5.7,1.5,6.3c-0.7,2.7-3.3,3.1-5.5,6.1c-3.4,4.6-3.4,11.8-1.1,12.6c1.5,0.6,4.9-2.7,11.6-9.4c3.3-3.3,5-5,6.8-7.5
		c2.8-4,4.3-7.7,5.1-10.2c-3.3-3.6-5.4-5.9-6.7-7.3C89.3,316.2,88,314.8,86.4,315.2z"/>
                                                <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse"
                                                                x1="38.7865"
                                                                y1="234.6313" x2="76.6236" y2="279.2549">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#836EC3"/>
                                                </linearGradient>
                                                <path className="st14" d="M41,186c-1.1,1.8-2.7,4.2-4.4,7.3c-7.6,13.4-11.3,24.6-12.1,27.3c-4,12.6-6.8,21.3-5.6,31.7
		c1.8,16.4,12.5,27.9,33.8,51.1c5.6,6.1,10.5,11,14,14.3c3.5-3.8,7-7.7,10.5-11.5c-4-5-9.8-12.3-16.7-21.2
		c-21.7-28-22.4-30.8-22.7-33.5c-2.2-19.3,20.2-29,16.7-47.1C52.7,195.3,45.7,189.3,41,186z"/>
                                                <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse"
                                                                x1="54.4249"
                                                                y1="233.438" x2="54.3946" y2="285.5319">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#A18CDC"/>
                                                </linearGradient>
                                                <path className="st15" d="M80.3,205.4c0.8,6.9-3.1,12.6-4.8,15.1c0,0-5.7,8.6-15.8,32.9c-2.9,7.1-2.5,8.6-7.8,34.9
		c-1.9,9.5-4.5,21.9-7.8,36.5c-1.4,0.5-3.9,1.3-7,1.1c-4.5-0.3-7.6-2.6-8.8-3.5c0.5-2,1.2-5,2-8.7c2-10.2,2.7-17.9,3-20.6
		c1.1-11.7,3.1-24,5.3-37.9c3.4-21.1,9.8-60.7,24.6-63.2c3.3-0.5,9,0.5,12.8,4.1C79.6,199.6,80.2,204.2,80.3,205.4z"/>
                                                <g>
                                                    <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse"
                                                                    x1="145.5917" y1="189.4975" x2="110.0766"
                                                                    y2="134.0126">
                                                        <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </linearGradient>
                                                    <path className="st16" d="M163.3,205.2c0.3-0.2,0.8,0,1.5,0.3c1.7,0.8,2.6,1.2,2.9,1.4c1.8,1.3,1.4,2.3,3.2,3.9
			c0.2,0.2,2.2,1.9,2.9,1.4c0.9-0.6-0.2-4.2-1.8-6.8c-2.5-4.3-6.5-6.4-8.3-7.2c-3.6-1.5-11.4-3.4-14-4c-0.6-0.2-1.3-0.3-1.8-0.8
			c-0.7-0.6-0.9-1.5-0.9-1.7c-0.7-2.9-11-17.7-12.7-20.1c-1.1-1.6-4.7-6.5-11.8-16.2c-2.3-3.2-4.3-5.8-5.6-7.6
			c-0.8,1.2-2.1,2.9-4.1,4.5c-1.6,1.2-3.2,2-4.4,2.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3
			c0,0.3,0.2,0.7,0.3,1c2.8,5.5,6.7,10.4,10.5,15.3c0.6,0.8,4.5,5.7,12.1,15.5c9.3,12.1,11.4,15.4,15.7,15.9c2,0.2,4.7-0.1,7.7,1.6
			c1.8,1,3.7,2.1,4.7,4.2c0.3,0.6,0.5,1.2,1.2,1.9c1.3,1.1,3.4,1.5,3.8,0.8c0.5-0.7-1.1-2.1-1.3-5.1
			C162.9,206.1,162.9,205.4,163.3,205.2z"/>
                                                </g>
                                                <g>
                                                    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse"
                                                                    x1="41.1354" y1="202.2566" x2="89.8583"
                                                                    y2="129.2548">
                                                        <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                        <stop offset="0.9308" stopColor="#B87CD8"/>
                                                    </linearGradient>
                                                    <path className="st17" d="M99.7,141c0,1.5-0.1,5.8-1.3,12.5c-0.8,4.2-1,4.2-2,8.7c-1.2,5.7-1.2,8.1-2.6,18.1
			c-0.4,2.6-0.9,6.5-1.6,11.3c-13.7-4.2-25.1-6.6-33.3-8c-11.6-1.9-19-2.3-20.7-6.9c-1.5-3.9,3-6.2,6.2-18.8c0.5-2,1.2-5.9,2.5-13.8
			c1.2-7,1.8-10.5,1.8-11.9c0.1-5.8-0.2-6.6,0.9-8.5c0.3-0.6,1-1.6,5.2-4.3c1.2-0.7,2.6-1.6,4.3-2.6c1.1-0.6,2.2-1.2,3.1-1.7
			c0.8-0.4,1.5-0.8,2.1-1.1c-0.8,0.8-2.2,2.5-2.9,5.1c-1,3.8,0.4,7,0.8,7.9c0.8-0.2,1.4-1.1,2.1-1.4c0.7-0.3,6.6-1.5,6.6-1.5
			c0,0,2.6-0.5,2.8-0.5c1.3-0.2,4.5-1.2,7.1-2.6c2.1-1.2,3.9-2.7,4-4.4c0-0.1,0-0.3,0-0.5l-0.1-2.1c0.1,0,0.3,0.1,0.4,0.1
			c3.6,1.4,6.1,4.7,8.3,7.9c0.1,0.2,0.3,0.4,0.4,0.6c2.1,3,4.2,6.1,5.2,9.7C99.8,134.8,99.8,136.9,99.7,141z"/>
                                                </g>
                                                <g>
                                                    <path className="st18" d="M95.4,122.3c-0.6-0.2-1.3-0.3-1.9-0.5c-6.1-1.1-12.6,0.6-17.3,4.7c-1.1,0.9-2.1,2-3.4,2.6
			c-1.1,0.5-2.5,0.7-3.5,0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-1.2-1.1-1.5-3.5-3.2-3.5c-0.6,0-1.1,0.3-1.5,0.7c-1.4,1-2.5,2.3-3.5,3.7
			c0-1.9,0-3.9-0.1-5.8l-0.8,0.8c-0.7,0.2-1.5,0.3-2.2,0.5c-1.1,0.2-2.1,0.5-3.2,0.7c-0.5-2.2-0.2-4.4,0.5-6.5c0.7-2.1,2-4,3.7-5.4
			c3.3-2.9,8-4.1,12.4-3.4c0.1,0,0.2,0,0.3,0l0.1,1.7c-0.6,0-1.4,0-2.4,0.1c-0.1,0-0.6,0.1-1.1,0.2c-0.1,0-2,0.5-3.5,1.6
			c-2,1.5-2.7,3.9-2.9,4.8c-1,3.7,0.3,6.8,0.8,7.9c0.8-0.2,1.4-1.1,2.1-1.4c0.7-0.3,6.6-1.5,6.6-1.5c0,0,2.6-0.5,2.8-0.5
			c1.3-0.2,4.5-1.2,7.1-2.6c2.1-1.2,3.9-2.7,4-4.4c0-0.1,0-0.3,0-0.5l-0.2-3.9c1.7,0.4,3.3,1.1,4.9,2
			C92.6,115.8,94.9,118.8,95.4,122.3z"/>
                                                </g>
                                                <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse"
                                                                x1="27.8554"
                                                                y1="138.0863" x2="55.1936" y2="138.0863">
                                                    <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                    <stop offset="0.9308" stopColor="#B87CD8"/>
                                                </linearGradient>
                                                <path className="st19" d="M53.7,121c-0.4-0.2-1-0.4-3.1,0.1c-1.2,0.3-5.8,1.6-14.4,10.9c-3.2,3.4-3,3.8-5.2,5.7c-1.9,1.7-2.7,2.1-3,3.3
		c-0.4,1.8,1.2,3,4.2,6.9c4.3,5.4,4.7,7.5,6.6,7.6c0.9,0,1.8-0.3,4.6-3.4c3.2-3.5,4.8-5.2,6.7-8.9C54,135.4,57.2,123,53.7,121z"/>
                                                <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse"
                                                                x1="89.5424"
                                                                y1="137.785" x2="117.8107" y2="137.785">
                                                    <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                    <stop offset="0.9308" stopColor="#B87CD8"/>
                                                </linearGradient>
                                                <path className="st20" d="M95.1,119.1c6.8-1.9,17.5,15.5,22.7,24.9c-0.1,1.2-0.5,4.8-3.3,8.1c-3.5,3.9-8,4.4-9,4.5
		c-1.3-0.6-14-7-15.7-20.2C88.9,129.9,90.6,120.4,95.1,119.1z"/>
                                                <g>
                                                    <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse"
                                                                    x1="311.9693" y1="259.9837" x2="382.2085"
                                                                    y2="259.9837">
                                                        <stop offset="0.2553" stopColor="#3E49F8"/>
                                                        <stop offset="0.9308" stopColor="#8D98FF"/>
                                                    </linearGradient>
                                                    <path className="st21" d="M312,302.1c-0.5,2.6,32.5,19.5,55.4,6.2c2.6-1.5,8.4-5,11.8-11.8c5.1-10.2-1.8-15.4-1.5-32.5
			c0.2-14.8,5.1-22.3,4.4-43.6c-0.1-2.7-0.3-5.6-2.2-8.1c-6.3-8-27.3-7.7-35.9,2.2c-6.7,7.7-5.3,22.3-2.4,51.5
			c0.8,8.1,1.9,17-3.4,24.4c-4,5.6-10,8.1-11.8,8.9C318.1,302.9,312.2,301,312,302.1z"/>
                                                </g>
                                                <g>
                                                    <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse"
                                                                    x1="150.8593" y1="327.1204" x2="196.19"
                                                                    y2="327.1204">
                                                        <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                        <stop offset="0.9308" stopColor="#B87CD8"/>
                                                    </linearGradient>
                                                    <path className="st22" d="M196.2,309.3v57.4l0,14.2c-1.9-0.6-3.8-1.2-5.7-1.9c-14.3-5.1-25.4-11.2-32.2-18.2
			c-4.5-4.6-7.1-9.7-7.4-14.9c0,0,0,0,0,0c0-0.4,0-0.8,0-1.1v-71.4l0.6,4.5c1,3.9,3.4,7.7,6.8,11.3c6.8,7,17.9,13.2,32.2,18.2
			c0.8,0.3,1.6,0.5,2.3,0.8C193.9,308.5,195.1,308.9,196.2,309.3z"/>
                                                    <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse"
                                                                    x1="282.546" y1="429.5867" x2="282.546"
                                                                    y2="244.6885">
                                                        <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                        <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                    </linearGradient>
                                                    <path className="st23" d="M375,247.8v71.6c-6.3-1.8-13.2-3.4-20.8-4.8c-12.8-2.3-27.4-3.8-43.4-4.4c-31.2-1.1-60.7,2-82.7,7.8v-19.8
			c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-10-2.8-18.4-6.1-24.8-9.8c-7.7-4.5-12.3-9.7-12.8-15.3c-0.1-1.1,0-2.2,0.2-3.3
			c4.1-19.3,59-33.3,120.5-31.1C336.2,239.6,358.2,242.9,375,247.8z"/>
                                                    <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse"
                                                                    x1="150.8577" y1="264.2412" x2="410.3057"
                                                                    y2="264.2412">
                                                        <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                        <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                    </linearGradient>
                                                    <path className="st24" d="M410.3,235.7L375,247.8c-16.8-4.9-38.8-8.3-64.2-9.1c-61.5-2.2-116.4,11.9-120.5,31.1
			c-0.2,1.1-0.3,2.2-0.2,3.3c0.5,5.6,5.1,10.8,12.8,15.3c6.4,3.8,14.8,7.1,24.8,9.8c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0l-31.8,11
			c0,0-0.1,0-0.1,0c-1.1-0.4-2.3-0.7-3.4-1.1c-0.8-0.3-1.6-0.5-2.3-0.8c-14.3-5.1-25.4-11.2-32.2-18.2c-3.5-3.6-5.8-7.3-6.8-11.3
			l-0.6-4.5c-0.4-27.8,63.3-51.9,143.4-54C340.8,218.1,382.7,224.6,410.3,235.7z"/>
                                                    <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse"
                                                                    x1="196.19" y1="320.8735" x2="196.2712"
                                                                    y2="320.8735">
                                                        <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                        <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                    </linearGradient>
                                                    <path className="st25"
                                                          d="M196.3,309.3l0,23.2c0,0,0,0,0,0L196.3,309.3C196.2,309.3,196.2,309.3,196.3,309.3z"/>
                                                    <linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse"
                                                                    x1="196.1678" y1="339.7398" x2="228.1113"
                                                                    y2="339.7398">
                                                        <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                        <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                    </linearGradient>
                                                    <polygon className="st26" points="228.1,298.3 228.1,370 196.2,381.1 196.2,380.9 196.2,366.6 196.2,365.8 196.2,355.2 196.2,332.5
			196.3,309.3 		"/>
                                                    <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse"
                                                                    x1="374.9951" y1="277.5623" x2="410.3057"
                                                                    y2="277.5623">
                                                        <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                        <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                    </linearGradient>
                                                    <polygon className="st27"
                                                             points="410.3,235.7 410.3,307.3 375,319.4 375,247.8 		"/>
                                                </g>
                                                <g>
                                                    <path className="st28"
                                                          d="M165.5,373.9c-0.3-1.2-0.5-2.4-0.6-3.6c0-0.3,0-0.6,0-0.9L165.5,373.9z"/>
                                                </g>
                                                <path className="st29"
                                                      d="M131.6,265.1v2.8C131.5,267,131.5,266.1,131.6,265.1z"/>
                                                <path className="st29"
                                                      d="M139.8,247.8l-0.2-0.2c0,0,0,0,0,0C139.6,247.6,139.7,247.7,139.8,247.8z"/>
                                                <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse"
                                                                x1="319.2986"
                                                                y1="273.0316" x2="319.2986" y2="154.1216">
                                                    <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                    <stop offset="0.9308" stopColor="#FFFFFF"/>
                                                </linearGradient>
                                                <polygon className="st30" points="457.7,181.8 457.7,219.5 393.5,206.4 294.9,203.1 270,203.9 202.2,219.5 180.9,225.9 182.8,220.5
		186.2,214.8 195.7,206.4 217.6,197.3 258.4,188 301.8,183.6 345.5,183.5 391.8,190.6 414,196.5 	"/>
                                                <g>
                                                    <g>
                                                        <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse"
                                                                        x1="233.8945" y1="121.2655" x2="394.1489"
                                                                        y2="394.473">
                                                            <stop offset="6.724014e-02" stopColor="#D8DCFF"/>
                                                            <stop offset="0.7613" stopColor="#8F9DE5"/>
                                                        </linearGradient>
                                                        <path className="st31" d="M457.7,219.5l-44.4,15.3c-0.2-0.1-0.4-0.1-0.6-0.2l-47.1,15.7c-9.9-3.6-22.2-6.4-36.1-7.9
				c-43.9-4.5-81.9,6.5-84.3,22.9c-1,7.3,7.6,14.9,18.1,20.6l-35.1,12.3c0.1,0,0.2,0.1,0.3,0.1l-40,13.8v-13.7
				c-12.8-10.3-20.1-22.4-18.7-33.8c-12.5-5.4-22.4-11.6-29-18.4c8.5-9,22.3-17.3,40.3-24.3c32.7-12.8,78.9-21.5,130.6-22.8
				C370.3,197.4,423,205.5,457.7,219.5z"/>
                                                    </g>
                                                </g>
                                                <path className="st29"
                                                      d="M131.6,227.5v2.8C131.5,229.4,131.5,228.4,131.6,227.5z"/>
                                                <path className="st32" d="M457.7,181.8l-44.4,15.3c-21.2-6.1-48.7-10.4-80.6-11.5c-78.9-2.8-149,15.6-151.7,40.6
		c-0.1,0.9-0.1,1.8,0,2.7c1.1,12.2,17.8,22.8,43,30.4l-35.3,12.2c-2.5-0.8-4.9-1.6-7.3-2.4c-18-6.4-31.9-14.1-40.4-22.9
		c-1.8-1.9-3.4-3.7-4.7-5.7c0,0,0,0,0,0c-2.8-4.1-4.4-8.5-4.7-12.9v-0.1c2.3-34.2,81.3-63.5,180.2-66.1c56.3-1.5,107.2,6,141.9,19
		C455,180.8,456.4,181.3,457.7,181.8z"/>
                                                <path className="st32" d="M228.7,260.6l-40,13.8c-2-0.6-4-1.3-5.9-2c-9.2-3.2-17.3-6.7-24.3-10.5c-2.9-1.6-5.5-3.2-7.9-4.9
		c-4.3-2.9-7.9-6-10.7-9.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.5-0.8-1-1.2-1.5c-4.1-5-6.4-10.3-6.7-15.8v-2.8c0,0,0,0,0-0.1
		c0.3,4.5,1.9,8.8,4.7,12.9c0,0,0,0,0,0c1.3,1.9,2.9,3.8,4.7,5.7c8.5,8.8,22.4,16.5,40.4,22.9c2.4,0.8,4.8,1.6,7.3,2.4l35.3-12.2
		C225.5,259.7,227.1,260.1,228.7,260.6z"/>
                                                <path className="st29"
                                                      d="M136.3,240.4c-2.8-4.1-4.4-8.5-4.7-12.9c0,0,0,0.1,0,0.1v-0.2l0,0L136.3,240.4z"/>
                                                <path className="st33" d="M188.6,274.3V312c-34.2-10.7-55.9-26.3-57-44v-37.7c0.3,5.5,2.7,10.8,6.7,15.8c0.4,0.5,0.8,1,1.2,1.5
		c0,0,0,0,0,0l0.2,0.2c2.9,3.2,6.5,6.3,10.7,9.2c2.4,1.7,5.1,3.3,7.9,4.9c6.9,3.8,15.1,7.4,24.3,10.5
		C184.6,273.1,186.6,273.7,188.6,274.3z"/>
                                                <g>
                                                    <path className="st34" d="M457.7,178.9l-4,1.4l-40.4,13.9c-21.2-6.1-48.7-10.4-80.6-11.5c-77.3-2.7-146.3,14.9-151.5,39.1
			c-0.3,1.4-0.4,2.7-0.3,4.1c0,0.1,0,0.2,0,0.3c0.7,6.9,6.5,13.4,16.1,19c8.1,4.8,18.9,9,31.6,12.5l-4.7,1.6l-35.3,12.2
			c-2.5-0.8-4.9-1.6-7.3-2.4c-18-6.4-31.9-14.1-40.4-22.9c-1.8-1.9-3.4-3.7-4.7-5.7c0,0,0,0,0,0c-2.8-4.1-4.4-8.5-4.7-12.9
			c0,0,0-0.1,0-0.1c-2.2-35.4,78.4-66.3,180.2-69C370.3,156.8,423,164.9,457.7,178.9z"/>
                                                </g>
                                                <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse"
                                                                x1="-28.3739"
                                                                y1="135.3014" x2="37.9165" y2="135.3014">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st35" d="M31.2,142.2c-2.2,1.3-4.5,2.7-6.9,4.2c-2.1,1.4-4.2,2.8-6.1,4.2c-1.5-1.8-3.6-4.7-5.9-8.4
		C3.6,128,2.7,116.7-3.8,115.5c-2.3-0.4-4.7,0.5-7.9-1.2c-2.6-1.4-4.1-3-4.1-3c-1.5-1.6-2.4-3.2-3.3-3c-0.5,0.1-0.7,0.7-0.7,0.8
		c-0.4,1,0.4,1.8,0.1,2.4c-0.3,0.5-1.5,0.5-2.4,0.1c-2.5-1-2.5-4.4-4.1-4.5c-0.8,0-1.4,0.7-1.5,0.9c0,0-0.7,0.8-0.7,2.4
		c0.1,2.8,6.3,12.1,15.5,12.6c3.1,0.2,5.9-0.7,7.9,1.2c1.4,1.3,1.6,3.1,1.7,3.8c0,0,1.3,8.8,6.5,20.9c0.8,2,5.4,12.6,14,14.3
		c7.1,1.4,15.2-3.6,20.7-11.9C35.7,148.3,33.4,145.2,31.2,142.2z"/>
                                                <path className="st36" d="M81,120.6c-4.4,4.4-8.5,6.9-11.6,8.4c-1.3,0.6-2.4,1-3.4,1.4c-3.2,1.1-5,1.2-6.3,0.3c-1.5-1-2.2-3.2-2.1-5.8
		c0.1-2.8,1.1-6,3-8.4c0.5-0.6,1.1-1.2,1.7-1.7c0.8-0.4,1.5-0.8,2.1-1.1c-0.8,0.8-2.2,2.5-2.9,5.1c-1,3.8,0.4,7,0.8,7.9
		c0.8-0.2,1.4-1.1,2.1-1.4c0.7-0.3,6.6-1.5,6.6-1.5c0,0,2.6-0.5,2.8-0.5C75.2,123,78.4,122,81,120.6z"/>
                                                <path className="st36" d="M83.5,66"/>
                                                <path className="st37"
                                                      d="M64.2,91.2c-1.4-0.1-4,2.6-2.9,4.8c0.9,1.8,3.7,2.3,4.8,1.4C67.6,96.1,66,91.4,64.2,91.2z"/>
                                                <path className="st37"
                                                      d="M93.1,87.5c0.8-0.1,2,1.9,1.7,3.8c-0.3,2.1-2.3,3.6-3,3.3C90.7,94.1,91.8,87.7,93.1,87.5z"/>
                                                <g>
                                                    <radialGradient id="SVGID_27_" cx="73.1122" cy="116.7174"
                                                                    r="10.9705"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.5792" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </radialGradient>
                                                    <path className="st38" d="M84.6,106.8l0.4,9.4c-0.1,1.8-1.8,3.3-4,4.4c-2.6,1.4-5.8,2.4-7.1,2.6c-0.2,0-2.8,0.5-2.8,0.5
			c0,0-6,1.2-6.6,1.5c-0.8,0.4-1.3,1.2-2.1,1.4c-0.4-0.9-1.8-4-0.8-7.9c0.7-2.6,2.1-4.3,2.9-5.1c-0.6,0.3-1.3,0.7-2.1,1.1
			c3.6-2.8,8.2-2.8,9.1-2.8l-0.2-4.7L84.6,106.8z"/>
                                                </g>
                                                <radialGradient id="SVGID_28_" cx="74.7038" cy="93.7905" r="21.8119"
                                                                fx="55.0781" fy="84.2726"
                                                                gradientUnits="userSpaceOnUse">
                                                    <stop offset="0.5792" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </radialGradient>
                                                <path className="st39" d="M83.5,66.7c10.4,4.4,12,18.9,9.1,27.9c-2,6.4-7.8,14.8-15,14.8c-4.2,0-7.9-2.8-8.8-3.5
		c-0.5-0.4-3.3-2.7-5-6.8c-1.3-3.2-0.6-4.3-2.1-11.7c-0.7-3.5-0.9-3.3-1-4.7c0-0.4-0.5-6.6,2.6-11.1C67.4,65.7,76.8,63.9,83.5,66.7z
		"/>
                                                <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse"
                                                                x1="60.7165"
                                                                y1="66.6516" x2="92.8022" y2="85.0634">
                                                    <stop offset="0.2109" stopColor="#774774"/>
                                                    <stop offset="0.7317" stopColor="#511E48"/>
                                                </linearGradient>
                                                <path className="st40" d="M61.7,86.6c-0.5-3-0.9-8,1.9-10.3c1.6-1.3,2.9-0.8,7.8-1.1c8.9-0.7,10.2-2.9,13.3-1.4
		c3.6,1.7,5.1,6.2,5.9,8.4c0.8,2.2,1,4.1,1,5.5c1.1,0.2,2.1,0.4,3.2,0.6c-0.3-3-0.8-6.7-1.3-11.7c-0.5-4.2-0.8-6.8-2.7-9.2
		c-0.3-0.4-0.4-0.5-3.4-3.1c-6-5.1-6.3-5.5-7.4-5.8c-2.1-0.6-4,0-7.7,1c-2,0.5-4.2,1.2-6.6,2.7c-0.6,0.4-4.4,2.8-6.4,6.6
		C56.6,73.8,57.7,80.7,61.7,86.6z"/>
                                                <path className="st41" d="M67.7,80.8c-0.2,0.5-0.8,0.3-1.5,0.9c-0.8,0.6-0.7,1.3-1.5,1.6c-0.1,0-0.6,0.2-0.8,0
		c-0.3-0.3,0.5-1.6,1.5-2.4c0.8-0.6,1.9-1.2,2.3-0.9C67.8,80.2,67.8,80.6,67.7,80.8z"/>
                                                <path className="st41" d="M83.8,81.4c-0.6,0-0.7-0.6-1.5-1c-0.9-0.4-1.5,0-2.1-0.6c-0.1,0-0.5-0.4-0.4-0.7c0.2-0.4,1.6-0.3,2.8,0.2
		c0.9,0.4,2,1.2,1.9,1.7C84.5,81.3,84.1,81.4,83.8,81.4z"/>

                                                <ellipse
                                                    transform="matrix(0.999 -4.571868e-02 4.571868e-02 0.999 -3.972 3.0948)"
                                                    className="st41" cx="65.7" cy="88.4" rx="1.4" ry="1.4"/>

                                                <ellipse
                                                    transform="matrix(0.999 -4.571868e-02 4.571868e-02 0.999 -3.8487 3.8417)"
                                                    className="st41" cx="82.1" cy="86.1" rx="1.4" ry="1.4"/>
                                                <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse"
                                                                x1="72.8518"
                                                                y1="86.5556" x2="73.5923" y2="93.5313">
                                                    <stop offset="0" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st42" d="M73.5,86.8c0.7,0.4,0.6,1.4,1,3.9c0.3,1.7,0.5,2.3,0.1,2.7c-0.4,0.4-1.1,0.3-1.6,0.1
		c-0.7-0.4-0.8-1.2-0.9-2.6c-0.1-1.3-0.4-3.6,0.6-4C73,86.6,73.4,86.8,73.5,86.8z"/>
                                                <g>
                                                    <g>
                                                        <g className="st43">
                                                            <path
                                                                d="M71.5,96.6c0.2-0.1,1.5,2.4,3.2,2.2c1.9-0.2,2.8-3.4,3-3.3c0.2,0.1-0.5,4.7-2.9,4.8C72.7,100.4,71.3,96.7,71.5,96.6z"/>
                                                        </g>
                                                        <g>
                                                            <linearGradient id="SVGID_31_"
                                                                            gradientUnits="userSpaceOnUse"
                                                                            x1="74.68" y1="96.0402" x2="75.5531"
                                                                            y2="100.2447">
                                                                <stop offset="0.4326" stopColor="#E55A81"/>
                                                                <stop offset="1" stopColor="#C9415B"/>
                                                            </linearGradient>
                                                            <path className="st44" d="M71.5,96.4c0.2-0.1,1.5,2.4,3.2,2.2c1.9-0.2,2.8-3.4,3-3.3c0.2,0.1-0.5,4.7-2.9,4.8
					C72.7,100.2,71.3,96.5,71.5,96.4z"/>
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <filter id="Adobe_OpacityMaskFilter"
                                                                filterUnits="userSpaceOnUse"
                                                                x="71.5" y="95.3" width="6.3" height="5">

                                                            <feColorMatrix type="matrix"
                                                                           values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0"
                                                                           colorInterpolationFilters="sRGB"
                                                                           result="source"/>
                                                        </filter>
                                                    </defs>
                                                    <g className="st46">
                                                        <g className="st43">
                                                            <path className="st47" d="M71.5,96.6c0.2-0.1,1.5,2.4,3.2,2.2c1.9-0.2,2.8-3.4,3-3.3c0.2,0.1-0.5,4.7-2.9,4.8
					C72.7,100.4,71.3,96.7,71.5,96.6z"/>
                                                        </g>
                                                        <g>
                                                            <path className="st47" d="M71.5,96.4c0.2-0.1,1.5,2.4,3.2,2.2c1.9-0.2,2.8-3.4,3-3.3c0.2,0.1-0.5,4.7-2.9,4.8
					C72.7,100.2,71.3,96.5,71.5,96.4z"/>
                                                        </g>
                                                    </g>
                                                </g>
                                                <polygon className="st32"
                                                         points="457.7,178.9 457.7,181.8 413.9,196.9 413.3,194.1 	"/>
                                                <polygon className="st32"
                                                         points="228.7,257.6 228.7,260.6 188.6,274.3 188.6,271.4 	"/>
                                                <path className="st48" d="M253.9,199l-45.4,10c-2.3,0.5-3.3,3.3-1.9,5.2l17.2,21.8c0.8,1,2,1.4,3.2,1.2l45.4-10
		c2.3-0.5,3.3-3.3,1.9-5.2l-17.2-21.8C256.3,199.1,255.1,198.7,253.9,199z"/>
                                                <path className="st48" d="M276.1,226.7l-47.6,10.4c-1.3,0.3-1.4,2.1-0.2,2.5l16.2,5.9c0.2,0.1,0.5,0.1,0.7,0l47.6-10.4
		c1.3-0.3,1.4-2.1,0.2-2.5l-16.2-5.9C276.6,226.6,276.4,226.6,276.1,226.7z"/>
                                                <path className="st49" d="M252.9,202l-38.4,8.4c-2,0.4-2.8,2.8-1.6,4.4l14.5,18.5c0.7,0.8,1.7,1.2,2.8,1l38.4-8.4
		c2-0.4,2.8-2.8,1.6-4.4L255.6,203C255,202.2,253.9,201.8,252.9,202z"/>
                                                <path className="st50" d="M252.6,206.3l-11.2,2.5c-0.6,0.1-0.8,0.8-0.5,1.3l4.2,5.4c0.2,0.2,0.5,0.4,0.8,0.3l11.2-2.5
		c0.6-0.1,0.8-0.8,0.5-1.3l-4.2-5.4C253.2,206.3,252.9,206.2,252.6,206.3z"/>
                                                <polygon className="st51"
                                                         points="233.9,211.3 220.9,214.2 221.6,215.2 234.6,212.4 	"/>
                                                <polygon className="st50"
                                                         points="235.4,213.6 222.4,216.4 223.1,217.4 236.1,214.6 	"/>
                                                <polygon className="st50"
                                                         points="236.8,215.8 223.9,218.6 224.5,219.6 237.5,216.8 	"/>
                                                <polygon className="st50"
                                                         points="238.3,218 225.3,220.8 226,221.9 239,219 	"/>
                                                <polygon className="st50"
                                                         points="239.8,220.2 226.8,223.1 227.5,224.1 240.5,221.2 	"/>
                                                <polygon className="st50"
                                                         points="241.3,222.4 228.3,225.3 229,226.3 242,223.4 	"/>
                                                <polygon className="st50"
                                                         points="259.7,214.3 246.7,217.1 247.4,218.2 260.4,215.3 	"/>
                                                <polygon className="st52"
                                                         points="261.2,216.5 248.2,219.4 248.9,220.4 261.9,217.5 	"/>

                                                <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse"
                                                                x1="333.6566"
                                                                y1="274.5763" x2="344.9474" y2="274.5763"
                                                                gradientTransform="matrix(0.9686 0.2485 -0.2485 0.9686 71.6377 9.9408)">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st53" d="M326.8,358.1c1.5,2.7,3,5.4,4.5,8.1c2-1,3.9-2,5.9-3.1c-1.9-3.1-3.8-6.2-5.7-9.3
		C329.9,355.3,328.3,356.7,326.8,358.1z"/>

                                                <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse"
                                                                x1="203.0017"
                                                                y1="111.7986" x2="214.2925" y2="111.7986"
                                                                gradientTransform="matrix(0.6881 0.7256 -0.7256 0.6881 203.1441 142.9642)">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st54" d="M262.2,366.7c-0.2,3.1-0.3,6.2-0.5,9.2c2.2,0.2,4.4,0.4,6.6,0.5c0-3.6,0.1-7.2,0.1-10.9
		C266.4,366,264.3,366.3,262.2,366.7z"/>
                                                <g>
                                                    <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse"
                                                                    x1="295.0735" y1="328.1834" x2="333.5114"
                                                                    y2="328.1834">
                                                        <stop offset="0.2109" stopColor="#555589"/>
                                                        <stop offset="0.7317" stopColor="#505099"/>
                                                    </linearGradient>
                                                    <path className="st55" d="M297.3,299.2c-1.1,1.4-4,8.2-0.8,18.4c0.3,0.9,0.5,1.5,0.6,1.9c6.2,12.6,13.9,24.5,23.6,34.6
			c0.4,0.4,2.3,1.3,4.1,3.4c1,1.2,1.6,2.3,2,3c2.2-1.5,4.4-3,6.6-4.4c-10-20.7-15.8-32.1-17-33.7c-0.2-0.2-0.9-1.2-1.2-2.6
			c-0.2-0.9-0.1-1.9,0.1-2.9c1-3.9,1.5-5.5,3.9-8.7c2.4-3.2,4.5-7.1,3.8-11c-0.1-0.3-0.2-0.7-0.4-1c-0.4-0.4-1-0.4-1.2-0.4
			c-11.7,0.5-14.6,1.1-23.9,3C297.6,298.9,297.5,299,297.3,299.2z"/>
                                                </g>
                                                <g>
                                                    <linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse"
                                                                    x1="257.6041" y1="318.1736" x2="339.2252"
                                                                    y2="318.1736">
                                                        <stop offset="0.2109" stopColor="#555589"/>
                                                        <stop offset="0.7317" stopColor="#505099"/>
                                                    </linearGradient>
                                                    <path className="st56" d="M337.9,274.1c1,2.1,1.2,4.7,1.2,4.7c0.3,4.1-1.1,7.3-1.7,8.5c-2.9,6.1-10.1,11.3-18,13.4
			c-6.2,1.6-12.4,1.2-19.3,0.7c-3.9-0.3-7.8,0.3-11.6,0.2c-2.3-0.1-4.7-0.1-6.6,1.3c-1.3,1-2.1,2.5-2.7,4.1c-1.4,4.1-1.1,8.6-0.9,13
			c0.3,9.3-0.5,18.7-2.3,27.8c-0.7,3.5-3.5,9.7-5.6,14.9c-0.8,1.9-1.5,3.5-1.9,4.6c-2.5,0.2-4.9,0.5-7.4,0.7c-0.6-3-1.1-5.5-1.3-7.1
			c-6.1-37.4,2-59.9,4.6-74c0.3-1.6,0.6-3.3,1.5-4.6c1.4-2.3,3.6-3.4,5-4c18-8.1,35.4-9.2,35.4-9.2
			C314.8,268.4,334.7,267.2,337.9,274.1z"/>
                                                </g>
                                                <g>

                                                    <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse"
                                                                    x1="-647.8177" y1="-84.4513" x2="-673.0857"
                                                                    y2="-123.9273"
                                                                    gradientTransform="matrix(-0.8418 -0.5398 -0.5398 0.8418 -320.8946 -39.7881)">
                                                        <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </linearGradient>
                                                    <path className="st57" d="M243.4,237.9c0.3-0.6,0.9-0.9,3-1.5c6.6-1.9,6.2-2.2,7.5-2.1c3.4,0,4,1.7,8.7,2.1c0.5,0.1,1,0.1,1.5,0.1
			c1.5,0,2.6-0.1,3-0.1c2-0.2,3.9-0.3,5.9-0.5c5.1-0.5,10.2-0.7,13.1-0.9c3-0.1,3.1-0.1,3.7-0.2c2.1-0.5,3.7-1.5,8.4-5.8
			c1.3-1.1,2.9-2.6,4.8-4.5c0,0.4,0.1,0.9,0.2,1.5c0.4,1.6,1.2,2.6,1.7,3.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c0,0.1,0,0.1-0.1,0.2c-0.2,0.3-0.6,0.5-0.6,0.5c-0.6,0.3-7.3,10-9.8,10.8c-0.9,0.3-1.9,0.5-1.9,0.5c-0.2,0-0.5,0.1-0.9,0.1
			c0,0,0,0,0,0c-1.1,0.1-2,0.1-2.2,0c-4.2-0.5-8.5-0.6-12.8-1c-5.2-0.5-10.3-0.9-14.8,0c-1.5,0.3-3.6,0.9-6.4,0.4
			c-1.4-0.3-1.8-0.6-3.1-0.6c-1.8,0-2.4,0.7-4.5,0.6c-0.8-0.1-1.3-0.2-2-0.4c-1.3-0.3-2-0.5-2.4-1.1
			C243.2,239.2,243.2,238.5,243.4,237.9z"/>
                                                </g>
                                                <g>

                                                    <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse"
                                                                    x1="-674.3434" y1="-118.6629" x2="-699.6115"
                                                                    y2="-158.1389"
                                                                    gradientTransform="matrix(-0.8418 -0.5398 -0.5398 0.8418 -320.8946 -39.7881)">
                                                        <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </linearGradient>
                                                    <path className="st58" d="M284.2,223.4c0.3-0.6,0.9-0.9,3-1.5c6.6-1.9,6.2-2.2,7.5-2.1c3.4,0,4,1.7,8.7,2.1c0.5,0.1,1,0.1,1.5,0.1
			c1.5,0,2.6-0.1,3-0.1c2-0.2,3.9-0.3,5.9-0.5c5.1-0.5,10.2-0.7,13.1-0.9c3-0.1,3.1-0.1,3.7-0.2c2.1-0.5,3.7-1.5,8.4-5.8
			c1.3-1.1,2.9-2.6,4.8-4.5c0,0.4,0.1,0.9,0.2,1.5c0.4,1.6,1.2,2.6,1.7,3.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c0,0.1,0,0.1-0.1,0.2c-0.2,0.3-0.6,0.5-0.6,0.5c-0.6,0.3-7.3,10-9.8,10.8c-0.9,0.3-1.9,0.5-1.9,0.5c-0.2,0-0.5,0.1-0.9,0.1
			c0,0,0,0,0,0c-1.1,0.1-2,0.1-2.2,0c-4.2-0.5-8.5-0.6-12.8-1c-5.2-0.5-10.3-0.9-14.8,0c-1.5,0.3-3.6,0.9-6.4,0.4
			c-1.4-0.3-1.8-0.6-3.1-0.6c-1.8,0-2.4,0.7-4.5,0.6c-0.8-0.1-1.3-0.2-2-0.4c-1.3-0.3-2-0.5-2.4-1.1C284,224.7,284,224,284.2,223.4z
			"/>
                                                </g>

                                                <radialGradient id="SVGID_39_" cx="508.8223" cy="178.0842" r="6.2873"
                                                                gradientTransform="matrix(0.6667 0 0 1 -0.5346 0)"
                                                                gradientUnits="userSpaceOnUse">
                                                    <stop offset="0.5792" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </radialGradient>
                                                <path className="st59" d="M334.2,184c0-3.2,0-6.4,0-9.6c3-0.7,5.9-1.5,8.9-2.2c-0.2,3.9-0.3,7.9-0.5,11.8C339.8,184,337,184,334.2,184z
		"/>
                                                <g>
                                                    <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse"
                                                                    x1="341.4403" y1="184.3765" x2="339.7527"
                                                                    y2="269.5965">
                                                        <stop offset="0.2109" stopColor="#754659"/>
                                                        <stop offset="0.7317" stopColor="#4F1E3C"/>
                                                    </linearGradient>
                                                    <path className="st60" d="M321.7,262.3c-1.1,1.2-4.5,4.2-3.7,5.9c0.6,1.2,2.9,0.9,5.6,1.3c6.5,0.8,8.5,4.6,12.6,5.7
			c8,2.2,20.6-3.8,24.7-12.9c2.7-6-0.5-8.4-1.5-21.4c-1.3-17.7,4-20.9,1.5-37.7c-2-12.7-6-17.1-8.9-19.2c-3.4-2.5-7.1-3.4-9.6-3.7
			c-4.8-0.6-8.7,0.6-10.7,1.5c-1.2,0.5-3,1.4-4.8,3c-7.8,6.9-6,32.7-5.9,34C322.9,241.8,328.5,255.1,321.7,262.3z"/>
                                                </g>
                                                <g>

                                                    <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse"
                                                                    x1="248.9685" y1="286.4511" x2="305.1674"
                                                                    y2="286.4511"
                                                                    gradientTransform="matrix(0.9757 -0.2191 0.2191 0.9757 -20.0169 -6.2406)">
                                                        <stop offset="0.2553" stopColor="#FFFFFF"/>
                                                        <stop offset="0.9308" stopColor="#DEDCE2"/>
                                                    </linearGradient>
                                                    <path className="st61" d="M324.5,187.9c-2.4,4.8-2.8,9.1-2.8,9.1c-0.6,6.4-5,11.8-12.1,20.4c-6.3,7.7-13.1,13-18.3,16.4
			c-0.3,0.5-1.4,2.4-1.1,4.9c0.3,2.2,1.4,3.6,1.9,4.1c4.1-1.9,9.7-4.5,16.1-8.2c2.1-1.2,5.4-3.1,9.2-5.6c10.6-7,16-10.5,18.6-15.4
			c5.2-9.6,2.4-21.8-2.4-27.1c-0.8-0.9-3.1-3.4-5.4-2.9C327.3,183.8,326.2,184.5,324.5,187.9z"/>
                                                </g>
                                                <g>
                                                    <radialGradient id="SVGID_42_" cx="335.166" cy="162.8168"
                                                                    r="17.4657"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop offset="0.5792" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </radialGradient>
                                                    <path className="st62" d="M326.1,147c1.3,1.3-0.9,4.5-2.3,10.4c-0.9,3.8-1.4,6.9-0.6,10.5c0.8,3.5,3.1,6.8,6.5,7.8
			c2.4,0.8,5.1,0.3,7.5-0.4c4.8-1.4,9.3-3.9,12.6-7.7c3.3-3.8,5.1-8.9,4.4-13.8c-1.1-6.9-7-12.3-13.6-14.5c-1.7-0.6-3.5-1-5.3-0.8
			c-1.4,0.2-2.5,0.6-3.9,1.3c-4,2-9.3,5.6-8.8,6.7C322.8,147.1,325,145.9,326.1,147z"/>
                                                </g>
                                                <linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse"
                                                                x1="324.8385"
                                                                y1="158.2878" x2="322.5229" y2="163.9715">
                                                    <stop offset="0" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st63" d="M324.5,157.1c0.3,0.1,0.3,0.6,0.2,4c-0.1,1.7,0,2.4-0.4,2.7c-0.4,0.3-1.1,0.1-1.5-0.2c-0.8-0.7-0.5-2-0.4-2.8
		C322.7,158.8,323.9,156.9,324.5,157.1z"/>
                                                <path className="st64" d="M322.4,167c0.2-0.1,0.8,1.6,1.9,1.7c1.6,0.1,2.8-3.4,3-3.3c0.2,0.1-1.2,5-2.9,4.8
		C323.1,170.2,322.2,167.1,322.4,167z"/>
                                                <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse"
                                                                x1="327.2063"
                                                                y1="151.8257" x2="331.9066" y2="151.8257">
                                                    <stop offset="0.2109" stopColor="#464675"/>
                                                    <stop offset="0.7317" stopColor="#2A2A68"/>
                                                </linearGradient>
                                                <path className="st65" d="M331.2,153.1c-0.6,0-0.7-0.6-1.5-1c-0.9-0.4-1.5,0-2.1-0.6c-0.1,0-0.5-0.4-0.4-0.7c0.2-0.4,1.6-0.3,2.8,0.2
		c0.9,0.4,2,1.2,1.9,1.7C331.8,152.9,331.5,153.1,331.2,153.1z"/>
                                                <g>
                                                    <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse"
                                                                    x1="338.921" y1="140.0053" x2="356.2426"
                                                                    y2="187.0749">
                                                        <stop offset="0.2109" stopColor="#464675"/>
                                                        <stop offset="0.7317" stopColor="#2A2A68"/>
                                                    </linearGradient>
                                                    <path className="st66" d="M338.5,160.5c-2.6,0.6-3.9,3.6-4,3.9c-0.7,1.7-0.5,3.1-0.1,5.9c0.3,1.9,0.6,3.8,1.7,5.4
			c0.9,1.2,2.2,2,3.5,2.7c0,0,0.8,0.4,1.6,0.6c2.9,0.9,25.2,2,28.3-7.2c0.8-2.5-0.5-3.8-3.1-10.8c-6.1-16.3-3-19.8-7.6-24.3
			c-7.9-7.6-22.9-3.1-25.9-2c-4.8,1.6-9.6,4.1-12.1,8.5c-0.7,1.2-1.2,2.5-1.1,3.9c0.1,1.4,0.9,2.7,2.3,3.1c2.1,0.6,4-1.2,6.1-1.8
			c2.2-0.6,4.6,0.4,6.3,1.9c1.7,1.5,2.8,3.5,3.9,5.5c0.8-1.1,1.7-2.3,2.8-3.1c1.2-0.8,2.7-1.1,4-0.5c1.7,0.8,1.9,3.5,1.3,5.2
			c-1,2.8-4.6,3.4-4.7,3.4C340,160.8,339.8,160.3,338.5,160.5z"/>
                                                </g>
                                                <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse"
                                                                x1="68.9093"
                                                                y1="175.429" x2="46.7308" y2="228.0924">
                                                    <stop offset="0.2553" stopColor="#776EE4"/>
                                                    <stop offset="0.5235" stopColor="#6866C6"/>
                                                </linearGradient>
                                                <path className="st67" d="M89.8,187.4c1.3,5-1.1,9.8-5.4,18.3c-4.7,9.3-8.1,16.1-14.9,18c-9.2,2.6-18.8-5.5-24.8-10.6
		c-5.8-4.9-9.4-10-11.6-13.6c0.7-2.5,1.9-6.7,3.4-11.9c2.8-9.9,2.9-10.4,3.4-11.2C46.9,166.1,85.7,171.5,89.8,187.4z"/>
                                                <path className="st68" d="M164.9,204.9c0,0,1.9,2.4,5,3.5c4.1,1.3,9.8,1.3,9.8,1.3l-9.2,7.6c0,0-3-0.3-6.9-2.6
		c-3.1-1.9-7.3-5.9-7.3-5.9L164.9,204.9z"/>
                                                <path className="st68" d="M161.6,205.2c0,0,2.3,2,5.6,2.4c4.3,0.5,7,3.7,7,3.7l-7.5,9.3c0,0-0.6-1.8-4.8-3.3c-3.4-1.2-7.9-6.6-7.9-6.6
		L161.6,205.2z"/>
                                                <path className="st68" d="M159.8,204.1c0,0,1.9,2.4,5,3.5c4.1,1.3,9.8,1.3,9.8,1.3l-9.2,7.6c0,0-3-0.3-6.9-2.6
		c-3.1-1.9-7.3-5.9-7.3-5.9L159.8,204.1z"/>
                                                <path className="st68" d="M324.1,279.8c0,0-3.1,0.3-5.6,2.4c-3.3,2.8-6.3,7.5-6.3,7.5l-1.5-11.8c0,0,1.9-2.4,5.9-4.4
		c3.3-1.6,8.9-3,8.9-3L324.1,279.8z"/>
                                                <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse"
                                                                x1="30.0293"
                                                                y1="332.1068" x2="41.8538" y2="332.1068">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st69" d="M41.9,323.2c-1,6.1-2.1,12.1-3.1,18.2c-2.9-0.7-5.8-1.3-8.7-2c0.5-5.5,1.1-11,1.6-16.6
		C35,322.9,38.4,323.1,41.9,323.2z"/>
                                                <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse"
                                                                x1="66.5431"
                                                                y1="314.5818" x2="85.5714" y2="314.5818">
                                                    <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                    <stop offset="1" stopColor="#E2A48F"/>
                                                </linearGradient>
                                                <path className="st70" d="M66.5,313.4c3.6,3.8,7.2,7.5,10.7,11.3c2.8-2.5,5.5-5,8.3-7.4c-4.4-4.3-8.8-8.5-13.2-12.8
		C70.5,307.5,68.5,310.4,66.5,313.4z"/>

                                                <ellipse
                                                    transform="matrix(0.999 -4.571868e-02 4.571868e-02 0.999 -6.7987 15.1568)"
                                                    className="st41" cx="328" cy="156.2" rx="1.4" ry="1.4"/>
                                                <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse"
                                                                x1="28.1336"
                                                                y1="359.1187" x2="28.1336" y2="365.2385">
                                                    <stop offset="0" stopColor="#EDEDF9"/>
                                                    <stop offset="1" stopColor="#CFCFE2"/>
                                                </linearGradient>
                                                <path className="st71" d="M12.8,357.5c-0.2,1.5-0.3,3.8,1,5.5c2,2.5,5.9,2.2,10.3,1.9c4.1-0.3,5.8-1,11.7-2.1c3.3-0.6,6.1-0.9,7.8-1.1
		c-0.1-2.1-0.2-4.2-0.3-6.3c-1,0.3-2,0.6-3.1,0.9C30.2,358.9,20.8,358.8,12.8,357.5z"/>
                                                <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse"
                                                                x1="17.2057"
                                                                y1="342.464" x2="34.1426" y2="342.464">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#5252CC"/>
                                                </linearGradient>
                                                <path className="st72" d="M34,340c-1-4.9-6.4-8.5-8.2-7.5c-1.6,0.9,0.4,4.6-1.1,9.5c-1.3,4.2-4.5,7.1-7.4,9c1,0.5,4.6,2.4,8.7,1.2
		C31.1,350.7,35,345.1,34,340z"/>
                                                <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse"
                                                                x1="12.6747"
                                                                y1="348.7983" x2="43.6985" y2="348.7983">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#615DE2"/>
                                                </linearGradient>
                                                <path className="st73" d="M42.6,338.8c-0.9-0.6-1.5,0-4.8-0.2c-2.7-0.1-3-0.6-3.9-0.2c-1.1,0.5-1.4,1.4-2.9,4.8c-2.2,5-2.5,5.3-2.9,5.6
		c-2,1.4-3.9-0.2-7.1,0.3c-4.8,0.8-9.1,6-8.3,8.2c0.6,1.5,4.6,1.6,12.6,1.8c4,0.1,6,0.1,8.6-0.4c4.1-0.8,7.2-2.4,9.3-3.6
		c0.1-5,0.3-8.3,0.3-10.2C43.8,341.7,43.8,339.7,42.6,338.8z"/>
                                                <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse"
                                                                x1="74.8119"
                                                                y1="340.7072" x2="102.8054" y2="340.7072">
                                                    <stop offset="0" stopColor="#EDEDF9"/>
                                                    <stop offset="1" stopColor="#CFCFE2"/>
                                                </linearGradient>
                                                <path className="st74" d="M74.8,352.8c0.8,1.2,2.3,2.9,4.6,2.9c3.3,0,6.3-3.4,9.7-7.3c3.2-3.6,4-5.6,8.1-11.2c2.3-3.2,4.3-5.7,5.6-7.3
		c-1.5-1.4-3-2.7-4.5-4.1c-0.6,1.1-1.2,2.1-1.9,3.2C90.1,339.2,82.2,347,74.8,352.8z"/>
                                                <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse"
                                                                x1="68.3439"
                                                                y1="333.2086" x2="83.9471" y2="333.2086">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#5252CC"/>
                                                </linearGradient>
                                                <path className="st75" d="M80.2,323c-4.1-2.6-11-0.5-11.8,1.7c-0.7,1.9,3.4,2.9,5.5,7.5c1.8,4,1.1,8.7,0,12.4c1.1-0.4,5.3-2.2,7.9-6.5
		C85.1,332.8,84.5,325.7,80.2,323z"/>
                                                <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse"
                                                                x1="73.2395"
                                                                y1="333.8807" x2="98.2928" y2="333.8807">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#615DE2"/>
                                                </linearGradient>
                                                <path className="st76" d="M86.4,315c-1.1,0.3-1.2,1.2-4.1,3.9c-2.3,2.2-2.8,2.1-3.3,3.1c-0.6,1.2-0.2,2.2,0.9,5.8
		c1.6,5.3,1.6,5.7,1.5,6.3c-0.7,2.7-3.3,3.1-5.5,6.1c-3.4,4.6-3.4,11.8-1.1,12.6c1.5,0.6,4.9-2.7,11.6-9.4c3.3-3.3,5-5,6.8-7.5
		c2.8-4,4.3-7.7,5.1-10.2c-3.3-3.6-5.4-5.9-6.7-7.3C89.4,316,88.1,314.5,86.4,315z"/>
                                                <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse"
                                                                x1="38.883"
                                                                y1="234.3981" x2="76.7201" y2="279.0218">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#836EC3"/>
                                                </linearGradient>
                                                <path className="st77" d="M41.1,185.7c-1.1,1.8-2.7,4.2-4.4,7.3c-7.6,13.4-11.3,24.6-12.1,27.3c-4,12.6-6.8,21.3-5.6,31.7
		c1.8,16.4,12.5,27.9,33.8,51.1c5.6,6.1,10.5,11,14,14.3c3.5-3.8,7-7.7,10.5-11.5c-4-5-9.8-12.3-16.7-21.2
		c-21.7-28-22.4-30.8-22.7-33.5c-2.2-19.3,20.2-29,16.7-47.1C52.8,195.1,45.8,189,41.1,185.7z"/>
                                                <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse"
                                                                x1="54.5213"
                                                                y1="233.2048" x2="54.4911" y2="285.2987">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#A18CDC"/>
                                                </linearGradient>
                                                <path className="st78" d="M80.4,205.1c0.8,6.9-3.1,12.6-4.8,15.1c0,0-5.7,8.6-15.8,32.9c-2.9,7.1-2.5,8.6-7.8,34.9
		c-1.9,9.5-4.5,21.9-7.8,36.5c-1.4,0.5-3.9,1.3-7,1.1c-4.5-0.3-7.6-2.6-8.8-3.5c0.5-2,1.2-5,2-8.7c2-10.2,2.7-17.9,3-20.6
		c1.1-11.7,3.1-24,5.3-37.9c3.4-21.1,9.8-60.7,24.6-63.2c3.3-0.5,9,0.5,12.8,4.1C79.7,199.4,80.3,204,80.4,205.1z"/>
                                                <g>
                                                    <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse"
                                                                    x1="145.6882" y1="189.2643" x2="110.173"
                                                                    y2="133.7794">
                                                        <stop offset="0.1407" stopColor="#FFBCB9"/>
                                                        <stop offset="1" stopColor="#E2A48F"/>
                                                    </linearGradient>
                                                    <path className="st79" d="M163.4,204.9c0.3-0.2,0.8,0,1.5,0.3c1.7,0.8,2.6,1.2,2.9,1.4c1.8,1.3,1.4,2.3,3.2,3.9
			c0.2,0.2,2.2,1.9,2.9,1.4c0.9-0.6-0.2-4.2-1.8-6.8c-2.5-4.3-6.5-6.4-8.3-7.2c-3.6-1.5-11.4-3.4-14-4c-0.6-0.2-1.3-0.3-1.8-0.8
			c-0.7-0.6-0.9-1.5-0.9-1.7c-0.7-2.9-11-17.7-12.7-20.1c-1.1-1.6-4.7-6.5-11.8-16.2c-2.3-3.2-4.3-5.8-5.6-7.6
			c-0.8,1.2-2.1,2.9-4.1,4.5c-1.6,1.2-3.2,2-4.4,2.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3
			c0,0.3,0.2,0.7,0.3,1c2.8,5.5,6.7,10.4,10.5,15.3c0.6,0.8,4.5,5.7,12.1,15.5c9.3,12.1,11.4,15.4,15.7,15.9c2,0.2,4.7-0.1,7.7,1.6
			c1.8,1,3.7,2.1,4.7,4.2c0.3,0.6,0.5,1.2,1.2,1.9c1.3,1.1,3.4,1.5,3.8,0.8c0.5-0.7-1.1-2.1-1.3-5.1
			C163,205.8,163,205.2,163.4,204.9z"/>
                                                </g>
                                                <g>
                                                    <linearGradient id="SVGID_58_" gradientUnits="userSpaceOnUse"
                                                                    x1="41.1329" y1="202.021" x2="89.8534"
                                                                    y2="129.0228">
                                                        <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                        <stop offset="0.9308" stopColor="#B87CD8"/>
                                                    </linearGradient>
                                                    <path className="st80" d="M99.7,136.3c-0.1-1.6-0.2-2.9-0.6-4.4c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
			c-0.1-0.4-0.3-0.8-0.4-1.1c-0.3-0.8-0.8-1.6-1.2-2.4c-1-1.7-2.1-3.3-3.2-4.9c-0.1-0.2-0.3-0.4-0.4-0.6c-0.3-0.4-0.5-0.7-0.8-1.1
			c-2-2.8-4.3-5.5-7.5-6.8c-0.1-0.1-0.3-0.1-0.4-0.1l0.1,2.1c0,0.2,0,0.3,0,0.5c-0.1,1.8-1.8,3.3-4,4.4c-2.6,1.4-5.8,2.4-7.1,2.6
			c-0.2,0-2.8,0.5-2.8,0.5c0,0-6,1.2-6.6,1.5c-0.8,0.4-1.3,1.2-2.1,1.4c-0.4-0.9-1.8-4-0.8-7.9c0.7-2.6,2.1-4.3,2.9-5.1
			c-0.6,0.3-1.3,0.7-2.1,1.1c-0.9,0.5-2,1.1-3.1,1.7c-1.8,1-3.2,1.8-4.3,2.6c-0.8,0.5-1.5,1-2.1,1.4h0c-2.4,1.7-2.9,2.5-3.1,2.9
			c-1.1,2-0.8,2.7-0.9,8.5c0,1.4-0.6,4.9-1.8,11.9c-0.3,1.9-0.6,3.5-0.8,5c-0.8,4.6-1.3,7.3-1.7,8.8c-0.1,0.3-0.2,0.7-0.3,1
			c-0.4,1.6-0.9,3.1-1.4,4.4c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0,0,0,0c-0.3,0.8-0.6,1.5-0.8,2.1c-0.1,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.7-0.6,1.3-0.9,1.8c-1.2,2.4-1.9,3.9-1.1,5.8c1.8,4.5,8.9,4.9,20.2,6.8c0.2,0,0.3,0.1,0.5,0.1
			c0.1,0,0.2,0,0.3,0.1h0c0.9,0.1,1.8,0.3,2.8,0.5c0.3,0.1,0.6,0.1,1,0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.1
			c0.2,0,0.3,0.1,0.5,0.1h0c0.4,0.1,0.8,0.2,1.2,0.2c0.1,0,0.2,0,0.2,0c0.4,0.1,0.7,0.1,1.1,0.2c0.5,0.1,1,0.2,1.5,0.3
			c0.4,0.1,0.9,0.2,1.3,0.3c6.5,1.4,14.2,3.3,22.7,5.9c0.7-4.7,1.3-8.6,1.6-11.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
			c0-0.3,0.1-0.6,0.1-0.9v0c0.1-0.4,0.1-0.8,0.2-1.2c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.6,0.1-1.1,0.2-1.6
			c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.8,0.1-1.1c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.8,0.2-1.5,0.3-2.1v0c0-0.1,0-0.2,0-0.3v0
			c0.2-1.4,0.3-2.6,0.5-3.8c0.2-1.6,0.5-3.2,0.9-5.2c0.9-4.5,1.2-4.5,2-8.7c0.1-0.5,0.2-1,0.3-1.4c1-5.8,1.1-9.7,1.1-11.1
			C99.8,138.9,99.8,137.5,99.7,136.3z M52.1,133.9c0.3-0.8,1.2-1.2,1.9-1.4c-0.4,1.6-0.9,3.2-1.5,4.7c0,0,0,0,0-0.1
			C52,136.1,51.8,134.7,52.1,133.9z M51.7,134.2c-0.2,0.7-0.5,1.9,0,3c0.1,0.2,0.2,0.5,0.5,0.8c-0.1,0.4-0.3,0.7-0.4,1.1
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.3-0.3,0.6-0.4,0.9c0,0,0,0.1,0,0.1
			c-0.1,0.3-0.3,0.6-0.4,0.9c-0.5-0.2-0.9-0.5-1.2-1C48.4,139.3,49.1,136.3,51.7,134.2z"/>
                                                </g>
                                                <g className="st81">
                                                    <g className="st43">
                                                        <path d="M99.7,138.3c-0.1-1.6-0.2-2.9-0.6-4.4c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
				c-0.1-0.4-0.3-0.8-0.4-1.1c-0.3-0.8-0.8-1.6-1.2-2.4c-1-1.7-2.1-3.3-3.2-4.9c-0.1-0.2-0.3-0.4-0.4-0.6c-0.3-0.4-0.5-0.7-0.8-1.1
				c-2-2.8-4.3-5.5-7.5-6.8c-0.1-0.1-0.3-0.1-0.4-0.1l0.1,2.1c0,0.2,0,0.3,0,0.5c-0.1,1.8-1.8,3.3-4,4.4c-2.6,1.4-5.8,2.4-7.1,2.6
				c-0.2,0-2.8,0.5-2.8,0.5c0,0-6,1.2-6.6,1.5c-0.8,0.4-1.3,1.2-2.1,1.4c-0.4-0.9-1.8-4-0.8-7.9c0.7-2.6,2.1-4.3,2.9-5.1
				c-0.6,0.3-1.3,0.7-2.1,1.1c-0.9,0.5-2,1.1-3.1,1.7c-1.8,1-3.2,1.8-4.3,2.6c-0.8,0.5-1.5,1-2.1,1.4h0c-2.4,1.7-2.9,2.5-3.1,2.9
				c-1.1,2-0.8,2.7-0.9,8.5c0,1.4-0.6,4.9-1.8,11.9c-0.3,1.9-0.6,3.5-0.8,5c-0.8,4.6-1.3,7.3-1.7,8.8c-0.1,0.3-0.2,0.7-0.3,1
				c-0.4,1.6-0.9,3.1-1.4,4.4c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0,0,0,0c-0.3,0.8-0.6,1.5-0.8,2.1c-0.1,0.1-0.1,0.3-0.2,0.4
				c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.4
				c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.7-0.6,1.3-0.9,1.8c-1.2,2.4-1.9,3.9-1.1,5.8c1.8,4.5,8.9,4.9,20.2,6.8c0.2,0,0.3,0.1,0.5,0.1
				c0.1,0,0.2,0,0.3,0.1h0c0.9,0.1,1.8,0.3,2.8,0.5c0.3,0.1,0.6,0.1,1,0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.1
				c0.2,0,0.3,0.1,0.5,0.1h0c0.4,0.1,0.8,0.2,1.2,0.2c0.1,0,0.2,0,0.2,0c0.4,0.1,0.7,0.1,1.1,0.2c0.5,0.1,1,0.2,1.5,0.3
				c0.4,0.1,0.9,0.2,1.3,0.3c6.5,1.4,14.2,3.3,22.7,5.9c0.7-4.7,1.3-8.6,1.6-11.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
				c0-0.3,0.1-0.6,0.1-0.9v0c0.1-0.4,0.1-0.8,0.2-1.2c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.6,0.1-1.1,0.2-1.6
				c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.8,0.1-1.1c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.8,0.2-1.5,0.3-2.1v0c0-0.1,0-0.2,0-0.3v0
				c0.2-1.4,0.3-2.6,0.5-3.8c0.2-1.6,0.5-3.2,0.9-5.2c0.9-4.5,1.2-4.5,2-8.7c0.1-0.5,0.2-1,0.3-1.4c1-5.8,1.1-9.7,1.1-11.1
				C99.8,140.9,99.8,139.5,99.7,138.3z M52.1,135.9c0.3-0.8,1.2-1.2,1.9-1.4c-0.4,1.6-0.9,3.2-1.5,4.7c0,0,0,0,0-0.1
				C52,138.1,51.8,136.7,52.1,135.9z M51.7,136.2c-0.2,0.7-0.5,1.9,0,3c0.1,0.2,0.2,0.5,0.5,0.8c-0.1,0.4-0.3,0.7-0.4,1.1
				c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.3-0.3,0.6-0.4,0.9c0,0,0,0.1,0,0.1
				c-0.1,0.3-0.3,0.6-0.4,0.9c-0.5-0.2-0.9-0.5-1.2-1C48.4,141.3,49.1,138.3,51.7,136.2z"/>
                                                    </g>
                                                    <g>
                                                        <linearGradient id="SVGID_59_" gradientUnits="userSpaceOnUse"
                                                                        x1="31.2326" y1="137.9235" x2="115.805"
                                                                        y2="161.3745">
                                                            <stop offset="0.4718" stopColor="#EA7AC8"/>
                                                            <stop offset="0.9308" stopColor="#9B61C1"/>
                                                        </linearGradient>
                                                        <path className="st82" d="M99.7,136.3c-0.1-1.6-0.2-2.9-0.6-4.4c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
				c-0.1-0.4-0.3-0.8-0.4-1.1c-0.3-0.8-0.8-1.6-1.2-2.4c-1-1.7-2.1-3.3-3.2-4.9c-0.1-0.2-0.3-0.4-0.4-0.6c-0.3-0.4-0.5-0.7-0.8-1.1
				c-2-2.8-4.3-5.5-7.5-6.8c-0.1-0.1-0.3-0.1-0.4-0.1l0.1,2.1c0,0.2,0,0.3,0,0.5c-0.1,1.8-1.8,3.3-4,4.4c-2.6,1.4-5.8,2.4-7.1,2.6
				c-0.2,0-2.8,0.5-2.8,0.5c0,0-6,1.2-6.6,1.5c-0.8,0.4-1.3,1.2-2.1,1.4c-0.4-0.9-1.8-4-0.8-7.9c0.7-2.6,2.1-4.3,2.9-5.1
				c-0.6,0.3-1.3,0.7-2.1,1.1c-0.9,0.5-2,1.1-3.1,1.7c-1.8,1-3.2,1.8-4.3,2.6c-0.8,0.5-1.5,1-2.1,1.4h0c-2.4,1.7-2.9,2.5-3.1,2.9
				c-1.1,2-0.8,2.7-0.9,8.5c0,1.4-0.6,4.9-1.8,11.9c-0.3,1.9-0.6,3.5-0.8,5c-0.8,4.6-1.3,7.3-1.7,8.8c-0.1,0.3-0.2,0.7-0.3,1
				c-0.4,1.6-0.9,3.1-1.4,4.4c-0.1,0.2-0.1,0.4-0.2,0.6c0,0,0,0,0,0c-0.3,0.8-0.6,1.5-0.8,2.1c-0.1,0.1-0.1,0.3-0.2,0.4
				c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.2,0.4
				c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.7-0.6,1.3-0.9,1.8c-1.2,2.4-1.9,3.9-1.1,5.8c1.8,4.5,8.9,4.9,20.2,6.8c0.2,0,0.3,0.1,0.5,0.1
				c0.1,0,0.2,0,0.3,0.1h0c0.9,0.1,1.8,0.3,2.8,0.5c0.3,0.1,0.6,0.1,1,0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.1
				c0.2,0,0.3,0.1,0.5,0.1h0c0.4,0.1,0.8,0.2,1.2,0.2c0.1,0,0.2,0,0.2,0c0.4,0.1,0.7,0.1,1.1,0.2c0.5,0.1,1,0.2,1.5,0.3
				c0.4,0.1,0.9,0.2,1.3,0.3c6.5,1.4,14.2,3.3,22.7,5.9c0.7-4.7,1.3-8.6,1.6-11.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
				c0-0.3,0.1-0.6,0.1-0.9v0c0.1-0.4,0.1-0.8,0.2-1.2c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.6,0.1-1.1,0.2-1.6
				c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.8,0.1-1.1c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.8,0.2-1.5,0.3-2.1v0c0-0.1,0-0.2,0-0.3v0
				c0.2-1.4,0.3-2.6,0.5-3.8c0.2-1.6,0.5-3.2,0.9-5.2c0.9-4.5,1.2-4.5,2-8.7c0.1-0.5,0.2-1,0.3-1.4c1-5.8,1.1-9.7,1.1-11.1
				C99.8,138.9,99.8,137.5,99.7,136.3z M52.1,133.9c0.3-0.8,1.2-1.2,1.9-1.4c-0.4,1.6-0.9,3.2-1.5,4.7c0,0,0,0,0-0.1
				C52,136.1,51.8,134.7,52.1,133.9z M51.7,134.2c-0.2,0.7-0.5,1.9,0,3c0.1,0.2,0.2,0.5,0.5,0.8c-0.1,0.4-0.3,0.7-0.4,1.1
				c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.3-0.3,0.6-0.4,0.9c0,0,0,0.1,0,0.1
				c-0.1,0.3-0.3,0.6-0.4,0.9c-0.5-0.2-0.9-0.5-1.2-1C48.4,139.3,49.1,136.3,51.7,134.2z"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <linearGradient id="SVGID_60_" gradientUnits="userSpaceOnUse"
                                                                    x1="54.2228" y1="119.8011" x2="95.5233"
                                                                    y2="119.8011">
                                                        <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                        <stop offset="0.9308" stopColor="#B87CD8"/>
                                                    </linearGradient>
                                                    <path className="st83" d="M95.5,122.1c-0.6-0.2-1.3-0.3-1.9-0.5c-6.1-1.1-12.6,0.6-17.3,4.7c-1.1,0.9-2.1,2-3.4,2.6
			c-1.1,0.5-2.5,0.7-3.5,0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-1.2-1.1-1.5-3.5-3.2-3.5c-0.6,0-1.1,0.3-1.5,0.7c-1.4,1-2.5,2.3-3.5,3.7
			c0-1.9,0-3.9-0.1-5.8l-0.8,0.8c-0.7,0.2-1.5,0.3-2.2,0.5c-1.1,0.2-2.1,0.5-3.2,0.7c-0.5-2.2-0.2-4.4,0.5-6.5c0.7-2.1,2-4,3.7-5.4
			c3.3-2.9,8-4.1,12.4-3.4c0.1,0,0.2,0,0.3,0l0.1,1.7c-0.6,0-1.4,0-2.4,0.1c-0.1,0-0.6,0.1-1.1,0.2c-0.1,0-2,0.5-3.5,1.6
			c-2,1.5-2.7,3.9-2.9,4.8c-1,3.7,0.3,6.8,0.8,7.9c0.8-0.2,1.4-1.1,2.1-1.4c0.7-0.3,6.6-1.5,6.6-1.5c0,0,2.6-0.5,2.8-0.5
			c1.3-0.2,4.5-1.2,7.1-2.6c2.1-1.2,3.9-2.7,4-4.4c0-0.1,0-0.3,0-0.5l-0.2-3.9c1.7,0.4,3.3,1.1,4.9,2
			C92.7,115.6,95,118.6,95.5,122.1z"/>
                                                </g>
                                                <linearGradient id="SVGID_61_" gradientUnits="userSpaceOnUse"
                                                                x1="27.954"
                                                                y1="137.8537" x2="55.2854" y2="137.8537">
                                                    <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                    <stop offset="0.9308" stopColor="#B87CD8"/>
                                                </linearGradient>
                                                <path className="st84" d="M54.1,132.4L54.1,132.4c-0.7,0.2-1.6,0.7-1.9,1.5c-0.3,0.8-0.1,2.3,0.3,3.2c0,0,0,0,0,0.1
		c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2-0.3-0.4-0.6-0.5-0.8c-0.5-1.1-0.2-2.3,0-3c-2.6,2.1-3.3,5.1-2.3,6.6c0.3,0.5,0.8,0.8,1.2,1
		c-0.2,0.4-0.4,0.8-0.6,1.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.5,0.3-1.1,0.7-1.6,1c0.1,0.1,0.2,0.3,0.3,0.4c0.3-0.2,0.6-0.3,0.8-0.5
		c-1,1.7-1.9,3-3.1,4.4c-0.8,0.9-1.7,1.9-2.7,3c-2.7,3-3.7,3.4-4.6,3.4c-1.9-0.1-2.4-2.1-6.6-7.6c-3.1-3.9-4.6-5.1-4.2-6.9
		c0.2-1,0.9-1.5,2.3-2.7c0.2-0.2,0.4-0.4,0.7-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.4,0.8-0.8,1.1-1.1
		c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.1,0.1-0.1,0.2-0.2
		c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.4,0.7-0.8,1.1-1.2c0.3-0.3,0.6-0.7,0.9-1l0.2-0.2c0,0,0-0.1,0.1-0.1
		c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.3-1.2,1.8-1.8c0.2-0.2,0.3-0.3,0.5-0.5c0,0,0,0,0,0c0.4-0.4,0.9-0.8,1.3-1.1
		c5-4.3,7.8-5,8.7-5.3c1-0.3,1.7-0.4,2.2-0.4h0c0.5,0,0.7,0.1,0.9,0.2C55.9,122,55.6,127,54.1,132.4z"/>
                                                <linearGradient id="SVGID_62_" gradientUnits="userSpaceOnUse"
                                                                x1="89.6416"
                                                                y1="137.5524" x2="117.9049" y2="137.5524">
                                                    <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                    <stop offset="0.9308" stopColor="#B87CD8"/>
                                                </linearGradient>
                                                <path className="st85" d="M117.9,143.8c-0.1,1.2-0.5,4.8-3.3,8.1c-3.4,3.9-8,4.4-9,4.5c-0.6-0.3-3.6-1.8-6.8-4.6
		c-3.8-3.3-8-8.5-8.9-15.6c-0.7-5.2,0.2-12.3,3-15.6c0.7-0.8,1.5-1.4,2.4-1.7c2.4-0.7,5.3,1.1,8.3,4c0.5,0.5,1,1,1.5,1.6
		c0,0,0,0,0,0c0.5,0.6,1,1.1,1.5,1.7c0.2,0.3,0.5,0.6,0.7,0.8c0.3,0.3,0.5,0.7,0.8,1c0.1,0.1,0.1,0.1,0.1,0.2c0.3,0.3,0.5,0.7,0.7,1
		c0.2,0.3,0.5,0.6,0.7,1c0.2,0.2,0.3,0.5,0.5,0.7c0,0,0.1,0.1,0.1,0.1c0.2,0.3,0.5,0.7,0.7,1c0.1,0.2,0.3,0.4,0.4,0.6
		c0.1,0.2,0.2,0.4,0.3,0.5c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6,0.8,1.3,1.2,1.9C114.8,138.4,116.6,141.4,117.9,143.8z"/>
                                                <g>
                                                    <linearGradient id="SVGID_63_" gradientUnits="userSpaceOnUse"
                                                                    x1="307.6306" y1="258.983" x2="377.8745"
                                                                    y2="258.983">
                                                        <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                        <stop offset="0.9308" stopColor="#B87CD8"/>
                                                    </linearGradient>
                                                    <path className="st86" d="M307.6,301.1c-0.4,1.9,8.7,6.7,19.2,9.1c5.5,1.3,22.1,5.2,36.2-2.9c2.9-1.7,8.5-5.1,11.8-11.8
			c5.1-10.2-1.8-15.4-1.5-32.5c0.2-14.8,5.1-22.3,4.4-43.6c-0.1-2.7-0.3-5.6-2.2-8.1c-6.3-8-27.3-7.7-35.9,2.2
			c-6.7,7.7-5.3,22.3-2.4,51.5c0.8,8.1,1.9,17-3.4,24.4c-4,5.6-10,8.1-11.8,8.9C313.8,302,307.8,300,307.6,301.1z"/>
                                                </g>
                                                <linearGradient id="SVGID_64_" gradientUnits="userSpaceOnUse"
                                                                x1="334.2498"
                                                                y1="313.2125" x2="334.2498" y2="313.2125">
                                                    <stop offset="0.2553" stopColor="#3E49F8"/>
                                                    <stop offset="0.9308" stopColor="#8D98FF"/>
                                                </linearGradient>
                                                <path className="st87" d="M334.2,313.2"/>
                                                <linearGradient id="SVGID_65_" gradientUnits="userSpaceOnUse"
                                                                x1="340.8546"
                                                                y1="344.6327" x2="346.0643" y2="344.6327">
                                                    <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                    <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                </linearGradient>
                                                <path className="st88" d="M346.1,316.2c0-1,0-2,0-3c-1.7,0-3.5,0-5.2,0c0,1.2,0,2.5,0,3.7c0,19.7,0,39.4,0,59.1c1.7,0,3.5-0.1,5.2-0.1
		C346.1,356,346.1,336.1,346.1,316.2z"/>
                                                <linearGradient id="SVGID_66_" gradientUnits="userSpaceOnUse"
                                                                x1="328.3426"
                                                                y1="311.4059" x2="360.0941" y2="311.4059">
                                                    <stop offset="0.2553" stopColor="#C6C6E0"/>
                                                    <stop offset="0.9308" stopColor="#A1A5BF"/>
                                                </linearGradient>
                                                <path className="st89" d="M328.3,310.5c3.5,0.7,7.7,1.2,12.6,1.2c7.9,0,14.5-1.5,19.2-3c-2.4,1.6-8.5,5.1-17,5.2
		C336.2,314.1,330.9,311.8,328.3,310.5z"/>
                                                <linearGradient id="SVGID_67_" gradientUnits="userSpaceOnUse"
                                                                x1="271.2438"
                                                                y1="158.1645" x2="289.9077" y2="158.1645">
                                                    <stop offset="0" stopColor="#776EE4"/>
                                                    <stop offset="1" stopColor="#A18CDC"/>
                                                </linearGradient>
                                                <path className="st90"
                                                      d="M271.2,145.5h18.7l-2.2,25.3H274L271.2,145.5z"/>
                                                <g>
                                                    <linearGradient id="SVGID_68_" gradientUnits="userSpaceOnUse"
                                                                    x1="271.3154" y1="136.7827" x2="289.9793"
                                                                    y2="136.7827">
                                                        <stop offset="0.2109" stopColor="#464675"/>
                                                        <stop offset="0.7317" stopColor="#2A2A68"/>
                                                    </linearGradient>
                                                    <path className="st91" d="M281.4,137.9l0.3,7.7h-2.2l0.3-7.7c-4.7-0.4-8.5-4.4-8.5-9.3c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
			c0,4.3,3.3,7.8,7.4,8.2l0.2-4.4h1.1l0.2,4.4c4.2-0.3,7.6-3.9,7.6-8.2c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5
			C290,133.5,286.2,137.5,281.4,137.9z"/>
                                                </g>
                                                <g>
                                                    <g>
                                                        <linearGradient id="SVGID_69_" gradientUnits="userSpaceOnUse"
                                                                        x1="273.469" y1="123.5859" x2="295.6462"
                                                                        y2="123.5859">
                                                            <stop offset="0" stopColor="#776EE4"/>
                                                            <stop offset="1" stopColor="#A18CDC"/>
                                                        </linearGradient>
                                                        <path className="st92" d="M290.3,121.9c0.4-0.8,0.7-1.7,0.7-2.7c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,1,0.2,1.9,0.6,2.7c-3.3,0-6,2.7-6,6
				c0,3.3,2.7,6,6,6c2.1,0,4-1.1,5-2.7c1.1,1.6,2.9,2.7,5,2.7c3.3,0,6-2.7,6-6C295.6,124.8,293.3,122.3,290.3,121.9z"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <linearGradient id="SVGID_70_" gradientUnits="userSpaceOnUse"
                                                                        x1="263.3885" y1="123.5859" x2="285.5657"
                                                                        y2="123.5859">
                                                            <stop offset="0.2553" stopColor="#C7A0F7"/>
                                                            <stop offset="0.9308" stopColor="#B87CD8"/>
                                                        </linearGradient>
                                                        <path className="st93" d="M280.2,121.9c0.4-0.8,0.7-1.7,0.7-2.7c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,1,0.2,1.9,0.6,2.7c-3.3,0-6,2.7-6,6
				c0,3.3,2.7,6,6,6c2.1,0,4-1.1,5-2.7c1.1,1.6,2.9,2.7,5,2.7c3.3,0,6-2.7,6-6C285.6,124.8,283.2,122.3,280.2,121.9z"/>
                                                    </g>
                                                </g>
                                                <path className="st94" d="M271.7,388.6c-0.8,1.9-5.4,1.5-10,2.8c-0.1,0-0.8,0.2-1.8,0.5c-1.6,0.4-2.4,0.7-3.2,0.8
		c-1.1,0.2-2,0.3-3.7,0.4c0,0-6.3,0.3-7.2-0.9c0,0-0.2-0.2-0.4-0.2c0,0,0,0,0,0c-0.2-0.2-0.2-0.5-0.3-0.7c0-0.1-0.1-0.5,0.1-0.9
		c0.1-0.2,0.2-0.4,0.3-0.4c8.6-1.1,17.1-2.2,25.7-3.3C271.9,387.7,271.8,388.3,271.7,388.6z"/>
                                                <path className="st94" d="M346,370.4c0.5,1.8-3.7,3.6-6.1,7.9c-0.2,0.3-0.7,1.2-1.4,2.3c-0.9,1.3-1.3,1.9-2,2.6
		c-0.8,0.7-0.8,0.5-3.6,2.1c-1.7,1-2.6,1.4-3.4,1.5c-0.5,0-1.6,0-3-1.2c2.7-2.8,5.7-5.7,8.9-8.6c3.1-2.9,6.2-5.5,9.2-7.8
		C345.6,369.6,345.9,370,346,370.4z"/>
                                                <linearGradient id="SVGID_71_" gradientUnits="userSpaceOnUse"
                                                                x1="274.3082"
                                                                y1="386.7423" x2="249.8324" y2="378.9145">
                                                    <stop offset="0.2553" stopColor="#FFFFFF"/>
                                                    <stop offset="0.9308" stopColor="#DEDCE2"/>
                                                </linearGradient>
                                                <path className="st95" d="M261.6,372.1c0.6-0.2,8.6-0.7,9,1.9c0.2,1.9,0.4,7.3,0.5,10.4c0.1,1.2,0.3,2.3,0.3,2.3
		c-2.1,0.7-3.8,1.2-5.1,1.6c-8.5,2.4-14.7,2.9-17.6,2.3c-0.3,0-3-0.6-3-0.6s0.3-1,0.7-2c0.3-0.8,0.8-1.4,1.9-2.2
		c1.7-1.2,6.5-4.3,8.7-7.1c1-1.3,1.8-2.7,2.3-3.9c0.3-0.7,0.3-1.4,0.7-1.8C260.5,372.4,261,372.4,261.6,372.1z"/>
                                                <linearGradient id="SVGID_72_" gradientUnits="userSpaceOnUse"
                                                                x1="343.4236"
                                                                y1="377.5233" x2="325.3702" y2="370.781">
                                                    <stop offset="0.2553" stopColor="#FFFFFF"/>
                                                    <stop offset="0.7174" stopColor="#E9E8EC"/>
                                                    <stop offset="0.9308" stopColor="#DEDCE2"/>
                                                </linearGradient>
                                                <path className="st96" d="M329.2,364.4c0.3-0.5,6.1-5.3,7.8-3.6c1.2,1.2,4.2,5.1,6.1,7.3c0.7,0.8,1.5,1.5,1.5,1.5s-1.3,1.6-2.9,4
		c-4.7,6.7-5.3,7.2-6.4,8.1c-4.4,3.8-8.6,4.1-8.8,4c0,0-0.1-0.1-0.1-0.2c0-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.3-0.4-0.8-0.5-1.5c0,0,0,0,0,0c0,0-0.2-1.1,0-2.2c0.2-1.6,2.9-2.4,3.7-5c0.3-0.9,0.3-2.1,0.3-2.3c0-0.7,0-0.8-0.1-2.1
		c0-1.4,0-1.4,0-2.1c-0.1-0.8-0.2-1.4-0.3-2c-0.2-0.7-0.5-1.2-0.5-1.7C328.5,365.2,328.9,364.9,329.2,364.4z"/>

                                                <linearGradient id="SVGID_73_" gradientUnits="userSpaceOnUse"
                                                                x1="242.2867"
                                                                y1="404.8766" x2="262.049" y2="397.6756"
                                                                gradientTransform="matrix(0.9272 -0.3745 0.3745 0.9272 -68.3699 0.7829)">
                                                    <stop offset="0.2109" stopColor="#525292"/>
                                                    <stop offset="0.8043" stopColor="#5E5EAA"/>
                                                </linearGradient>
                                                <path className="st97" d="M319.8,269.5c0.8,1-2.8,3.5-3,8.3c-0.2,2.8,0.9,5.3,1.9,7c-3.1,5.5-7.2,9.4-10.1,8.7
		c-3.9-1-5.9-10.2-2.7-16.5C309.6,270.2,318.8,268.1,319.8,269.5z"/>
                                                <path className="st48"
                                                      d="M285.4,227.2c-0.1-0.9,3.9-2.9,8-2c2.7,0.6,4.4,2.3,5,3C288.3,228.4,285.5,227.9,285.4,227.2z"/>
                                            </g>

                                        </svg>

                                    </div>
                                </ScrollAnimation>

                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile">
                                <ScrollAnimation delay={200} animateIn='fadeInLeft' animateOut='fadeOut'
                                                 initiallyVisible={false}>

                                    <h3 className="mt-0 mb-12">
                                        Или персонал использует номера по своему усмотрению
                                    </h3>
                                    <p className="m-0">
                                        Сотрудник гостиницы заселяет друзей или сам гостит без разрешения
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}>


                                <ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOut' initiallyVisible={false}>
                                    <div className="blob2">

                                        <svg viewBox="0 0 553 517">

                                            <linearGradient id="MyGradient">
                                                <stop offset="5%" stopColor="#c4d0f9"/>
                                                <stop offset="95%" stopColor="#e5d0fa"/>
                                            </linearGradient>

                                            <g className="g1">
                                                <path className="bg" d="M60.5-32.9c1.6-7,5.1-11.2,8-11c0.3,0,0.8,0.1,1.4,0.5c-0.3,1.5-0.4,3-0.4,4.5c0,13.2,10.8,24,24,24
		s24-10.8,24-24c0-1.6-0.2-3.1-0.4-4.5c1-0.4,2.2-0.7,3.4-0.5c4.1,0.9,6,7,7,10c2.7,8.3,1.8,17,2,17c0,0,0-0.1,0-0.1
		c0.2-1.9,1.4-4.5,2-9.9c0.3-3.1,0.5-5.2,0-8c-0.5-2.7-1.3-4.7-2-6c-1-1.9-1.6-3.1-3-4c-1.3-0.9-2.6-1-6-1c-1.9,0-4.1,0-6.4,0
		l-0.8-2.8c0.8-2.1,2.3-4.8,5.2-7.2c4.1-3.4,8.5-3.9,10-4c-2.8-0.4-8.6-0.7-15,2c-0.9,0.4-1.7,0.8-2.4,1.2l-1.1-3.9
		c1.7-1.6,3.4-2.7,4.6-3.3c-2.1-0.1-3.9,0-5.5,0.2l-11.9-41.5c0.7-0.3,1.1-1,1.3-1.7h-4.9c0.1,0.7,0.6,1.3,1.2,1.6L81.9-69.1
		c-4.7-2.4-9.3-2.7-11.4-2.8c2.9,1.5,6.6,3.8,9.8,7.2l-3.1,8.8c-1.4-0.8-3-1.3-5.7-2c-2.4-0.7-6.2-1.4-11-1c2.1,0.4,5.5,1.4,9,4
		c2.4,1.8,4.1,3.8,5.4,5.7l-0.9,2.7c-0.4-0.1-0.9-0.3-1.4-0.3c-3.9-0.7-7,1-9,2c-1.5,0.8-3.5,1.8-5,4c-1.3,1.8-1.9,4-2,8
		c-0.1,3.4,0.1,8.6,2,15C59.2-22.9,59.8-27.9,60.5-32.9z M113.1-45.9c-0.2,0-0.4,0-0.6,0c0.1-0.3,0.1-0.7,0.3-1.2L113.1-45.9z
		 M104.5-51.9c0.5-1.6,1.3-3.8,3-6c0.5-0.7,1.1-1.3,1.6-1.9l1,3.6C107.7-54.7,105.8-53.1,104.5-51.9z M95.7-104.9c0.1,0,0.2,0,0.3,0
		c0.1,0,0.1,0,0.2,0l11.9,41.3c-3.9,0.7-6.2,2.2-7.5,3.7c-2.6,2.8-4.5,8.2-5,8c-0.1,0-0.1-0.2-0.1-0.4c0-1-0.7-2.2-1.9-4.6
		c-3.2-6.4-8-10-8-10c-0.9-0.7-1.8-1.2-2.7-1.8L95.7-104.9z M81.1-63.8c1.3,1.5,2.4,3.1,3.4,4.9c3,5.6,2.6,9.8,2,11
		c-0.1,0.3-0.5,1-1,1c-0.5,0-0.9-0.8-1-1c-1.4-2.8-5.7-6.8-6-7c-0.1-0.1-0.3-0.2-0.4-0.4L81.1-63.8z M75.6-48.1
		c0.7,1.2,1.2,2.3,1.5,3.1c-0.6-0.4-1.4-0.8-2.2-1.2L75.6-48.1z"/>
                                                <path className="bg" d="M182.7-95.1l-1.2-10.3c-0.1-0.8-0.8-1.5-1.7-1.5h-6.7c-0.8,0-1.5,0.6-1.6,1.5l-1.2,10.3
		c-8.4,2.1-15.2,8.2-18.1,16.2h48.8C197.9-86.9,191.1-93.1,182.7-95.1z"/>
                                                <path className="bg" d="M248-18l-1.6-13.4c-0.1-0.8-0.8-1.5-1.6-1.5h-2.9v-74h-1v74H238c-0.8,0-1.6,0.6-1.7,1.5L234.7-18
		c-8.6,2.2-15.4,8.8-18.1,17.1h49.5C263.4-9.3,256.5-15.8,248-18z"/>
                                                <path className="bg" d="M409.2-66.1c-1.7-0.9-3.2-1-4.3-1c-1.8-0.7-3.6-1.6-5.2-2.7c0.2,0,0.4,0,0.5,0c3.4-0.3,5.5-2.4,6-3
		c-0.7-0.3-2.9-1.3-5.8-0.6c-1.5,0.4-2.6,1-3.4,1.7c-0.2-0.2-0.5-0.4-0.7-0.6c-0.7-0.7-1.8-1.6-3.5-3c-0.5-0.4-0.9-0.8-1.3-1.1
		c0.5-1,0.6-1.9,0.7-2.3c-0.1,0-0.2,0-0.3,0c0.2-0.1,0.4-0.2,0.6-0.3c3-1.6,4-4.5,4.2-5.2c-0.7,0-3.2,0.1-5.5,1.9
		c-1.5,1.2-2.3,2.6-2.7,3.6c-1.1-0.8-2-1.4-2.8-1.7c-2.8-1-5.4-0.8-7.5-0.4c0.4-0.4,0.7-0.9,1-1.5c1.6-3,1-5.9,0.8-6.7
		c-0.6,0.4-2.7,1.8-3.7,4.5c-0.6,1.6-0.6,3.1-0.5,4.1c-0.9,0.2-1.8,0.5-2.6,0.9l-15.7-44.9l-19.2,43.5c-0.6-0.5-1.3-0.8-2.9-1.4
		c0.4-1,0.8-2.9,0.3-5.1c-0.6-2.6-2.2-4.1-2.8-4.6c-0.4,0.8-1,2.4-0.8,4.4c0.2,2.2,1.1,3.8,1.9,4.8c-1.9-0.6-4.8-1.5-9.1-2.7
		l-0.3-0.1c-0.5-0.1-1-0.3-1.4-0.4c0.5-1,1-2.8,0.7-5c-0.4-2.6-1.9-4.2-2.5-4.8c-0.4,0.8-1.1,2.3-1.1,4.3c0,2.3,0.9,4,1.7,5.1
		c-3.5-1.1-6-2.2-8.3-3.7c0.7-0.9,1.5-2.5,1.6-4.6c0.2-2.6-0.9-4.6-1.3-5.2c-0.6,0.7-1.6,2-2,4c-0.4,1.9-0.1,3.5,0.3,4.7
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.9-0.7-1.7-1.6-2.5-2.4c-0.3-1.1-1.1-2.8-2.8-4.2c-2-1.7-4.2-1.9-5-2c0.2,0.9,0.7,2.5,2,4
		c1.7,1.9,3.8,2.6,5.1,2.9c0.8,0.9,1.6,1.7,2.5,2.5c-0.7-0.1-1.4-0.2-2.2-0.2c-3.4,0.2-5.6,2.3-6.1,2.8c0.7,0.3,2.9,1.4,5.8,0.8
		c2-0.4,3.4-1.5,4.2-2.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0,0c1.9,1.3,3.9,2.2,6.3,3.1c-0.3,0.1-0.6,0.1-1,0.2
		c-3.3,0.9-4.9,3.5-5.3,4.1c0.7,0.2,3.1,0.7,5.8-0.5c1.7-0.8,2.8-1.9,3.4-2.8c0.6,0.2,1.3,0.4,2,0.6l0.3,0.1
		c3.2,0.9,5.6,1.6,7.4,2.2c-0.4,0.1-0.9,0.2-1.4,0.4c-3.2,1.1-4.7,3.8-5,4.5c0.7,0.1,3.2,0.5,5.8-0.9c1.7-0.9,2.7-2.2,3.2-3.1
		c2,0.7,2.7,1,3.3,1.6l-1.5,3.3c-2.1-0.4-3.9,0.1-4.4,0.3c0.3,0.5,1.3,1.8,3.1,2.8l-0.6,1.3c-0.3-0.1-0.6-0.2-0.8-0.2
		c-0.6-0.7-1.6-1.7-3-2.4c-3-1.5-6-0.7-6.7-0.5c0.4,0.6,1.9,2.6,4.7,3.5c0.5,0.1,0.9,0.2,1.4,0.3c-1.6,1.2-2.5,3.2-3,4.3
		c-0.4,0.9-0.8,2-1,3.9c-0.9,0.2-2.4,0.6-3.9,1.7c-2.7,2.1-3.2,5.1-3.3,5.8c0.7-0.1,3.2-0.6,5.2-2.7c1-1.1,1.6-2.3,1.9-3.3
		c0,1.1,0,2.3,0.1,3.8c0.1,1.6,0.3,2.7,0.5,3.7c-0.9,0.3-2.2,0.8-3.4,1.9c-2.5,2.3-2.9,5.3-3,6c0.7-0.2,3.1-0.8,5-3
		c1-1.2,1.5-2.5,1.7-3.5c0.1,0.3,0.2,0.7,0.2,1c0.3,1.3,0.7,2.7,1,5c0.3,2.1,0.4,4,0.4,5.7c-0.9,0.2-2.1,0.7-3.2,1.6
		c-2.7,2.1-3.2,5.1-3.3,5.8c0.7-0.1,3.2-0.6,5.1-2.7c0.6-0.6,1-1.2,1.3-1.9c-0.1,1.4-0.2,2.6-0.3,3.3c-0.2,2.2-0.5,3.5-0.9,5.1
		c0,0.2-0.1,0.4-0.1,0.6c-0.9,0.2-2.4,0.6-3.8,1.7c-2.7,2.1-3.2,5.1-3.3,5.8c0.7-0.1,3.2-0.6,5.1-2.7c0.7-0.7,1.1-1.5,1.5-2.2
		c-0.1,0.8-0.3,1.6-0.4,2.6c-0.4,2.6-0.7,5.3-0.9,7.9c-0.5,0.8-1.1,2-1.3,3.5c-0.5,3.4,1.2,5.9,1.7,6.5c0.5-0.6,1.9-2.6,1.8-5.5
		c0-1.9-0.7-3.3-1.2-4.2c0.2-2.6,0.5-5.3,0.9-8c0.1-0.9,0.3-1.7,0.4-2.4c0.1,0,0.1,0,0.2,0c0.3,0.6,0.6,1.3,1.2,1.9
		c0.4,0.5,0.9,1,1.3,1.3c-0.2,0.2-0.4,0.4-0.6,0.7c-2,2.7-1.7,5.8-1.6,6.5c0.7-0.3,2.9-1.4,4.2-4c0.3-0.6,0.5-1.1,0.6-1.7
		c0.8,0.3,1.5,0.4,1.8,0.5c-0.1-0.5-0.4-1.9-1.3-3.3c0.9-1.3,1.8-2.6,2.7-4c0,0.8,0.1,1.7,0.5,2.6c1.1,3.2,3.7,4.7,4.4,5.1
		c0.1-0.7,0.5-3.2-0.8-5.8c-0.9-1.7-2.2-2.8-3.1-3.3c0.8-1.2,2.5-4.1,4.1-7.2c0,0.9,0,2,0.4,3.2c1.1,3.2,3.7,4.7,4.4,5.1
		c0.1-0.7,0.5-3.2-0.8-5.8c-1-2-2.6-3.1-3.5-3.6c0.1-0.3,0.3-0.5,0.4-0.8c0.2-0.3,0.3-0.7,0.5-1.1c3.8,2.4,8.2,3.9,13.1,3.9
		c3,0,5.9-0.6,8.6-1.6c0,0.5-0.1,1.1-0.1,1.6c0,0.1,0,0.1,0,0.2c-0.9,0.2-2.4,0.7-3.8,1.9c-2.6,2.2-3,5.2-3.1,6
		c0.7-0.1,3.1-0.7,5-2.9c1.1-1.3,1.6-2.6,1.8-3.6c-0.1,2.4-0.1,4.9,0,7.3c-0.5,0.8-1,2-1.2,3.5c-0.4,3.4,1.4,5.8,1.8,6.5
		c0.4-0.6,1.8-2.7,1.7-5.6c-0.1-1.9-0.7-3.3-1.3-4.2c-0.1-2.2-0.1-4.3,0-6.5c0.4,0.5,0.9,1,1.5,1.5c2.7,2.1,5.7,1.8,6.5,1.8
		c-0.3-0.7-1.3-2.9-3.9-4.3c-1.5-0.8-3-1-4-1c0-0.1,0-0.3,0-0.4c0-0.7,0.1-1.4,0.1-2c8.5-3.7,14.4-12.2,14.4-22c0-2-0.3-3.9-0.7-5.8
		c0.3,0.5,0.7,0.9,1.2,1.4c2.5,2.3,5.5,2.5,6.2,2.5c-0.2-0.7-1-3.1-3.4-4.7c-1.5-1.1-3.1-1.4-4.1-1.5c0.4-0.2,0.8-0.6,1.2-1
		c1.8-1.2,3.3-1.8,4.5-1.7c0,0.9,0.2,2.1,0.8,3.4c0.5,1,1.1,1.8,1.8,2.5c0,0.7,0,1.3,0.1,2c0,0.8,0,1.5,0,2.2
		c-0.9,0.2-2.4,0.7-3.8,1.9c-2.6,2.2-3,5.2-3.1,6c0.7-0.1,3.1-0.7,5-2.9c0.8-0.9,1.3-1.9,1.6-2.7c-0.1,0.3-0.1,0.6-0.2,1
		c-0.2,1-0.4,2.1-0.5,3.6c-0.2,1.8-0.3,3.4-0.3,5.3c-0.9,0.2-2.3,0.7-3.6,1.9c-2.6,2.2-3,5.2-3.1,6c0.7-0.1,3.1-0.7,5-2.9
		c0.9-1,1.4-2.1,1.7-3c0,1.4,0.1,3,0.2,4.9c0.1,1.8,0.2,3.2,0.3,4.4c-0.9,0.3-2,0.8-3.1,1.7c-2.6,2.2-3,5.2-3.1,6
		c0.7-0.1,3.1-0.7,5-2.9c0.6-0.8,1.1-1.5,1.4-2.3c0,0.1,0,0.1,0,0.2c0.1,1.4,0.3,2.9,0.5,5c0.1,1.8,0.2,3.5,0.3,5.3
		c-0.9,0.3-2,0.8-3.1,1.7c-2.6,2.2-3,5.2-3.1,6c0.7-0.1,3.1-0.7,5-2.9c0.6-0.7,1-1.4,1.3-2c0,1.6,0.1,3.2,0.1,4.8
		c-0.6,0.7-1.2,1.9-1.6,3.3c-0.8,3.3,0.7,6,1,6.6c0.5-0.5,2.1-2.4,2.3-5.3c0.1-1.9-0.4-3.4-0.8-4.3c0-2.1,0-4.2-0.1-6.3
		c0.5,0.7,1.1,1.6,2.1,2.3c2.7,2.1,5.7,1.8,6.5,1.8c-0.3-0.7-1.3-2.9-3.9-4.3c-1.9-1-3.7-1.1-4.7-1c-0.1-1.9-0.2-3.7-0.3-5.5
		c-0.2-2.1-0.3-3.6-0.5-5c0-0.4-0.1-0.8-0.1-1.1c0.5,0.7,1.1,1.5,2.1,2.2c2.7,2.1,5.7,1.8,6.5,1.8c-0.3-0.7-1.3-2.9-3.9-4.3
		c-1.9-1-3.7-1.1-4.7-1c-0.1-1.2-0.2-2.6-0.3-4.5c-0.1-1.8-0.2-3.3-0.2-4.6c0.4,0.6,1,1.2,1.7,1.7c2.7,2.1,5.7,1.8,6.5,1.8
		c-0.3-0.7-1.3-2.9-3.9-4.3c-1.6-0.9-3.2-1-4.3-1c0-1.9,0-3.6,0.2-5.4c0.2-1.4,0.4-2.5,0.5-3.5c0.1-0.4,0.1-0.9,0.2-1.3
		c0.4,0.6,1,1.2,1.8,1.8c2.7,2.1,5.7,1.8,6.5,1.8c-0.3-0.7-1.3-2.9-3.9-4.3c-1.6-0.8-3-1-4.1-1c0.1-0.7,0.1-1.5,0.1-2.5
		c0-0.4,0-0.7,0-1.1c0.9,0.7,1.8,1.1,2.1,1.2c0.1-0.7,0.2-3.2-1.5-5.6c-0.6-0.9-1.3-1.5-1.9-2c-0.3-0.4-0.6-0.7-0.9-0.9
		c0,0-0.1,0-0.1-0.1c0.9-0.2,2-0.6,3-1.4c1.2,2.5,3.2,3.8,3.8,4.1c0.2-0.7,0.6-3.2-0.7-5.8c-0.1-0.2-0.2-0.4-0.3-0.6
		c0.2,0.2,0.5,0.4,0.7,0.6c1.7,1.4,2.7,2.3,3.5,2.9c0.1,0.1,0.3,0.2,0.4,0.3c-0.4,0.9-0.8,2.3-0.7,4.1c0.3,3.4,2.4,5.5,3,6
		c0.3-0.7,1.3-2.9,0.6-5.8c-0.3-1.3-0.8-2.3-1.4-3.1c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.6,0.5c1.8,1.3,3.8,2.4,6,3.3
		c0.5,0.8,1.2,1.9,2.4,2.7c2.7,2,5.7,1.7,6.5,1.6C412.9-62.5,411.8-64.7,409.2-66.1z M372.9-77.6C372.9-77.6,372.8-77.6,372.9-77.6
		c-0.4,0-0.8,0-1.2,0.1c0.3-0.2,0.7-0.3,1-0.5L372.9-77.6z M338.7-79c1.2,1.4,2.1,3.1,2.6,5.2c-0.6-0.6-1.4-1.4-2.6-1.9
		c-0.4-0.2-0.9-0.4-1.3-0.5L338.7-79z M335.1-70.8c0.5,0.2,0.9,0.5,1.3,0.9h-1.7L335.1-70.8z M334.2-71.2l-0.9,2.1
		c0.1-0.9,0-1.7-0.1-2.3C333.4-71.4,333.8-71.3,334.2-71.2z M328-66.7c0.5-1.2,1.4-3.1,2.9-4.1c-0.4,0.5-0.7,1-1,1.6
		c-1.5,3-0.7,6-0.5,6.7c0.5-0.3,1.8-1.3,2.8-3.1c-0.4,1.8-0.7,3.7-0.7,5.7c0,0.1,0,0.3,0,0.4c-0.2-0.2-0.4-0.4-0.6-0.6
		c-1.4-1.3-2.9-1.9-4-2.1C327.1-64.5,327.5-65.8,328-66.7z M332.8-25.2c-0.3-0.3-0.5-0.6-0.9-0.9c-0.2-0.1-0.3-0.3-0.5-0.4
		c0.5-0.1,1-0.3,1.6-0.6c0.9-0.5,1.6-1,2.2-1.6C334.4-27.5,333.6-26.3,332.8-25.2z M336.6-31c-0.9-0.2-2.5-0.3-4.3,0.3
		c-1.8,0.6-3,1.7-3.8,2.6c-0.2-0.1-0.5-0.1-0.7-0.2c0.1-0.5,0.2-1,0.3-1.4c0.3-1.6,0.7-2.9,0.9-5.2c0.1-0.9,0.3-2.3,0.3-4.2
		c0.3,0.8,0.7,1.8,1.5,2.8c0.5,0.6,1.1,1.2,1.6,1.6c-0.1,0.2-0.2,0.3-0.2,0.4c0.2,0,0.6,0.1,1.1,0.1c1.5,0.9,2.9,1.1,3.4,1.2
		c0-0.3-0.1-0.8-0.4-1.5c0.6-0.2,1.1-0.4,1.7-0.7c0.6-0.3,1.2-0.7,1.6-1.1C338.5-34.1,337.3-32.2,336.6-31z M341.1-39.1
		C341.1-39.1,341-39.1,341.1-39.1c-1,0-2.3,0-3.8,0.5c-1,0.4-1.9,0.9-2.6,1.4c-0.2-0.3-0.5-0.6-0.8-0.9c-1.7-1.6-3.6-2-4.5-2.2
		c0-1.9-0.1-4.2-0.4-6.7c-0.1-1.1-0.3-2-0.4-2.7c0.1,0.1,0.2,0.3,0.3,0.4c2.3,2.5,5.3,2.9,6,3c-0.2-0.7-0.8-3.1-3-5
		c-1.5-1.2-3.1-1.7-4.1-1.9c-0.3-1.2-0.5-2.6-0.7-4.7c0-0.6-0.1-1.2-0.1-1.7c0.2,0.5,0.5,0.9,0.9,1.4c1.3,1.6,2.9,2.5,4.1,2.9
		c1.2,6.2,4.7,11.5,9.7,15C341.4-39.9,341.2-39.5,341.1-39.1z M340.4-70.4c0.2,0.1,0.6,0.3,1,0.5h-3.7c-0.7-0.8-1.5-1.4-2.3-1.9
		L336-73c0.2,0.1,0.5,0.2,0.7,0.3c2.1,0.6,3.8,0.3,4.8,0.1c0.1,0.4,0.2,0.8,0.2,1.2C341.1-71,340.6-70.6,340.4-70.4z M349.4-69.9
		c0.1-0.3,0.2-0.5,0.3-0.7c0.4-0.3,0.7-0.5,1-0.7c0.3,0.2,0.7,0.7,1.1,1.4H349.4z M354.6-69.9c0.1-0.6,0.2-1.1,0.4-1.6
		c0.8,0.5,2.1,1.2,3.7,1.4c0.9,0.1,1.8,0.1,2.6-0.1c0.1,0.1,0.1,0.2,0.2,0.3H354.6z M355.8-72.5C355.8-72.5,355.8-72.5,355.8-72.5
		c0.2-0.2,1.4-1.2,2.6-0.9c0,0,0,0,0,0C357.4-73.2,356.5-72.8,355.8-72.5z M363.9-69.9h-1.1c-0.1-0.2-0.2-0.3-0.3-0.5
		c0.6-0.2,1.1-0.4,1.5-0.6C363.9-70.6,363.9-70.2,363.9-69.9z M361.9-80.6c-0.3,0.7-1.1,3-0.3,5.8c0.2,0.6,0.4,1.1,0.6,1.6
		c-0.6-0.2-1.4-0.3-2.2-0.3c-0.5-0.5-0.9-0.7-1.5-0.8c-0.8-0.1-1.5,0-2,0.3c0.3-0.6,0.5-1.4,0.6-2.3c0.4-3.4-1.3-5.9-1.7-6.5
		c-0.4,0.6-1.8,2.6-1.8,5.6c0.1,1.9,0.7,3.4,1.3,4.3c-0.8,0.8-1.2,1.9-1.3,3.1H353c-0.3-0.6-0.6-1.2-1-1.6c0.4-0.8,0.8-2,0.8-3.5
		c0.1-3.4-1.9-5.7-2.4-6.3c-0.4,0.6-1.6,2.8-1.2,5.7c0.2,1.3,0.6,2.4,1.1,3.2c-0.2,0-0.4,0.1-0.6,0.2c-0.8-0.3-1.8-0.6-3.1-0.7
		c-1.6,0-2.9,0.4-3.9,0.8c-0.1-0.6-0.2-1.1-0.3-1.6c1-0.5,2.6-1.4,3.8-3.3c1.4-2.2,1.4-4.4,1.3-5.2c-0.8,0.3-2.4,1-3.7,2.5
		c-1.2,1.4-1.7,2.8-2,4c-0.6-1.8-1.5-3.2-2.7-4.4l18.3-41.6L372.4-79c-0.8,0.4-1.6,0.8-2.5,1.3c0.2-0.5,0.4-1.2,0.5-1.8
		c0.5-3.4-1.1-5.9-1.6-6.5c-0.5,0.6-1.9,2.6-1.9,5.5c0,1.7,0.5,3.1,1,4c-1.5,0.9-2.2,1.6-2.6,2.1c0-0.1,0-0.2,0-0.4
		C364.8-78.2,362.5-80.1,361.9-80.6z M371-69.9c1.8-0.9,2.9-2.1,3.2-2.5c-0.7-0.3-3-1.1-5.8-0.3c-1.6,0.5-2.8,1.4-3.6,2.1
		c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0.1-0.3
		c0.2-0.5,0.4-1,0.7-1.5c0.4-0.5,1.1-1.2,2.3-1.9c0.8,0.5,2,1.2,3.6,1.4c0.8,0.1,1.6,0.1,2.3,0l1.5,4.2H371z M378.5-73
		c-0.7,1.7-0.7,3.2-0.6,4.2c0,0,0,0-0.1,0c-0.2-0.4-0.3-0.8-0.5-1.2h-0.7l-1.5-4.4c1.7-0.4,2.8-1.1,3.2-1.4
		c-0.5-0.4-2.1-1.5-4.4-1.8l-0.3-0.9c0.7-0.3,1.5-0.6,2.3-0.8c0.6,0.8,1.5,1.8,2.8,2.5c0.6,0.3,1.2,0.6,1.8,0.7
		C379.8-75.3,379-74.3,378.5-73z M387.9-76.8c-0.3,0.2-0.6,0.5-0.9,0.8c-1.5,1.6-2.1,3.4-2.3,4.3c-0.1,0-0.2,0-0.2,0
		c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.9,0-1.8,0.2-2.9,0.7c1-1.7,1.2-3.4,1.1-4.6c1.4,0.1,2.4-0.2,2.8-0.3c-0.4-0.6-1.8-2.7-4.5-3.7
		c-0.5-0.2-0.9-0.3-1.4-0.4c1.7-0.2,3.7-0.2,5.8,0.5c0.7,0.2,1.5,0.8,2.6,1.5C387.9-77.6,387.9-77.2,387.9-76.8z M389.6-77.9
		C389.6-77.9,389.6-77.9,389.6-77.9c0-0.1,0.1-0.1,0.2-0.1C389.7-77.9,389.7-77.9,389.6-77.9z"/>
                                                <path className="bg" d="M131.5,47.1h37v-51h-9.9l-7.5-15l-9.4,15h-10.2V47.1z M151-16.9l6.5,13h-14.6L151-16.9z M135.5,0.1h29v43h-29
		V0.1z"/>
                                                <path className="bg"
                                                      d="M76.9,58.1H66.5v40h41v-40H97.1l-9-18L76.9,58.1z M101.5,63.1v29h-29v-29H101.5z M78,58.1l10-16l7.9,16H78z"/>
                                                <path className="bg" d="M410.5,7.9l0.6-0.3v-8.5L96,145.6l3.5,5.5l19-8.8v126.8v9v31H2.5v2h409v-2h-291v-31h57v-9v-15h59v-9v-17
		c13.7,0.3,30.1,0.5,43.6,0.8c5.5,0.1,10.9-1.1,15.9-3.4l115.5-56.4v-10c-0.3,0.2-0.7,0.3-1,0.5V7.9z M408.5,160.6
		c-19.7,9.6-39.3,19.1-59,28.7V36l59-27.2V160.6z M120.5,269.1V141.4l55-25.3v153H120.5z M177.5,245.1V115.2l57-26.3v156.2H177.5z
		 M236.5,219.1V88l54-24.9v154.4c-3.1,1-6.4,1.6-9.8,1.6H236.5z M294.4,216c-0.6,0.3-1.3,0.6-1.9,0.8V62.2l55-25.3v153.3
		C329.8,198.8,312.1,207.4,294.4,216z"/>
                                            </g>
                                            <path className="g0" fill="url(#MyGradient)">
                                                <animate attributeName="d" dur="8s" repeatCount="indefinite"
                                                         values="M305.2 148.5c0 84.3-68.4 152.7-152.7 152.7c-84.3 0-152.7-68.4-152.7-152.7C-0.2 62.4 18.6 1.4 94.2 0.6
	C171.2-0.2 305.2 60.8 305.2 148.5z; m305.2,148.5c-15,86.3 -89.4,154.7 -173.7,154.7c-84.3,0 -145.7,-72.6 -131.7,-154.7c14,-82.1 133.8,-147.1 209.4,-147.9c77,-0.8 111,61.6 96,147.9z; M305.2 148.5c0 84.3-68.4 152.7-152.7 152.7c-84.3 0-152.7-68.4-152.7-152.7C-0.2 62.4 18.6 1.4 94.2 0.6
	C171.2-0.2 305.2 60.8 305.2 148.5z; "/>
                                            </path>


                                        </svg>
                                        <Image
                                            src={require('./../../assets/images/guest.svg')}
                                            alt="Features split 02"
                                            width={528}
                                            height={396}/>

                                    </div>
                                </ScrollAnimation>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

Features.propTypes = propTypes;
Features.defaultProps = defaultProps;

export default Features;
