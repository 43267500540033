import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from "./partials/Logo";
import FooterSocial from "./partials/FooterSocial";
import ScrollAnimation from "react-animate-on-scroll";

const propTypes = {
    topOuterDivider: PropTypes.bool,
    topDivider: PropTypes.bool
}

const defaultProps = {
    topOuterDivider: false,
    topDivider: false
}

const Footer = ({
                    className,
                    topOuterDivider,
                    topDivider,
                    ...props
                }) => {

    const classes = classNames(
        'site-footer center-content-mobile',
        topOuterDivider && 'has-top-divider',
        className
    );

    return (
        <footer
            {...props}
            className={classes}
        >

            <ScrollAnimation  animateIn='fadeInUp' animateOut='fadeOut' initiallyVisible={false}>
            <div className="top-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12 about-widget aos-init aos-animate" data-aos="fade-up">
                            <Logo />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 footer-list aos-init aos-animate" data-aos="fade-up">
                            <ul>
                                <li><a href="#">Система</a></li>
                                <li><a href="#">Оборудование</a></li>
                                <li><a href="#">Стоимость</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 footer-list aos-init aos-animate" data-aos="fade-up">
                            <ul>
                                <li><a href="#">Демонстрация</a></li>
                                <li><a href="#">Демоверсия</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-lg-2 col-sm-6 col-12 footer-information aos-init aos-animate"
                             data-aos="fade-up">
                            <p>ООО Софтра</p><p>ул. Бурденко, 16/12<br/>Москва, Россия</p>
                            <FooterSocial />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    <div className="footer-bottom space-between col-12">
                            <span>©2021 Softra Hotel monitor </span>
                            <a href="/privacy-policy/">Политика конфиденциальности</a>
                    </div>
                </div>
            </div>
            </ScrollAnimation>

        </footer>
);
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
