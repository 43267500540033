import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useIntersection} from "react-use";
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Button from "../elements/Button";

import gsap, {Power3} from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"


const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const SystemWorks = ({
                         className,
                         topOuterDivider,
                         bottomOuterDivider,
                         topDivider,
                         bottomDivider,
                         hasBgColor,
                         invertColor,
                         invertMobile,
                         invertDesktop,
                         alignTop,
                         imageFill,
                         ...props
                     }) => {

    const outerClasses = classNames(
        'system-works section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );


    function getCircleSize() {
        let circleTransform = document.querySelector('#circle').getAttribute('transform')
        let circleWidth = document.querySelector('#circle').getBoundingClientRect().width
        if (circleTransform !== null && circleTransform.includes('matrix')){
            return circleWidth / circleTransform.slice(7, circleTransform.length-1).split(',')[0]
        }
        return circleWidth;
    }

    const getCirclePosition = (el, x=0, y=0) =>{

        let detectorCoords= document.querySelector(el).getBoundingClientRect()
        let detectorsCoords= document.querySelector('.detectors').getBoundingClientRect()
        let circle = document.querySelector('#circle').getBoundingClientRect()
        return {
            x: Math.abs(detectorCoords.left - detectorsCoords.left) + x ,
            y: Math.abs(detectorCoords.top - detectorsCoords.top) + y,
        }
    }
    const setCirclePosition = () => {

        setTimeout(()=>{
            let el = currentCircleElement;

            let hotelCoords = document.querySelector('.system-works-hotel').getBoundingClientRect()
            let hotelImgCoordsLocal = document.querySelector('.img-box-wrapper').getBoundingClientRect()
            let detectorCoords= document.querySelector(el).getBoundingClientRect()
            let detectorsCoords= document.querySelector('.detectors').getBoundingClientRect()
            let detector = {
                x: Math.abs(detectorCoords.left - detectorsCoords.left),
                y: Math.abs(detectorCoords.top - detectorsCoords.top),
            }

            let circle = document.querySelector('#circle')

            let r = 7;
            if (window.innerWidth <= 480) {
                detector = {
                    x: Math.abs(detectorCoords.left - detectorsCoords.left) / 2.5 + 5,
                    y: Math.abs(detectorCoords.top - detectorsCoords.top) / 2.5 + 5,
                }
                r = 6
            } else if (window.innerWidth <= 999 && window.innerWidth > 480){
                r = 8
            }

            let cy = detector.y
            let cx = detector.x

            gsap.fromTo(circle, {},{
                x: cx,
                y: cy,
                r: `${r}%`,
            })
        }, 10)


    }

    const getDimensions = (e) => {
        if (e && e.currentTarget) {
            setTimeout(setCirclePosition(), 500)
            ScrollTrigger.refresh(true)

            return {
                height: e.currentTarget.innerHeight,
                width: e.currentTarget.innerWidth,
            }
        }
    }

    const [dimensions, setDimensions] = useState(getDimensions())
    const update = (e) => setDimensions(getDimensions(e))

    useEffect(
        () => {
            window.addEventListener('resize', update)
            return () => {
                window.removeEventListener('resize', update)
            }
        },
        [dimensions]
    )

    const [currentCircleElement, setCurrentCircleElement] = useState('.detector--3')
    const [hotelImgCoords, setHotelImgCoords] = useState(null)
    const sectionRef = useRef(null)

    useMemo(()=>{
        if (!!sectionRef.current) {
            setCirclePosition()
        }
    }, [currentCircleElement])


    const changeStepContent = (el, current, next, circleElCurr, circleElPrev ) => {
        gsap.fromTo(el.querySelector(`${current} .progress-bar i`), { opacity: 0,  width: '0%', },  {
            opacity: 1,
            width: '100%',
            scrollTrigger: {
                trigger: next,
                start: `top bottom`,
                end: "top top+=270",
                toggleActions: 'play none none reverse',
                scrub: true,
                onLeave: () => {circleElCurr && setCurrentCircleElement(circleElCurr)},
                onLeaveBack: () => {circleElPrev && setCurrentCircleElement(circleElPrev)},
                onEnterBack: () => {circleElPrev && setCurrentCircleElement(circleElPrev)},
                onEnter: () => {circleElPrev && setCurrentCircleElement(circleElPrev)},
                // markers: true,
            }
        })
        gsap.fromTo(el.querySelector(`${current} .progress-bar i`), { opacity: 1,  width: '100%', },  {
            opacity: 0,
            width: '0%',
            scrollTrigger: {
                trigger: next,
                start: "top top+=270",
                toggleActions: 'play none none reverse',
                // markers: true,
            }
        })
        gsap.fromTo(el.querySelector(next), { opacity: 0,   },  {
            opacity: 1,
            // position: 'absolute',
            top: 0,
            scrollTrigger: {
                trigger: next,
                start: "top top+=270",
                toggleActions: 'play none none reverse',
                // markers: true,
            }
        })
        gsap.fromTo(el.querySelector(current), { opacity: 1,   },  {
            opacity: 0,
            position: 'absolute',
            scrollTrigger: {
                trigger: next,
                start: "top top+=270",
                toggleActions: 'play none none reverse',
                // markers: true,
            }
        })
    }


    const initScrollTrigger = (el) => {
        ScrollTrigger.matchMedia({
            // desktop
            "(min-width: 1000px)": () => {
                //Появлениие
                (() => {
                    gsap.fromTo(el.querySelector('.img-box'), { opacity: 0, x: -200,  y: 0, }, {
                        opacity: 1,
                        x: 0,
                        y: 0,
                        // duration: 50,
                        scrollTrigger: {
                            trigger: ".system-works",
                            // markers: true,
                            start: "top center",
                            end: `10`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            onLeaveBack: () => {setCurrentCircleElement('.detector--3')},
                            onLeave: () => {setCurrentCircleElement('.detector--3')},
                            // pin: true
                        }
                    })
                    gsap.fromTo(el.querySelector('#svg-imageX2'), {opacity: 0, scale: .2, x: 200, }, {
                        opacity: 1,
                        scale: 1,
                        x: 0,
                        // duration: 50,
                        scrollTrigger: {
                            trigger: ".system-works",
                            // markers: true,
                            start: "top center+=10",
                            end: `10`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // pin: true
                        }
                    })
                    gsap.fromTo(el.querySelector('#circle'), { opacity: 0, scale: 20,  x: getCirclePosition('.detector--3', -1000, -500).x,  y: getCirclePosition('.detector--3', -1000, -500).y,},{
                        opacity: 1,
                        scale: 1,
                        x: getCirclePosition('.detector--3', 5, 30).x,
                        y: getCirclePosition('.detector--3', 5, 30).y,
                        // duration: 50,
                        scrollTrigger: {
                            trigger: ".motion_text",
                            // markers: true,
                            start: "top bottom",
                            end: `top center`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // pin: true
                        }
                    })
                })();
                //Фиксация
                (() => {
                    gsap.from(el.querySelector('.system-works-hotel'), {
                        // duration: 50,
                        scrollTrigger: {
                            trigger: ".system-works-hotel",
                            // markers: true,
                            start: "top top+=220",
                            end: `${el.clientHeight-120} bottom`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            pin: true,
                            pinSpacing: false
                        }
                    })
                    gsap.from(el.querySelector('.system-works-title'), {
                        // duration: 50,
                        scrollTrigger: {
                            trigger: ".system-works-title",
                            // markers: true,
                            start: "top top+=20",
                            end: `${el.clientHeight-60} bottom`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            pin: true,
                            pinSpacing: false
                        }
                    })
                })();

                //Анимация текста
                (() => {
                    const animationText = (el, trigger) => {
                        gsap.fromTo(el.querySelector(trigger), { opacity: 0, y: 100, }, {
                            opacity: 1,
                            y: 0,
                            scrollTrigger: {
                                trigger: trigger,
                                // markers: true,
                                id: trigger,
                                start: "top bottom",
                                end: "top center",
                                toggleActions: 'play none none reverse',
                                scrub: true,
                            }
                        })
                        gsap.fromTo(el.querySelector(trigger), { opacity: 1, y: 0, }, {
                            opacity: 0,
                            y: 100,
                            scrollTrigger: {
                                trigger: trigger,
                                // markers: true,
                                id: trigger,
                                start: "bottom center",
                                end: "bottom top",
                                toggleActions: 'play none none reverse',
                                scrub: true,
                            }
                        })
                        gsap.fromTo(el.querySelector(`${trigger} .description-img`), { scale: .8, y: 0 }, {
                            y: 100,
                            scale: 1,
                            scrollTrigger: {
                                trigger: trigger,
                                // markers: true,
                                id: trigger,
                                start: "top bottom",
                                end: "top center",
                                toggleActions: 'play none none reverse',
                                scrub: true,
                            }
                        })
                        gsap.fromTo(el.querySelector(`${trigger} .img-icon img`), {  scale: 1 }, {
                            scale: 2,
                            scrollTrigger: {
                                trigger: trigger,
                                // markers: true,
                                id: trigger,
                                start: "top center",
                                end: "top top",
                                toggleActions: 'play none none reverse',
                                scrub: true,
                            }
                        })
                    }
                    animationText(el, '.motion_text')
                    animationText(el, '.doors_text')
                    animationText(el, '.socket_text')
                    animationText(el, '.aggregator_text')
                    animationText(el, '.cloud_text')
                    animationText(el, '.app_text')

                })();
                //Анимация детекторов
                (() => {
                    const animationDetectors = (el, detectors, detector, trigger) =>{
                        const elem = el.querySelector(detectors)
                        const animationName =  detector !== '.aggregator' ? 'pulse' : 'pulse--red'

                        const startAnimation = (point) => {point.classList.add(animationName)}
                        const stopAnimation = (point) => {
                            const listener = () => {
                                point.classList.remove(animationName);
                                elem.removeEventListener('animationiteration', listener, false)
                            }
                            elem.addEventListener('animationiteration', listener, false)
                        }

                        elem.querySelectorAll(detector).forEach(point => {
                            gsap.from(point, {
                                scrollTrigger: {
                                    trigger: trigger,
                                    // markers: true,
                                    start: "top center",
                                    end: "bottom center",
                                    toggleActions: 'play none none reverse',
                                    // scrub: true,
                                    onEnter: () => startAnimation(point),
                                    onEnterBack: () => startAnimation(point),
                                    onLeave: () => detector !== '.socket' && detector !== '.aggregator' && stopAnimation(point),
                                    onLeaveBack: () => {
                                        detector !== '.motion' && stopAnimation(point)
                                    },
                                }
                            })
                        })


                    }

                    animationDetectors(el, '.motion-detectors', '.motion',".motion_text")
                    animationDetectors(el, '.doors-detectors', '.door',".doors_text")
                    animationDetectors(el, '.socket-detectors', '.socket',".socket_text")
                    animationDetectors(el, '.aggregator-detectors', '.aggregator',".aggregator_text")


                })();


                //Анимация балуна
                (() => {

                    const zoomX1 = (trigger, target, scale) => {

                            const coefficient = () => {
                                let percent = 1 - scale
                                return getCircleSize() * percent / 2
                            }

                            gsap.fromTo(el.querySelector('#circle'), {}, {
                                x: getCirclePosition(target, coefficient(), coefficient()).x,
                                y: getCirclePosition(target, coefficient(), coefficient()*1.2).y,
                                scale: scale,
                                ease: "none",
                                scrollTrigger: {
                                    trigger: trigger,
                                    start: "top bottom",
                                    end: "center center",
                                    toggleActions: 'play none none reverse',
                                    scrub: true,
                                }
                            });

                    }
                    const zoomX2 = (x, y) => {

                    }



                    //Анимация балуна  1

                    zoomX1('.doors_text', '.door--2', .6)
                    zoomX1('.socket_text', '.socket--1', .4)
                    zoomX1('.aggregator_text', '.aggregator', .6)

                    gsap.fromTo(el.querySelector('#svg-imageX2'), { opacity: 1,    duration: 30, },  {

                        duration: 1,
                        opacity: 1,
                        backgroundPosition: "-1020px 1450px",
                        scrollTrigger: {
                            trigger: ".doors_text",
                            // markers: true,
                            start: "top bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    })
                    gsap.fromTo(el.querySelector('#svg-imageX2'), {opacity: 1,  duration: 30,}, {

                        duration: 1,
                        opacity: 1,
                        backgroundPosition: "-1050px 1270px",
                        scrollTrigger: {
                            trigger: ".socket_text",
                            // markers: true,
                            start: "top bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    })

                    el.querySelectorAll('.line').forEach(elem => {
                        gsap.fromTo(elem, {opacity:0, scaleX: 0}, {
                            opacity:.2,
                            scaleX: 1,
                            duration: .3,
                            scrollTrigger: {
                                trigger: ".aggregator_text",
                                // markers: true,
                                start: "top center",
                                end: "bottom bottom",
                                toggleActions: 'play none none reverse',
                                // scrub: true,
                            }
                        })
                    })
                    el.querySelectorAll('.detector').forEach(elem => {
                        // console.log(elem.classList)
                        if (!elem.classList.value.includes('aggregator')){
                            gsap.fromTo(elem, {}, {
                                scale: .5,
                                scrollTrigger: {
                                    onEnter: () => {elem.classList.add('pulse')},
                                    trigger: ".aggregator_text",
                                    // markers: true,
                                    start: "top center",
                                    end: "bottom bottom",
                                    toggleActions: 'play none none reverse',
                                    // scrub: true,
                                }
                            })
                        }
                    })

                    gsap.fromTo(el.querySelector('.circle-preview'), { duration: 1,}, {

                        opacity: 0,
                        scrollTrigger: {
                            trigger: ".aggregator_text",
                            // markers: true,
                            start: "top bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    })
                    gsap.fromTo(el.querySelector('#svg-imageX2'), {opacity: 1,  duration: 30,}, {

                        duration: 1,
                        opacity: 1,
                        backgroundPosition: "-940px 1170px",
                        scrollTrigger: {
                            trigger: ".aggregator_text",
                            // markers: true,
                            start: "center bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    })

                    //Анимация балуна  4
                    gsap.fromTo(el.querySelector('.bg'), {   duration: 1,  scale: 1, },  {

                        opacity: 0,
                        scale: .2,
                        transform: 'rotateX(25deg) rotateZ(-28deg) rotateY(5deg)',
                        scrollTrigger: {
                            trigger: ".app_text",
                            // markers: true,
                            start: "center bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    } )
                    gsap.fromTo(el.querySelector('#svg-imageX2'), { opacity: 1, },  {

                        opacity: 0,
                        scale: 0,
                        scrollTrigger: {
                            trigger: ".cloud_text",
                            // markers: true,
                            start: "top bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    } )
                    gsap.fromTo(el.querySelector('.cloud-img'), { opacity: 0, scale: 0 },  {

                        opacity: 1,
                        scale: 1,
                        scrollTrigger: {
                            trigger: ".cloud_text",
                            // markers: true,
                            start: "top bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    } )
                    gsap.fromTo(el.querySelector('.cloud-img'), { },  {

                        opacity:0,
                        scale:0,
                        scrollTrigger: {
                            trigger: ".app_text",
                            // markers: true,
                            start: "top bottom",
                            end: "100",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    } )
                    gsap.fromTo(el.querySelector('.app'), { opacity: 0,    duration: 30,  x: -200,  y: 20, },  {

                        duration: 1,
                        opacity: 1,
                        scale: .8,
                        x: 50,
                        y: 50,
                        left: 0,
                        scrollTrigger: {
                            trigger: ".app_text",
                            // markers: true,
                            start: "center bottom",
                            end: "50",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                        }
                    } )
                    gsap.fromTo(el.querySelector('.detectors'), { opacity: 1, },  {
                        opacity: 0,
                        scale: .2,
                        transform: 'rotateX(25deg) rotateZ(-28deg) rotateY(5deg)',
                        // scale:0,
                        scrollTrigger: {
                            trigger: ".app_text",
                            start: "center bottom",
                            end: "1",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // markers: true,
                        }
                    } )

                })();
            },

            // mobile
            "(max-width: 999px)": () => {


                //Появлениие плана отеля
                (() => {
                    gsap.fromTo(el.querySelector('.system-works-hotel'), {opacity: 0,}, {
                        opacity: 1,
                        scrollTrigger: {
                            trigger: ".system-works",
                            start: "top center",
                            end: `60`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // markers: true,
                        }
                    })
                    gsap.fromTo(el.querySelector('.img-box-wrapper'), {transform: 'rotateX(25deg) rotateZ(-28deg) rotateY(5deg)'}, {
                        transform: 'none',
                        scale: 1.5,
                        y: 100,
                        scrollTrigger: {
                            trigger: ".system-works",
                            start: "top center",
                            end: `60`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // markers: true,
                        }
                    })
                    gsap.fromTo(el.querySelector('#svg-imageX2'), { opacity: 0, scale: .2, x: 200, }, {
                        opacity: 1,
                        scale: 1,
                        x: 0,
                        scrollTrigger: {
                            trigger: ".system-works",
                            start: "top center+=10",
                            end: `10`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // markers: true,
                        }
                    })

                    gsap.fromTo(el.querySelector('#circle'), {
                        // opacity: 0, scale: 10, stroke: 'none',
                    }, {
                        // stroke: '#ff1d1d',
                        // opacity: 1,
                        // scale: 1,
                        scrollTrigger: {
                            trigger: ".system-works-content",
                            // markers: true,
                            start: "top top+=250",
                            end: `100`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // onEnter: setCurrentCircleElement('.detector--3'),
                            // onEnterBack: setCurrentCircleElement('.detector--3'),
                        }
                    })
                })();

                //Появлениие описания
                (() => {
                    gsap.fromTo(el.querySelector('.motion_text'), { opacity: 0,  y: 100, },  {
                        opacity: 1,
                        y: 0,
                        // position: 'absolute',
                        top: 0,
                        scrollTrigger: {
                            trigger: ".system-works-content",
                            start: "top center",
                            end: "top top+=270",
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            // markers: true,
                        }
                    })

                    changeStepContent(el, '.motion_text', '.doors_text', '.door--2', '.detector--3')
                    changeStepContent(el, '.doors_text', '.socket_text', '.socket', '.door--2')
                    changeStepContent(el, '.socket_text', '.aggregator_text', '.aggregator', '.socket')
                    changeStepContent(el, '.aggregator_text', '.cloud_text')
                    changeStepContent(el, '.cloud_text', '.app_text')
                })();

                (() => {
                    //Фиксация заголовка
                    gsap.fromTo(el.querySelector('.header'), {},{scrollTrigger: {
                            trigger: ".header",
                            start: "top+=10 top",
                            end: `${el.clientHeight} bottom`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            pin: true,
                            pinSpacing: false,
                            // markers: true,
                            id: 'header'
                        }})

                    //Фиксация плана отеля
                    gsap.from(el.querySelector('.system-works-hotel'), { duration: 2,
                         scrollTrigger: {
                            trigger: ".system-works-hotel",
                            start: "top top+=72",
                            end: `${el.clientHeight-88} bottom`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            pin: true,
                            pinSpacing: false,
                            // markers: true,
                            id: 'system-works-hotel'
                        }})

                    gsap.from(el.querySelector('.system-works-content'),  {
                        scrollTrigger: {
                            trigger: ".system-works-content",
                            start: "top top+=350",
                            end: `${el.clientHeight-350+62} bottom`,
                            toggleActions: 'play none none reverse',
                            scrub: true,
                            pin: true,
                            // markers: true,
                            id: 'system-works-content'
                        }
                    })

                })()




            },
            "(max-width: 480px)": () => {

                gsap.fromTo(el.querySelector('.img-box-wrapper'), {scale: 1, transform: 'rotateX(25deg) rotateZ(-28deg) rotateY(5deg)'}, {
                    transform: 'none',
                    scrollTrigger: {
                        trigger: ".system-works",
                        start: "top center",
                        end: `60`,
                        toggleActions: 'play none none reverse',
                        scrub: true,
                    //     // markers: true,
                    }
                })
                gsap.fromTo(el.querySelector('.img-box-wrapper'), {},  {
                    scale: 2.5,
                    y: 150,
                    scrollTrigger: {
                        trigger: ".doors_text",
                        start: `top bottom`,
                        end: "60",
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        // markers: true,
                    }
                })
                gsap.fromTo(el.querySelector('.img-box-wrapper'), {},  {
                    y: 50,
                    x: 15,
                    scrollTrigger: {
                        trigger: ".socket_text",
                        start: `top bottom`,
                        end: "60",
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        // onCompleteBack: () => {setCurrentCircleElement('.door--3')},
                        // markers: true,
                    }
                })
                gsap.fromTo(el.querySelector('.img-box-wrapper'), {},  {
                    scrollTrigger: {
                        trigger: ".aggregator_text",
                        start: `top bottom`,
                        end: "60",
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        // markers: true,
                    }
                })
                gsap.fromTo(el.querySelector('.img-box-wrapper'), {},  {
                    y: 35,
                    x: 30,
                    scrollTrigger: {
                        trigger: ".cloud_text",
                        start: `top bottom`,
                        end: "60",
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        // markers: true,
                    }
                })

                gsap.fromTo(el.querySelector('.img-box-wrapper'), {},  {
                    scale: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: ".app_text",
                        start: `top bottom`,
                        end: "270",
                        toggleActions: 'play none none reverse',
                        scrub: true,
                        // markers: true,
                    }
                })

            },
        })
    }


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.refresh()
        const el = sectionRef.current
        el.querySelector('.img-box-wrapper > img').addEventListener('load', () => {
            setHotelImgCoords(el.querySelector('.img-box-wrapper').getBoundingClientRect())
            initScrollTrigger(el)
        })



    }, [sectionRef, dimensions])


    return (
        <section
            {...props}
            className={outerClasses}
            id={'system'}
            ref={sectionRef}
        >


            <div className='header'>
                <div className="container">
                <h2 className="mt-0 mb-12 system-works-title">Как работает система?</h2>
                </div>
            </div>

            <div className="content content--left">
                <div className="system-works-hotel">
                    <div className="img-box">
                        <div className="img-box-wrapper">
                            <img
                                src={require("./../../assets/images/3d plan hotel bg.png")}
                                alt="3D план отеля"
                                className='bg'
                            />
                            <img
                                src={require('./../../assets/images/browser_bg.png')}
                                alt="Интерфейс приложения"
                                className='app'
                            />

                            <div className='circle-preview' style={{position: 'absolute'}}>

                                <svg className="glass">
                                    <image id="svg-image"
                                           xlinkHref={require("./../../assets/images/3d plan hotel bg.png")}
                                           clipPath="url(#svg-mask)"/>
                                    <use xlinkHref='#circle'/>
                                    <clipPath id="svg-mask">
                                        <circle id='circle' cx="0" cy="0" r="10%" fill="none" stroke="#ff1d1d"
                                                strokeWidth="2"/>
                                    </clipPath>
                                </svg>
                            </div>
                            <div className="detectors">
                                <div className="motion-detectors">
                                    <span className="detector pulse motion detector--1"></span>
                                    <span className="detector pulse motion detector--2"></span>
                                    <span className="detector pulse motion detector--3"></span>
                                    <span className="detector pulse motion detector--4"></span>
                                    <span className="detector pulse motion detector--5"></span>
                                    <span className="detector pulse motion detector--6"></span>
                                </div>
                                <div className="doors-detectors">
                                    <span className="detector door door--1"></span>
                                    <span className="detector door door--2"></span>
                                    <span className="detector door door--3"></span>
                                    <span className="detector door door--4"></span>
                                    <span className="detector door door--5"></span>
                                    <span className="detector door door--6"></span>
                                </div>
                                <div className="socket-detectors">
                                    <span className="detector socket socket--1"></span>
                                    <span className="detector socket socket--2"></span>
                                </div>
                                <div className="aggregator-detectors">
                                    <span className="detector aggregator"></span>
                                </div>
                                <div className="detectors-lines">
                                    <span className="line line--1"></span>
                                    <span className="line line--2"></span>
                                    <span className="line line--3"></span>
                                    <span className="line line--4"></span>
                                    <span className="line line--5"></span>
                                    <span className="line line--6"></span>
                                    <span className="line line--7"></span>
                                    <span className="line line--8"></span>
                                    <span className="line line--9"></span>
                                    <span className="line line--10"></span>
                                    <span className="line line--11"></span>
                                    <span className="line line--12"></span>
                                    <span className="line line--13"></span>
                                    <span className="line line--14"></span>
                                </div>
                            </div>

                            <div className='circle-zoom' style={{position: 'absolute'}}>
                                <div id="svg-imageX2"></div>

                                <img
                                    src={require('./../../assets/images/pollution.svg')}
                                    alt="Удалённое облако"
                                    className='cloud-img'
                                />

                            </div>
                        </div>

                    </div>
                </div>
                <div className='empty-space'></div>
            </div>

            <div className="content content--right container">

                <div className='empty-space'></div>

                <div className="system-works-content">
                    <ul className='inner'>
                        <li className="mb-32 motion_text">
                            <h6>Шаг 1 \ 6 <span className="progress-bar"><i></i></span></h6>

                            <div className="description">
                                <div className="description-img">
                                <span className="img-icon">
                                    <img
                                        src={require("./../../assets/images/motion_detector.png")}
                                        alt=""/>
                                </span>
                                </div>
                                <div className="description-text">
                                    <h4>В номерах ставятся датчики движения, для отслеживания присутствия людей в номере</h4>
                                    {/**<p>Тем не менее, личность топ менеджера концентрирует повседневный анализ зарубежного опыта, опираясь на опыт западных коллег. Итак, ясно, что внутрифирменная реклама усиливает экспериментальный охват аудитории. </p>*/}
                                </div>
                            </div>

                        </li>
                        <li className="mb-32 doors_text">
                            <h6>Шаг 2 \ 6 <span className="progress-bar"><i></i></span></h6>

                            <div className="description">
                                <div className="description-img">
                                <span className="img-icon">
                                    <img src={require("./../../assets/images/doors_detector.png")} alt=""/>
                                </span>
                                </div>
                                <div className="description-text">
                                    <h4>На двери устанавливаются датчики открытия\закрытия дверей.</h4>
                                    {/**<p>Стоит отметить, что партисипативное планирование экономит конвергентный побочный PR-эффект. Концепция новой стратегии, вопреки мнению П.Друкера, охватывает культурный бренд.</p>*/}
                                </div>
                            </div>
                        </li>
                        <li className="mb-32 socket_text">
                            <h6>Шаг 3 \ 6 <span className="progress-bar"><i></i></span></h6>

                            <div className="description">
                                <div className="description-img">
                                <span className="img-icon">
                                    <img
                                        src={require("./../../assets/images/socket.png")}
                                        alt=""/>
                                </span>
                                </div>
                                <div className="description-text">
                                    <h4>В недоступных, для глаз посетителей местах, устанавливаются
                                        ретрансляторы сигнала датчиков.</h4>

                                    {/**<p>Выставочный стенд, как принято считать, переворачивает фактор коммуникации, отвоевывая рыночный сегмент. Поведенческий таргетинг ускоряет повседневный формирование имиджа, используя опыт предыдущих кампаний. </p>*/}
                                </div>
                            </div>

                        </li>
                        <li className="mb-32 aggregator_text">
                            <h6>Шаг 4 \ 6 <span className="progress-bar"><i></i></span></h6>


                            <div className="description">
                                <div className="description-img">
                                <span className="img-icon">
                                    <img
                                        src={require("./../../assets/images/micro_pc.png")}
                                        alt=""/>
                                </span>
                                </div>
                                <div className="description-text">
                                    <h4>В офисном помещении монтируется агрегатор для сбора данных с
                                        датчиков и отправки в облако.</h4>

                                    {/**<p>Корпоративная культура допускает межличностный портрет потребителя, повышая конкуренцию. Отраслевой стандарт, пренебрегая деталями, экономит контент. Особенность рекламы очевидна не для всех. </p>*/}
                                </div>
                            </div>

                        </li>
                        <li className="m-0 cloud_text">
                            <h6>Шаг 5 \ 6 <span className="progress-bar"><i></i></span></h6>


                            <div className="description">
                                <div className="description-img" style={{opacity: '0'}}>
                                {/*<span className="img-icon">*/}
                                {/*    <img src={require("./../../assets/images/micro_pc.png")} alt=""/>*/}
                                {/*</span>*/}
                                </div>
                                <div className="description-text">
                                    <h4>Данные с датчиков передаются на удалённое облако, для хранения и обработки.</h4>

                                    {/**<p>Управление брендом притягивает традиционный канал. Корпоративная культура без оглядки на авторитеты ускоряет ролевой SWOT-анализ.</p>*/}
                                </div>
                            </div>

                        </li>
                        <li className="m-0 app_text">
                            <h6>Шаг 6 \ 6 </h6>


                            <div className="description">
                                <div className="description-img" style={{opacity: '0'}}>
                                {/*<span className="img-icon">*/}
                                {/*    <img src={require("./../../assets/images/micro_pc.png")} alt=""/>*/}
                                {/*</span>*/}
                                </div>
                                <div className="description-text">
                                    <h4>Полученные показания датчиков в автоматическом режиме сравниваются с отчётами о
                                        бронировании.</h4>

                                    {/**<p>Управление брендом притягивает традиционный канал. Корпоративная культура без оглядки на авторитеты ускоряет ролевой SWOT-анализ.</p>*/}
                                </div>
                            </div>

                            <div className="reveal-from-bottom" data-reveal-delay="200">
                                <Button className='mt-8' tag="a" wideMobile href="#">
                                    Попробовать Демоверсию
                                </Button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

SystemWorks.propTypes = propTypes;
SystemWorks.defaultProps = defaultProps;

export default SystemWorks;
