import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Button from '../elements/Button';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import Select from 'react-select'

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Demonstration = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           ...props
                       }) => {


    const outerClasses = classNames(
        'demonstration section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'demonstration-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    function onChange() {
        // console.log(onChange)
    }

    function onBlur() {
        // console.log(onBlur)
    }

    const options = [
        {value: '1', label: 'Отель 01'},
        {value: '2', label: 'Отель 02'},
        {value: '3', label: 'Отель 03'},
    ]
    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className="body">

                    <div className="title">
                        <h2>
                            Запишитесь <br/>на демонстрацию
                        </h2>
                        <p>
                            Посмотреть как работает система, как монтируются датчики можно, записавшись на демонстрацию
                            в одной из гостиниц оборудованной системой.
                        </p>
                    </div>
                    <div className="form">
                        <div className="buttons">
                            <div className="demonstration-input">
                                <label htmlFor="telephone" className="input__label">
                                    Номер телефона
                                </label>
                                {/*<input type="text" id="telephone" autoComplete="off" className="input"/>*/}
                                <IntlTelInput
                                    preferredCountries={['ru']}
                                    onPhoneNumberChange={onChange()}
                                    onPhoneNumberBlur={onBlur()}
                                    inputClassName="input"
                                />

                            </div>
                            <div className="demonstration-input">
                                <label htmlFor="hotelSelect" className="input__label">Выберите отель</label>

                                <Select options={options}
                                        className="input -select"
                                        classNamePrefix="select"
                                        defaultValue={options[0]}/>

                            </div>
                        </div>
                        <Button href="#" color='primary'>
                            Записаться на демонстрацию
                        </Button>
                    </div>
                </div>
            </div>
            <div className="blob-group blob-group-4 in-viewport">
                <canvas width="1400" height="599"></canvas>
            </div>
        </section>
    );
}

Demonstration.propTypes = propTypes;
Demonstration.defaultProps = defaultProps;

export default Demonstration;
