import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from "../elements/Button";

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const Prices = ({
                    className,
                    topOuterDivider,
                    bottomOuterDivider,
                    topDivider,
                    bottomDivider,
                    hasBgColor,
                    invertColor,
                    pushLeft,
                    ...props
                }) => {

    const outerClasses = classNames(
        'price section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'price-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Развивайте и контролируйте свой бизнес с помощью современных технологий',
        paragraph: 'Выберите подходящий пакет услуг'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="price-item-header">
                                    <h3>Без оборудования</h3>
                                </div>
                                <div className="price-item-content">
                                    <p className="m-0 text-sm">
                                        Самостоятельная покупка оборудования на 1 номер
                                    </p>
                                    <h4 className="m-8">
                                        7&#8239;000₽
                                    </h4>
                                </div>

                                <div className="price-item-price">
                                    <p className="price-item-subscriber">
                                        Абонентская плата
                                    </p>
                                    <p className="m-0 text-sm">
                                        1 номер
                                    </p>

                                    <h3 className="mb-8 mt-0">
                                        400₽ <span className='text-sm'>/ в мес.</span>
                                    </h3>
                                </div>
                                <div className="price-item-footer">
                                    <Button tag="a" color="primary" size='sm' noArrow wideMobile href="#">
                                        Регистрация
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="price-item-header">
                                    <h3>Аренда оборудования</h3>
                                </div>
                                <div className="price-item-content">

                                    <p className="m-0 text-sm">
                                        Оборудование предоставляется в аренду
                                    </p>


                                </div>

                                <div className="price-item-price">

                                    <p className="price-item-subscriber">
                                        Абонентская плата
                                        высчитывается исходя из стоимости номера.
                                    </p>
                                    <p className="m-0 text-sm">
                                        1 номер
                                    </p>

                                    <h3 className="mb-8 mt-0">
                                        1&#8239;000₽ <span className='text-sm'>/ в мес.</span>
                                    </h3>
                                </div>
                                <div className="price-item-footer">
                                    <Button tag="a" color="primary" size='sm' noArrow wideMobile href="#">
                                        Регистрация
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="price-item-header">
                                    <h3>Полная покупка</h3>
                                </div>
                                <div className="price-item-content">
                                    <p className="mb-8 text-sm">
                                        Покупка системы без абонентской платы
                                    </p>
                                </div>

                                <div className="price-item-price">
                                    <p className="m-0 text-sm">
                                        1 номер
                                    </p>

                                    <h3 className="mb-8 mt-0">
                                        30&#8239;000₽ <span className='text-sm'></span>
                                    </h3>
                                </div>
                                <div className="price-item-footer">
                                    <Button tag="a" color="primary" size='sm' noArrow wideMobile href="#">
                                        Регистрация
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

Prices.propTypes = propTypes;
Prices.defaultProps = defaultProps;

export default Prices;
