import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const SystemProtection = ({
                              className,
                              topOuterDivider,
                              bottomOuterDivider,
                              topDivider,
                              bottomDivider,
                              hasBgColor,
                              invertColor,
                              pushLeft,
                              ...props
                          }) => {

    const outerClasses = classNames(
        'system-protection section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'system-protection-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Сложность обмана системы',
        paragraph: 'Будьте уверены в показаниях'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="system-protection-item-header">
                                    <div className="system-protection-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-01.svg')}
                                            alt="Features tile icon 01"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="system-protection-item-content">
                                    <h4 className="mt-0 mb-8">
                                        Обнаружение вскрытия
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Устройство сбора требует только шнура питания, закрыто в специальный корпус, в
                                        случае открытия которого срабатывают датчики и можно будет увидеть,
                                        что происходило вскрытие
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="system-protection-item-header">
                                    <div className="system-protection-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-02.svg')}
                                            alt="Features tile icon 02"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="system-protection-item-content">
                                    <h4 className="mt-0 mb-8">
                                        Бесперебойное питание
                                    </h4>
                                    <p className="m-0 text-sm">
                                        В устройство встроен ИБП в случае перепадов электричества или отключении
                                        электричества устройство продолжит
                                        собирать данные не менее нескольких часов, а также отправит сигнал о том, что
                                        отключилось электричество
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="system-protection-item-header">
                                    <div className="system-protection-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-03.svg')}
                                            alt="Features tile icon 03"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="system-protection-item-content">
                                    <h4 className="mt-0 mb-8">
                                        Интернет не нужен
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Устройство оснащено собственным модемом и не требует наличия интернета
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

SystemProtection.propTypes = propTypes;
SystemProtection.defaultProps = defaultProps;

export default SystemProtection;
