import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import { scroller } from "react-scroll";
import Button from "../elements/Button";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );


  const scrollToSection = (e) => {
    e.preventDefault()
    const className = e.target.getAttribute('data-to');
    scroller.scrollTo(className, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container-fluid">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <Logo />
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">

                  <div className="mobile-navigation showMobileNav">
                    <ul
                        className={
                          classNames(
                              'navList list-reset text-xs',
                              navPosition && `header-nav-${navPosition}`
                          )}>

                      <li className={'menu-item'}>
                        <a href="#" data-to='system' onClick={scrollToSection}>Система</a>
                        <ul>
                          <li>
                            <a href="#" data-to='features-split' onClick={scrollToSection}>Активность</a>
                          </li>
                          <li>
                            <a href="#" data-to='system-indicators' onClick={scrollToSection}>Показания</a>
                          </li>
                        </ul>
                      </li>
                      <li className={'menu-item'}>
                        <a href="#" data-to='devices' onClick={scrollToSection}>Оборудование</a>
                        <ul>
                          <li>
                            <a href="#" data-to='doors_detector_text' onClick={scrollToSection}>Датчик открывания</a>
                          </li>
                          <li>
                            <a href="#" data-to='motion_detector_text' onClick={scrollToSection}>Датчик движения</a>
                          </li>
                          <li>
                            <a href="#" data-to='micro_pc_text' onClick={scrollToSection}>Микрокомпьютер</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#" data-to='price' onClick={scrollToSection}>Стоимость</a>
                      </li>
                    </ul>
                    <ul
                        className={
                          classNames(
                              'buttonNav list-reset text-xs',
                              navPosition && `header-nav-${navPosition}`
                          )}
                    >
                      <li>
                        <Button tag={'a'} color={'dark'} noArrow to={'#'}>Попробовать Демоверсию</Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
