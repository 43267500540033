import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import SystemProtection from '../components/sections/SystemProtection';
import SystemIndicators from '../components/sections/SystemIndicators';
import SystemWorks from '../components/sections/SystemWorks';
import Features from '../components/sections/Features';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Devices from '../components/sections/Devices';
import MicroProcessor from '../components/sections/MicroProcessor';
import Price from '../components/sections/Price';
import Demonstration from '../components/sections/Demonstration';

const Home = () => {

  return (
    <>
        <Hero />
        <Features invertMobile imageFill invertDesktop/>
        <SystemWorks />
        <FeaturesSplit invertMobile imageFill />
        <SystemIndicators />
        <SystemProtection />
        <Devices />
        <MicroProcessor />
        <Price />
        <Demonstration />
    </>
  );
}

export default Home;
